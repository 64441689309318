import React from 'react'
import NavBar from '../Camponent/NavBarFooter/NavBar'
import Banner from '../Camponent/Newssingle/Banner'
import Footer from '../Camponent/NavBarFooter/Footer'
function Newssingle() {
  return (
   <>
   <NavBar/>
    <Banner/>
    <Footer/>
   </>
  )
}

export default Newssingle
