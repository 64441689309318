import React from 'react'
import img from '../../Images/unsplash_OOE4xAnBhKo.png'
import { GoArrowUpRight } from 'react-icons/go'
function Banner() {
  return (
   <>
   <div className='blogs px-4 my-4'>
    <div className='row m-0'>
        <div className='col-lg-8 col-12 p-0'>
        <div className='blog-card'>
            <img src={img} alt=''/>
        </div>
        </div>
        <div className='col-lg-4 col-12 p-0'>
            <div className='blog-text'>
                <h3>Viverra accumsan, sed vestibulum sit<br/> turpis neque, sit.</h3>
                <span style={{color:'#1C9093'}} className='d-flex'>Pakistan Property.<h5 style={{color:'#959595'}}>March 16,2022</h5></span>
                <p>At accumsan condimentum donec dictumst eros,<br/> tempus in diam. Ornare gravida quis eu blandit<br/> vestibulum egestas. Congue neque in mi<br/> tincidunt amet arcu varius pulvinar. At accumsan<br/> condimentum donec dictumst eros, tempus in.<br/> Ornare gravida quis eu blandit lectus vestibulum<br/> egestas. Congue neque in mi vulputate tincidunt<br/>t arcu varius pulvinar...</p>
                <h6>Read More<span style={{fontSize:'19px',marginLeft:'3px'}}><GoArrowUpRight /></span></h6>
            </div>
        </div>
    </div>
   </div>
   </>
  )
}

export default Banner
