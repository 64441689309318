import React from 'react'
import { CgMenuGridR } from 'react-icons/cg'
import { TfiMenuAlt } from 'react-icons/tfi'
import img from '../../Images/listing/image 28.png'
import img1 from '../../Images/logo.png'
import img2 from '../../Images/Square Meters.png'
import img3 from '../../Images/Frame 1300192801.png'
import img4 from '../../Images/Bath.png'
import img5 from '../../Images/Frame 1000014539.png'
import img6 from '../../Images/Vector.png'
import img7 from '../../Images/Frame 1000014540.png'
import img8 from '../../Images/Rectangle 1822.png'
import img9 from '../../Images/Rectangle 748.png'
function Listing() {
  return (
    <>
      <div className='list px-4'>
        <h2>Latest Lease</h2>
        <div className='least'>
          <button>Popular</button>
          <span className='mx-2' style={{ color: "rgb(28,144,147)" }}><TfiMenuAlt /></span>
          <span><CgMenuGridR /></span>
        </div>
      </div>
      <div className='least-card'>
        <div className='row'>
          <div className='col-lg-3'>
            <img src={img} alt='...' />
          </div>
          <div className='col-lg-9'>
            <div className='last-heading'>
              <h2>PKR20,000000</h2>
              <h4>Added: 2 Hours ago</h4>
            </div>
            <div className='b-heading'>
              <h2>Beverly SParingfield</h2>
              <div className='least-logo'>
                <span className='market'>Marketed By</span>
                <span className='logo'>
                  <img src={img1} alt='...' />
                </span>
              </div>
            </div>
            <h3>23H Block, Phase 8, DHA Lahore </h3>
            <div className='least-img'>
              <span>
                <img src={img2} alt='...' />
                <h4>2 Kanal</h4>
              </span>
              <span>
                <img src={img3} alt='...' />
                <h4>Available</h4>
              </span>
              <span>
                <img src={img4} alt='...' />
                <h4>Industrial</h4>
              </span>
            </div>
            <hr />
            <p>Occupy 556,598 square feet of high-specification warehouse space within this premium distribution facility. The office accommodation is split across two three-storey office blocks. The property is available to let on new terms to be agreed.</p>
            <hr />
            <div className='rect-img'>
                  <div className='least-btn'>
                      <button><img src={img5} className='mx-1' alt='...' />Email</button>
                      <button><img src={img6} className='mx-1' alt='...' />Call</button>
                      <button><img src={img7} className='mx-1' alt='...' />WhatsApp</button>
                  </div>
                  <img src={img9} alt='...' />
                </div>
          </div>
        </div>
      </div>
      <div className='latest-card'>
        <div className='row m-0'>
          <div className='col-lg-6'>
            <div className='latest-img'>
              <div className='big-img'>
                <img src={img8} alt='...' />
              </div>
              <div className='down-card'>
                <div className='last-heading'>
                  <h2 style={{ color: '#313839' }}>Beverly Springfield</h2>
                  <h4>Added: 2 Hours ago</h4>
                </div>
                <h3>23H Block, Phase 8, DHA Lahore </h3>
                <div className='least-img'>
                  <span>
                    <img src={img2} alt='...' />
                    <h4>2 Kanal</h4>
                  </span>
                  <span>
                    <img src={img3} alt='...' />
                    <h4>Available</h4>
                  </span>
                  <span>
                    <img src={img4} alt='...' />
                    <h4>Industrial</h4>
                  </span>
                </div>
                <hr />
                <p>Occupy 556,598 square feet of high-specification warehouse space within this premium distribution facility. The office accommodation is split across two three-storey office blocks. The property is available to let on new terms to be agreed.</p>
                <hr />
                <div className='rect-img'>
                  <div className='least-btn'>
                      <button><img src={img5} className='mx-1' alt='...' />Email</button>
                      <button><img src={img6} className='mx-1' alt='...' />Call</button>
                      <button><img src={img7} className='mx-1' alt='...' />WhatsApp</button>
                  </div>
                  <img src={img9} alt='...' />
                </div>
              </div>
            </div>
          </div>
          <div className='col-lg-6'></div>
        </div>
      </div>
    </>
  )
}

export default Listing
