import React from 'react'

function Similar() {
    return (
        <>
            <div className='similar px-2'>
                <div className='row'>
                    <div className='col-lg-4'>
                        <h3>Popular searches</h3>
                        <p>
                        Luxury Flats For Sale in DHA Defence Lahore
                        8 Marla Houses for Sale in DHA Lahore
                        Furnished Houses For Sale in DHA Defence Lahore
                        Cheap Houses for Sale in DHA Defence
                        independent Houses For Sale in DHA Defence Lahore
                        Old Houses For Sale in DHA Defence Lahore
                        </p>
                    </div>
                    <div className='col-lg-4'>
                    <p style={{marginTop:"50px"}}>
                        Luxury Flats For Sale in DHA Defence Lahore
                        8 Marla Houses for Sale in DHA Lahore
                        Furnished Houses For Sale in DHA Defence Lahore
                        Cheap Houses for Sale in DHA Defence
                        independent Houses For Sale in DHA Defence Lahore
                        Old Houses For Sale in DHA Defence Lahore
                        </p>
                    </div>
                    <div className='col-lg-4'>
                    <p style={{marginTop:"50px"}}>
                        Luxury Flats For Sale in DHA Defence Lahore
                        8 Marla Houses for Sale in DHA Lahore
                        Furnished Houses For Sale in DHA Defence Lahore
                        Cheap Houses for Sale in DHA Defence
                        independent Houses For Sale in DHA Defence Lahore
                        Old Houses For Sale in DHA Defence Lahore
                        </p>
                    </div>
                
                </div>
                <hr className='m-0'/>
                <div className='row'>
                    <div className='col-lg-4'>
                        <h3>Popular searches</h3>
                        <p>
                        Luxury Flats For Sale in DHA Defence Lahore
                        8 Marla Houses for Sale in DHA Lahore
                        Furnished Houses For Sale in DHA Defence Lahore
                        Cheap Houses for Sale in DHA Defence
                        independent Houses For Sale in DHA Defence Lahore
                        Old Houses For Sale in DHA Defence Lahore
                        </p>
                    </div>
                    <div className='col-lg-4'>
                    <p style={{marginTop:"50px"}}>
                        Luxury Flats For Sale in DHA Defence Lahore
                        8 Marla Houses for Sale in DHA Lahore
                        Furnished Houses For Sale in DHA Defence Lahore
                        Cheap Houses for Sale in DHA Defence
                        independent Houses For Sale in DHA Defence Lahore
                        Old Houses For Sale in DHA Defence Lahore
                        </p>
                    </div>
                    <div className='col-lg-4'>
                    <p style={{marginTop:"50px"}}>
                        Luxury Flats For Sale in DHA Defence Lahore
                        8 Marla Houses for Sale in DHA Lahore
                        Furnished Houses For Sale in DHA Defence Lahore
                        Cheap Houses for Sale in DHA Defence
                        independent Houses For Sale in DHA Defence Lahore
                        Old Houses For Sale in DHA Defence Lahore
                        </p>
                    </div>
                   
                </div>
            </div>
        </>
    )
}

export default Similar
