import React from 'react'
import NavBar from '../Camponent/NavBarFooter/NavBar'
import Footer from '../Camponent/NavBarFooter/Footer'

function TermsCondition() {
  return (
    <div>
      <NavBar/>
      <div className='container-fluid p-4'>
                <div className='row m-0 '>
                    <div className='term-condition'>
                        <h2>Terms & Conditions</h2>
                        <h4>Definitions</h4>
                        <p>The following capitalized terms shall have the following meaning in these Terms & Conditions, unless the context otherwise requires:</p>
                        <ul>
                            <li className='my-4'>"Advertising Agreement"  A contract between PakistanProperty.com (the "Company") and a Client for advertising services or products.</li>
                            <li className='my-4'>"Ad Services Package"  The specific advertising products or services the Company provides to the Client, as detailed in the order form signed by the Client.</li>
                            <li className='my-4'>"Affiliates"  Any company controlled or owned by PakistanProperty.com, or any company commonly controlled or owned with PakistanProperty.com, and any entity jointly offering the Service.</li>
                            <li className='my-4'>"PakistanProperty.com"  The company that owns and operates the website www.pakistanproperty.com [invalid URL removed], with its registered office at [Address Placeholder].</li>
                            <li className='my-4'>"Client" - The entity that enters into the Advertising Agreement with the Company.</li>
                            <li className='my-4'>"Customer" - Any customer of the Client.</li>
                            <li className='my-4'>"Effective Date" - The date these Terms & Conditions come into effect, as stated at the beginning of this document.</li>
                            <li className='my-4'>"Intellectual Property Rights" - All intellectual property, including patents, trademarks, copyrights, designs, database rights, trade secrets, and any other proprietary rights, whether registered or not.</li>
                            <li className='my-4'>"Material" - Any content or information published on the Website or provided by the Company in connection with the Service.</li>
                            <li className='my-4'>"Privacy Policy" - The Company's privacy policy, which may be updated from time to time.</li>
                            <li className='my-4'>"Product" - The online classified advertising platform provided on the Website, along with any Ad Services Package.</li>
                            <li className='my-4'>"Posting Agents" - Third-party agents or services that post Material to the Service on behalf of others.</li>
                            <li className='my-4'>"Registration Details" - The information Users must provide when registering on the Website, such as name, phone number, email address, and age.</li>
                            <li className='my-4'>"Service"  The provision of the Website and the Product.</li>
                            <li className='my-4'>"Unacceptable" - Any material or information on the Website that may be considered illegal, offensive, harmful, misleading, or in violation of any laws or third-party rights.</li>
                            <li className='my-4'>"User Material" - Any content or information posted on the Website by a User or provided to PakistanProperty.com in connection with the Website or Service.</li>
                        </ul>
                        <p>These definitions are crucial for understanding and interpreting these Terms & Conditions. If you have any questions, please contact us.</p>
                    </div>
                    </div>
                    </div><div className='container-fluid p-4'>
                <div className='row m-0 '>
                    <div className='term-condition'>
                        <h2>Terms & Conditions</h2>
                        <h4>Definitions</h4>
                        <p>The following capitalized terms shall have the following meaning in these Terms & Conditions, unless the context otherwise requires:</p>
                        <ul>
                            <li className='my-4'>"Advertising Agreement"  A contract between PakistanProperty.com (the "Company") and a Client for advertising services or products.</li>
                            <li className='my-4'>"Ad Services Package"  The specific advertising products or services the Company provides to the Client, as detailed in the order form signed by the Client.</li>
                            <li className='my-4'>"Affiliates"  Any company controlled or owned by PakistanProperty.com, or any company commonly controlled or owned with PakistanProperty.com, and any entity jointly offering the Service.</li>
                            <li className='my-4'>"PakistanProperty.com"  The company that owns and operates the website www.pakistanproperty.com [invalid URL removed], with its registered office at [Address Placeholder].</li>
                            <li className='my-4'>"Client" - The entity that enters into the Advertising Agreement with the Company.</li>
                            <li className='my-4'>"Customer" - Any customer of the Client.</li>
                            <li className='my-4'>"Effective Date" - The date these Terms & Conditions come into effect, as stated at the beginning of this document.</li>
                            <li className='my-4'>"Intellectual Property Rights" - All intellectual property, including patents, trademarks, copyrights, designs, database rights, trade secrets, and any other proprietary rights, whether registered or not.</li>
                            <li className='my-4'>"Material" - Any content or information published on the Website or provided by the Company in connection with the Service.</li>
                            <li className='my-4'>"Privacy Policy" - The Company's privacy policy, which may be updated from time to time.</li>
                            <li className='my-4'>"Product" - The online classified advertising platform provided on the Website, along with any Ad Services Package.</li>
                            <li className='my-4'>"Posting Agents" - Third-party agents or services that post Material to the Service on behalf of others.</li>
                            <li className='my-4'>"Registration Details" - The information Users must provide when registering on the Website, such as name, phone number, email address, and age.</li>
                            <li className='my-4'>"Service"  The provision of the Website and the Product.</li>
                            <li className='my-4'>"Unacceptable" - Any material or information on the Website that may be considered illegal, offensive, harmful, misleading, or in violation of any laws or third-party rights.</li>
                            <li className='my-4'>"User Material" - Any content or information posted on the Website by a User or provided to PakistanProperty.com in connection with the Website or Service.</li>
                        </ul>
                        <p>These definitions are crucial for understanding and interpreting these Terms & Conditions. If you have any questions, please contact us.</p>
                    </div>
                    </div>
                    </div>
      <Footer/>
    </div>
  )
}

export default TermsCondition

