import React from 'react'
import { HiOutlineArrowUpRight } from 'react-icons/hi2'
import img from '../../Images/Group.png'
import img1 from '../../Images/Vector (1).png'
import img2 from '../../Images/Group 1000014144.png'
function Catogery() {
  return (
    <>
   <div className='category px-4'>
    <h3>Browse by Category</h3>
    <div className='row m-0'>
    <div className='col-lg-4 p-2'>
        <div className='category-card'>
        <div className='c-box'>
            <img src={img} alt='...'/>
        </div>
       <div className='category-heading'>
       <h1>20356</h1>
            <h5>Apartment</h5>
            <h6>Explore Project<span><HiOutlineArrowUpRight /></span></h6>
       </div>
        </div>
        </div>
        <div className='col-lg-4'>
        <div className='category-card'>
        <div className='c-box'>
            <img src={img1} alt='...'/>
        </div>
       <div className='category-heading'>
       <h1>14256</h1>
            <h5>Office</h5>
            <h6>Explore Project<span><HiOutlineArrowUpRight /></span></h6>
       </div>
        </div>
        </div>
        <div className='col-lg-4'>
        <div className='category-card'>
        <div className='c-box'>
            <img src={img2} alt='...'/>
        </div>
       <div className='category-heading'>
       <h1>2124</h1>
            <h5>Shop</h5>
            <h6>Explore Project<span><HiOutlineArrowUpRight /></span></h6>
       </div>
        </div>
        </div>
    </div>
   </div>
    </>
  )
}

export default Catogery
