import React, { useState } from 'react';
import img1 from '../../Images/AgentProfile/Mask Group.png';
import img2 from '../../Images/AgentProfile/Mask Group (1).png';
import img3 from '../../Images/AgentProfile/Mask Group (2).png';
import img4 from '../../Images/AgentProfile/dillon-kydd-XGvwt544g8k-unsplash 1.png';
import img5 from '../../Images/Frame 1300192747.png';
import img6 from '../../Images/Frame 1300192750.png';
import img7 from '../../Images/Square Meters.png'
import { MdOutlineBed } from 'react-icons/md';
import { LuBath } from 'react-icons/lu';
import { CiHeart } from 'react-icons/ci';
import { Link } from 'react-router-dom';

function Properties() {
    const [buy, setBuy] = useState(false);

    const properties = [
        {
            img: img1,
            price: "5,00,00,000",
            priceUnit: "Crore",
            location: "Beverly Springfield",
            address: "23H Block, Phase 8, DHA Lahore ",
            beds: 3,
            baths: 2,
            area: "3 Marla",
        },
        {
            img: img2,
            price: "5,00,00,000",
            priceUnit: "Crore",
            location: "Faulkner Ave",
            address: "23H Block, Phase 8, DHA Lahore ",
            beds: 3,
            baths: 2,
            area: "3 Marla",
        },
        {
            img: img3,
            price: "5,00,00,000",
            priceUnit: "Crore",
            location: "Palm Harbor",
            address: "23H Block, Phase 8, DHA Lahore ",
            beds: 3,
            baths: 2,
            area: "3 Marla",
        },
        {
            img: img4,
            price: "5,00,00,000",
            priceUnit: "Crore",
            location: "St. Crystal",
            address: "23H Block, Phase 8, DHA Lahore ",
            beds: 3,
            baths: 2,
            area: "3 Marla",
        }
    ];

    return (
        <>
            <div className='popular_properties properties container-fluid main_banner_home mt-4 pt-1'>
                <h1 className='my-4'>Recent Properties By Stallion Real Estate & Builders</h1>
                <div className='d-flex flex-wrap justify-content-between'>
                    <div className='btn_popular mt-1 d-flex'>
                        <button className='d-flex align-items-center' onClick={() => setBuy(false)} style={{ background: `${!buy ? "white" : "none"}`, border: `${!buy ? "1px solid lightgray" : "none"}` }}>
                            <img style={{ marginRight: "6px" }} src={img6} alt="" /> Rent
                        </button>
                        <button className='d-flex align-items-center' onClick={() => setBuy(true)} style={{ background: `${buy ? "white" : "none"}`, border: `${buy ? "1px solid lightgray" : "none"}` }}>
                            <img style={{ marginRight: "6px" }} src={img5} alt="" /> Buy
                        </button>
                    </div>
                </div>
            </div>

            <div className='container-fluid main_banner_home my-2 py-2'>
                <div className='row mx-0 my-3'>
                    {properties.map((item, index) => (
                        <div key={index} className='col-xl-3 col-lg-4 col-md-6 col-12 p-0 pe-2 my-2'>
                            <Link to={"/profile"} style={{ textDecoration: "none" }}>
                                <div className="property-item rounded">
                                    <div className="position-relative">
                                        <div style={{ width: "100%", overflow: "hidden" }}>
                                            <img src={item.img} alt="" />
                                        </div>
                                        <div className="position-absolute  pt-1 px-3"><img src='./image/Frame.svg' alt='...' />POPULAR</div>
                                        <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path id="Vector 2" d="M8 8L0 0H8V8Z" fill="#046B6D" />
                                        </svg>
                                    </div>
                                    <div className="p-4 pb-0">
                                        <div className='d-flex justify-content-between'>
                                            <h5><span>PKR</span> {item.price} <span className='price_unit'>{item.priceUnit}</span></h5>
                                            <span style={{ border: "1px solid lightgray", height: "50px", width: "50px", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "50%" }}>
                                                <CiHeart style={{ fontSize: "25px", color: "#1C9093" }} />
                                            </span>
                                        </div>
                                        <h5 className="d-block h5">{item.location}</h5>
                                        <p style={{ color: "gray" }}>{item.address}</p>
                                    </div>
                                    <div className="d-flex border-top property_small">
                                        <small className="flex-fill text-center py-2 d-flex justify-content-center align-items-center">
                                            <MdOutlineBed style={{ color: "#1C9093", fontSize: "20px", margin: "0 .5rem" }} />
                                            {item.beds} Beds
                                        </small>
                                        <small className="flex-fill text-center py-2 d-flex justify-content-center align-items-center">
                                            <LuBath style={{ color: "#1C9093", fontSize: "20px", margin: "0 .5rem" }} />
                                            {item.baths} Bathroom
                                        </small>
                                        <small className="flex-fill text-center py-2 d-flex justify-content-center align-items-center">
                                            {/* <FaRegSquare style={{ color: "#1C9093", fontSize: "20px", margin: "0 .5rem" }} /> */}
                                            <img style={{ width:"20px", height:"20px",  margin: "0 .5rem" }} src={img7} alt="" />
                                            {item.area}
                                        </small>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
}

export default Properties;
