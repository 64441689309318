import React, { useEffect, useState } from 'react'
import { LuSave } from 'react-icons/lu'
import { useAuth } from '../../../Context/ContextProvider'

function Preferences({lable}) {
    const { updateSetting, loading, currency, area_unit } = useAuth()
    const [data, setData] = useState({
        notification: false,
        newsletter: false,
        newsletter1: false,
        currency: "",
        area_unit: "",
    })
    useEffect(() => {
        const user_data = JSON.parse(localStorage.getItem("agent_data"))
        let setting = user_data?.data?.user?.setting
        if (setting) {
            setData({
                notification: setting?.email_notif,
                newsletter: setting?.news_letter,
                newsletter1: setting?.automated_reports,
                currency: setting?.currency,
                area_unit: setting?.area_unit,
            })

        }
        else {
            setData({
                notification: false,
                newsletter: false,
                newsletter1: false,
                currency: "",
                area_unit: "",
            })

        }
    }, [])

    const submit = async () => {
        await updateSetting({
            email_notif: data?.notification,
            news_letter: data?.newsletter,
            automated_reports: data?.newsletter1,
            currency: data?.currency,
            area_unit: data?.area_unit,
        })
    }
    return (
        <>
            <div className='locationPurpos'>
                <div className='row'>
                    <div className='col-lg-5 col-md-6 col-12'>
                        <div className='d-flex align-items-center justify-content-between' style={{ borderBottom: "1px solid lightgray" }}>
                            <div className='inp_login m-0'>
                                <label className="logo_label my-2">Email Notification</label>
                                <p>Allow to to receive email notifications</p>
                            </div>
                            <div className="switch mx-5">
                                <input checked={data?.notification?true:false} onChange={(e) => setData({ ...data, notification: e.target.checked })} type="checkbox" id="toggle" />
                                <label for="toggle" className="slider"></label>
                            </div>

                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-lg-5 col-md-6 col-12'>
                        <div className='d-flex align-items-center justify-content-between p-0' style={{ borderBottom: "1px solid lightgray" }}>
                            <div className='inp_login m-0'>
                                <label className="logo_label my-2">Newsletters</label>
                                <p>Allow to stay updated and receive newsletter</p>
                            </div>
                            <div className="switch mx-5">
                                <input  checked={data?.newsletter?true:false} onChange={(e) => setData({ ...data, newsletter: e.target.checked })} type="checkbox" id="toggle2" />
                                <label for="toggle2" className="slider"></label>
                            </div>

                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-lg-5 col-md-6 col-12'>
                        <div className='d-flex align-items-center justify-content-between' style={{ borderBottom: "1px solid lightgray" }}>
                            <div className='inp_login m-0'>
                                <label className="logo_label my-2">Newsletters</label>
                                <p>Allow to stay updated and receive newsletter</p>
                            </div>
                            <div className="switch mx-5">
                                <input  checked={data?.newsletter1?true:false} onChange={(e) => setData({ ...data, newsletter1: e.target.checked })} type="checkbox" id="toggle1" />
                                <label for="toggle1" className="slider"></label>
                            </div>

                        </div>
                    </div>
                </div>
                <div className='row mt-3'>
                    <div className='col-lg-5 col-md-6 col-12'>
                        <div className='inp_login'>
                            <label>Currency</label>
                            <select  value={data?.currency} onChange={(e) => setData({ ...data, currency: e.target.value })} style={{ padding: ".7rem" }}>
                                {currency?.map((item) => {
                                    return (
                                        <option value={item?.id}>{item?.name}</option>
                                    )
                                })}
                            </select>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-lg-5 col-md-6 col-12'>
                        <div className='inp_login'>
                            <label>Area Unit</label>
                            <select  value={data?.area_unit} onChange={(e) => setData({ ...data, area_unit: e.target.value })} style={{ padding: ".7rem" }}>
                                {area_unit?.map((item) => {
                                    return (
                                        <option value={item?.id}>{item?.name}</option>
                                    )
                                })}
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div className='agency_setting my-3'>
                <div className='d-flex justify-content-end'>
                    <button onClick={submit}>Save Changes   <LuSave />  </button>
                </div>
            </div>
        </>
    )
}

export default Preferences