import React, { useEffect, useState } from 'react'
import { useAuth } from '../../Context/ContextProvider'
import OrderDetail from './OrderDetail';
import { PiEyeLight } from 'react-icons/pi';

function OrderHistory() {
    const [orderList,setOrderLIst]=useState([])
    const [orderStatus,setOrderStatus]=useState()
    const [orderData,setOrderData]=useState({
        products:[],
        orderID:"",
        order_status:""
    })
    const {getOrderLIst}=useAuth()
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const [open1, setOpen1] = React.useState(false);
    const handleOpen1 = () => setOpen1(true);
    const getStatusClass = (status) => {
        switch (status) {
          case 'Pending for Approval':
            return 'status-approval';
          case 'Saved':
            return 'status-saved';
          case 'Approved':
            return 'status-approved';
          case 'Rejected':
            return 'status-rejected';

          default:
            return '';
        }
      };
      const fetchData = async () => {
        try {
            let result = await getOrderLIst();
            if (result?.success) {
                setOrderLIst(result?.data?.data?.orders);
            }
        } catch (error) {
            console.error("An error occurred while fetching data:", error);
        }
    };
    useEffect(() => {

        
        fetchData();
        // eslint-disable-next-line
    }, []);
  return (
    <div className='add_propertey_main agency_setting'>
       
            <div className='content_box'>
                <div className='d-flex justify-content-between'>
            <h3 className='heading_mian'>Order History</h3>
                </div>
                <div className='locationPurpos my-3'>
                <div className='listing_table p-0'>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col">Order ID</th>
                                    <th scope="col">Product</th>
                                    <th scope="col">Date & Time</th>
                                    <th scope="col">Order Status      </th>
                                    <th scope="col">Price (PKR)</th>
                                    <th scope="col">Action </th>
                                 
                                </tr>
                            </thead>
                            <tbody>
                                {orderList?.reverse()?.map((item)=>{
                                        const date = new Date(item?.created_at);
                                        const formattedTime = date.toLocaleTimeString('en-US', {
                                          hour: '2-digit',
                                          minute: '2-digit',
                                          hour12: true
                                        });
                                        const formattedDate = date.toLocaleDateString('en-US', {
                                          year: 'numeric',
                                          month: 'short',
                                          day: 'numeric'
                                        });
                                    return(
                                        <tr>
                                        <td>{item?.order_code}</td>
                                        <td > 
                                            <div className='d-flex align-items-center'>
                                            {item?.products?.length}<div onClick={()=>{
                                                    handleOpen1()
                                                    setOrderStatus(item?.order_status?.name)
                                                    setOrderData({
                                                        products:item?.products,
                                                        orderID:item?.id,
                                                        order_status:item?.order_status?.name
                                                    })
                                                }} className='d-flex align-items-center justify-content-center ms-2' title='View' style={{cursor:"pointer", border:"1px solid #E3FEFF",height:"25px",width:"25px",fontSize:"15px",color:"#1C9093",background:"#F5F6FA",borderRadius:"50%"}}><PiEyeLight/></div> 

                                            </div>
                                            </td>
                                        <td> {`${formattedTime}, ${formattedDate}`}</td>
                                        <td><span className={`${getStatusClass(item?.order_status?.name)} status`}>{item?.order_status?.name}</span></td>
                                        <td>Rs {item?.total_price}</td>
                                        <td>
                                            <div className='table_action_button'>

                                            {item?.order_status?.name!=="Saved"?<div style={{background:"#1C9093",width:"max-content",borderRadius:"5px"}} className='action_btn d-flex'><button onClick={()=>{
                                                    handleOpen1()
                                                    setOrderStatus(item?.order_status?.name)
                                                    setOrderData({
                                                        products:item?.products,
                                                        orderID:item?.id,
                                                        order_status:item?.order_status?.name
                                                    })
                                                }} className='py-0'>View</button></div>:
                                            <div style={{background:"#1C9093",width:"max-content",borderRadius:"5px"}} className='action_btn d-flex'>
                                                <button onClick={()=>{
                                                    handleOpen()
                                                    setOrderData({
                                                        products:item?.products,
                                                        orderID:item?.id
                                                    })
                                                }} style={{borderRadius:"5px 0 0 5px",borderRight:"1px solid lightgray",}} className='btn1 py-0'>Pay Now</button>
                                                <button onClick={()=>{
                                                    handleOpen1()
                                                    setOrderStatus(item?.order_status?.name)
                                                    setOrderData({
                                                        products:item?.products,
                                                        orderID:item?.id,
                                                        order_status:item?.order_status?.name
                                                    })
                                                }} style={{borderRadius:"0 5px 5px 0"}} className='btn2 py-0'>View</button>
                                            </div>}
                                            </div>
                                        </td>
                                    </tr>
                                    )
                                })}
                              
                            </tbody>
                        </table>
                    </div>

                </div>
                                <OrderDetail orderStatus={orderStatus} fetchData={fetchData} order_status={orderData?.order_status} handleOpen={handleOpen} orderId={orderData?.orderID} cartData={orderData?.products} open={open} setOpen={setOpen} open1={open1} setOpen1={setOpen1}/>
            </div>

        </div>
  )
}

export default OrderHistory