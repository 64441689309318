import React from 'react'
import NavBar from '../Camponent/NavBarFooter/NavBar'
import Banner from '../Camponent/ConactUs/Banner'
import Footer from '../Camponent/NavBarFooter/Footer'

function Contactus() {

    return (
        <div>
            <NavBar />
            <Banner/>
            <Footer/>
        </div>
    )
}

export default Contactus