import React, { useState } from 'react'
import Modal from '@mui/material/Modal';
import { AiFillCloseCircle } from 'react-icons/ai';
import { RiAttachment2 } from 'react-icons/ri';
import { Box} from '@mui/material';
import { useAuth } from '../../Context/ContextProvider';
import { MdRemoveShoppingCart } from 'react-icons/md';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    boxShadow: 24,
};
function OrderDetail({open,fetchData,order_status,setOpen,handleOpen,open1,setOpen1,orderId,cartData,orderStatus}) {
    const handleClose = () => setOpen(false);
    const handleClose1 = () => setOpen1(false);
    const { updateOrder, loading,formatPriceWithCommas } = useAuth()
    const [fileName, setFileName] = useState("")
    const [data,setData]=useState({
        image:"",
    })
    const [fieldErrors, setFieldErrors] = React.useState({})
    const validateStep = () => {
        let errors = {

        };
        if (!data.image) {
            errors.image = "Image is required.";
        }
   
       
        setFieldErrors(errors);
        // Check if there are any errors
        return Object.keys(errors).length === 0;
    };
    const submit = async () => {
        if(validateStep()){
            let result=await updateOrder(data)
            if(result?.success){
              fetchData();
              handleClose()
              handleClose1()
              setData({
                  image:"",
              })
              setFileName("")
            }
        }
 
    }
    const handleFileChange = (event) => {
        let file = event.target.files[0]
        setFileName(file?.name || "");
        setData({...data,image:file,orderId});

    }
    const getStatusClass = (status) => {
        switch (status) {
          case 'Pending for Approval':
            return 'status-approval';
          case 'Saved':
            return 'status-saved';
          case 'Approved':
            return 'status-approved';
          case 'Rejected':
            return 'status-rejected';

          default:
            return '';
        }
      };
    return (
        <div>
            <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <div style={{ background: "white" }} className='modal_feature payment_modal p-3'>
                    <div className='d-flex justify-content-between align-items-center'>
                        <h4 className='heading'>Add User</h4>
                        <button onClick={handleClose} style={{ background: "none", border: "none", color: "#ECECEC", fontSize: "20px" }}><AiFillCloseCircle /></button>
                    </div>
                    <hr className='p-0 m-0' />
                    <h6 className='p-0 m-0'>Submit your payment receipt to complete the order</h6>
                    <p className='p-0 m-0'>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                        Lorem Ipsum has been the industry's standard dummy text.</p>
                    <div className='inp_login'>
                        <label>Attach File</label>
                        <div style={{ position: "relative" }}>
                            <input onChange={handleFileChange} id='receipt' type="file" hidden />
                            <input value={fileName} onClick={() => document.getElementById('receipt').click()} readOnly className='ps-5' type="text" placeholder='Attach File' />
                            <RiAttachment2 style={{ position: "absolute", left: "15px", top: "10px", fontSize: "18px" }} />
                            <label for="receipt" className='attach_lable' style={{ position: "absolute", right: "20px", top: "7px" }} >Attach File</label>
                        </div>
                        {fieldErrors?.image && <span className='text_error'>{fieldErrors?.image}</span>}
                    </div>
                    <div className='model_footer d-flex justify-content-between pt-3'>
                        <button onClick={submit} className='add_btn'>{loading? <div className="spinner-border text-light button_loading" role="status">

                        </div> : "Confirm"}</button>
                        <button onClick={handleClose} className='cancel_btn'>Cancel</button>
                    </div>
                </div>
            </Box>
        </Modal>
        <Modal
            open={open1}
            onClose={handleClose1}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <div style={{ background: "white" }} className='modal_feature payment_modal add_propertey_main p-3'>
                    <div className='d-flex justify-content-between align-items-center'>
                        <h4 className='heading'>Order ID: 1247854</h4>
                        <button onClick={handleClose1} style={{ background: "none", border: "none", color: "#ECECEC", fontSize: "20px" }}><AiFillCloseCircle /></button>
                    </div>
                    <hr className='p-0 m-0' />
                    <div className='d-flex justify-content-between header_date'><span>Date Time: 22 Aug, 2024. 05.12PM</span> <div><span>Order Status:</span> <span className={`${getStatusClass(orderStatus)} status`}>{orderStatus}</span></div> </div>
                    <hr className='p-0 m-0' />
                    <div style={{border:"none"}} className='locationPurpos order_summery py-0 px-2'>
                                {cartData?.length ? <>
                        <div className='cart_item pb-3'>
                            {cartData?.length ? <>
                                {cartData?.map((item) => {
                                    return (
                                        <div className='d-flex justify-content-between cart_list'>
                                            {item?.name === "Listing" ? <><span>{item?.name} ({item?.quantity})</span><span>Rs {formatPriceWithCommas(item?.total_price)}</span></> : <>
                                                <div className='mt-2'>
                                                    <span>{item?.name}</span>
                                                    <p style={{ padding: "0", margin: "0", fontSize: "11px" }}>{item?.name?.replace("Listing", "Credits")} <span>({item?.quantity}X)</span></p>
                                                    <p style={{ padding: "0", margin: "0", fontSize: "11px" }}>{"Listing"} <span>({item?.quantity}X)</span></p>
                                                </div>
                                                <span>Rs {formatPriceWithCommas(item?.product?.price)}</span>
                                            </>}
                                        </div>
                                    )
                                })}</> : ""}
                            {/* {cartData1?.length ? <>
                 
                                {cartData1?.map((item) => {
                                    return (
                                        <div className='d-flex justify-content-between'>
                                            <span>{item?.name} ({item?.quantity})</span><span>Rs {formatPriceWithCommas(item?.price)}</span>
                                        </div>
                                    )
                                })}
                            </> : ""} */}
                            <hr className='p-0 m-0 mt-2' />
                            <div className='d-flex justify-content-between align-items-center total m-0 p-0'>
                                <h6>Total</h6><span style={{ lineHeight: "0" }}>Rs {formatPriceWithCommas(cartData?.reduce((acc, item) => acc + (item.product.price * item.quantity), 0))}</span>
                            </div>
                        </div>
                    </>
                        : <div className='no_cart'>
                            <MdRemoveShoppingCart className='icon' />
                            <span className='mt-3'>No Item(s) in Cart</span>
                            <span>Rs 00</span>
                        </div>}
                            </div>

                    {order_status==="Saved"&&<div className='model_footer d-flex justify-content-between'>
                        <button onClick={handleOpen} className='add_btn'>{loading? <div className="spinner-border text-light button_loading" role="status">

                        </div> : "Pay Now"}</button>
                        <button onClick={handleClose1} className='cancel_btn'>Cancel</button>
                    </div>}
                </div>
            </Box>
        </Modal>
        </div>
    )
}

export default OrderDetail