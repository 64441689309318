import React from 'react'
import NavBar from '../Camponent/NavBarFooter/NavBar'
import Banner from '../Camponent/Lease/Banner'
import Listing from '../Camponent/Lease/Listing'

function Lease() {
  return (
    <div>
      <NavBar/>
      <Banner/>
      <Listing/>
    </div>
  )
}

export default Lease
