import React, { useEffect, useState } from 'react'
import { useAuth } from '../../Context/ContextProvider'

function QoutaAndCredets() {
    const { getQoutaLogs, getProductList,getQoutaState } = useAuth()
    const [header, setHeader] = useState([])
    const [header1, setHeader1] = useState([])
    const [data, setData] = useState([])
    const [logs, setlogs] = useState([])
    const [productLogs, setproductLogs] = useState([])
    const processLogs = (logs, headers) => {

        return logs.map(log => {
            const processedData = {};

            headers.forEach(header => {
                const matchingQuota = log.quota?.filter(q => q.offer_slug === header.offer_slug) || [];
                const matchingCredits = log.credits?.filter(c => c.offer_slug === header.offer_slug) || [];

                // Combine both quota and credits under the same header
                if (matchingQuota.length > 0 || matchingCredits.length > 0) {
                    processedData[header.offer_slug] = [
                        ...matchingQuota,
                        ...matchingCredits
                    ];
                }
            });

            return processedData;
        });
    };
    useEffect(() => {
        const fetchData = async (creditsHeaders, listingsHeaders) => {
            try {
                let result = await getQoutaLogs();

                if (result?.success) {
                    const organizedLogs = await processLogs(result?.data?.data?.logs, [...listingsHeaders, ...creditsHeaders]);
                    setData(organizedLogs);
                    setlogs(result?.data?.data?.logs)
                }
            } catch (error) {
                console.error("An error occurred while fetching data:", error);
            }
        };
        const fetchqouta= async () => {
            try {
                let result = await getQoutaState();

                if (result?.success) {
                    setproductLogs(result?.data?.data?.offer_logs);
              
                }
            } catch (error) {
                console.error("An error occurred while fetching data:", error);
            }
        };
        const fetchProduct = async () => {
            try {
                let result = await getProductList();
                if (result?.success) {
                    let product = result?.data?.data?.products
                    const listingsHeaders = product.filter(header => header.type === 'listings');
                    const creditsHeaders = product.filter(header => header.type === 'credits');
                    setHeader(listingsHeaders)
                    setHeader1(creditsHeaders)

                    fetchData(creditsHeaders, listingsHeaders);
                }
            } catch (error) {
                console.error("An error occurred while fetching data:", error);
            }
        };
        fetchProduct();
        fetchqouta()
        // eslint-disable-next-line
    }, [])
console.log(data);

const ProductCard = ({ product, log }) => (
    <div style={{ marginRight: "1rem" }} className='credits_box'>
    <h6 className='m-0 p-0'>{product.name} ({log?.total || 0})</h6>
    <div className='m-0 d-flex justify-content-between'>
        <p className='m-0'>Available</p>
        <p className='m-0'>{log?.available || 0}</p>
    </div>
    <div className='m-0 d-flex justify-content-between'>
        <p className='m-0'>Used</p>
        <p className='m-0'>{log?.used || 0}</p>
    </div>
    <div className='m-0 d-flex justify-content-between'>
        <p className='m-0'>Total</p>
        <p className='m-0'>{log?.total || 0}</p>
    </div>
</div>
  );
    

    return (
        <div className='add_propertey_main'>
            <div className='heading_title_main d-flex justify-content-between'>
                <h3 className='heading_mian'>Quota and Credits</h3>
                <button>Buy quota and credits</button>
            </div>
            <div className='content_box qouta_main mt-3'>
                <div className='qouta_box_mian'>
                {header.map((product) => {
        const log = productLogs?.find((log) => log.offer_slug === product.offer_slug);
        return <ProductCard key={product.id} product={product} log={log} />;
      })}
                {header1.map((product) => {
        const log = productLogs?.find((log) => log.offer_slug === product.offer_slug);
        return <ProductCard key={product.id} product={product} log={log} />;
      })}
                </div>
                <div className='mt-3'>
                    <h3 className='heading_mian'>logs</h3>
                    <div className='inp_date'>
                        <input placeholder='Select date' />
                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" viewBox="0 0 15 14" fill="none">
                            <path d="M0.634766 4.6665V12.0556C0.634766 13.1278 1.44032 14.0001 2.43047 14.0001H13.2047C14.1948 14.0001 15.0004 13.1278 15.0004 12.0556V4.6665H0.634766ZM4.94446 11.6667C4.94446 12.0964 4.62302 12.4445 4.22617 12.4445H3.50789C3.11104 12.4445 2.78961 12.0964 2.78961 11.6667V10.8889C2.78961 10.4591 3.11104 10.1111 3.50789 10.1111H4.22617C4.62302 10.1111 4.94446 10.4591 4.94446 10.8889V11.6667ZM4.94446 7.77769C4.94446 8.20742 4.62302 8.55548 4.22617 8.55548H3.50789C3.11104 8.55548 2.78961 8.20742 2.78961 7.77769V6.99989C2.78961 6.57016 3.11104 6.2221 3.50789 6.2221H4.22617C4.62302 6.2221 4.94446 6.57016 4.94446 6.99989V7.77769ZM8.89501 11.6667C8.89501 12.0964 8.57357 12.4445 8.17672 12.4445H7.45844C7.06159 12.4445 6.74016 12.0964 6.74016 11.6667V10.8889C6.74016 10.4591 7.06159 10.1111 7.45844 10.1111H8.17672C8.57357 10.1111 8.89501 10.4591 8.89501 10.8889V11.6667ZM8.89501 7.77769C8.89501 8.20742 8.57357 8.55548 8.17672 8.55548H7.45844C7.06159 8.55548 6.74016 8.20742 6.74016 7.77769V6.99989C6.74016 6.57016 7.06159 6.2221 7.45844 6.2221H8.17672C8.57357 6.2221 8.89501 6.57016 8.89501 6.99989V7.77769ZM12.8456 11.6667C12.8456 12.0964 12.5241 12.4445 12.1273 12.4445H11.409C11.0121 12.4445 10.6907 12.0964 10.6907 11.6667V10.8889C10.6907 10.4591 11.0121 10.1111 11.409 10.1111H12.1273C12.5241 10.1111 12.8456 10.4591 12.8456 10.8889V11.6667ZM12.8456 7.77769C12.8456 8.20742 12.5241 8.55548 12.1273 8.55548H11.409C11.0121 8.55548 10.6907 8.20742 10.6907 7.77769V6.99989C10.6907 6.57016 11.0121 6.2221 11.409 6.2221H12.1273C12.5241 6.2221 12.8456 6.57016 12.8456 6.99989V7.77769Z" fill="#646C6D" />
                            <path d="M13.2047 1.16669H12.8456C12.8456 0.525012 12.3607 0 11.7681 0C11.1756 0 10.6907 0.525012 10.6907 1.16669H4.94446C4.94446 0.525012 4.45962 0 3.86703 0C3.27445 0 2.78961 0.525012 2.78961 1.16669H2.43047C1.43924 1.16669 0.634766 2.03783 0.634766 3.11118V3.88898H15.0004V3.11118C15.0004 2.03783 14.1959 1.16669 13.2047 1.16669ZM3.86703 0.777796C4.06456 0.777796 4.22617 0.9528 4.22617 1.16669V1.94449C4.22617 2.15838 4.06456 2.33339 3.86703 2.33339C3.66951 2.33339 3.50789 2.15838 3.50789 1.94449V1.16669C3.50789 0.9528 3.66951 0.777796 3.86703 0.777796ZM11.7681 0.777796C11.9657 0.777796 12.1273 0.9528 12.1273 1.16669V1.94449C12.1273 2.15838 11.9657 2.33339 11.7681 2.33339C11.5706 2.33339 11.409 2.15838 11.409 1.94449V1.16669C11.409 0.9528 11.5706 0.777796 11.7681 0.777796Z" fill="#646C6D" />
                        </svg>
                    </div>

                </div>
                <div className='qouta_table_main'>
                    <div className='listing_table'>
                        <table className="table">
                            <thead>
                                <tr style={{ borderColor: "white" }}>
                                    <th style={{ background: "white", color: "#1C9093", width: "100px" }} scope="col"></th>
                                    <th className='table_heading' colSpan={header.length + 1} style={{ background: "white" }}>Quota</th>
                                    <th className='table_heading' colSpan={header1.length + 1} style={{ background: "white" }}>Credits</th>
                                    <th style={{ background: "white", color: "#1C9093", width: "100px" }} scope="col"></th>
                                </tr>
                                <tr style={{ borderColor: "white" }}>
                                    <th style={{ background: "white", color: "#1C9093", width: "100px" }} scope="col">Purchase Date</th>
                                    {header?.map((item, index) => (
                                        <th key={index} style={{ background: "#F0F3F8" }} scope="col">{item.name}</th>
                                    ))}
                                    <th style={{ background: "#F0F3F8" }} scope="col">Expiry Date</th>
                                    {header1?.map((item, index) => (
                                        <th key={index} style={{ background: "#F0F3F8" }} scope="col">{item.name}</th>
                                    ))}
                                    <th style={{ background: "#F0F3F8" }} scope="col">Expiry Date</th>
                                    <th style={{ background: "white", color: "#1C9093", width: "100px" }} scope="col">Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data?.map((log, index) => {
                                    const totalQuantity = Object.values(log).flat().reduce((sum, item) => sum + item.quantity, 0);
                                    return (
                                        <tr key={index}>
                                            <td>{logs[index]?.purchase_date}</td>

                                            {header?.map(header => (
                                                <td key={header.name}>
                                                    {log[header.offer_slug] ? log[header.offer_slug].map(item => (
                                                        <div key={item.product_id}>{item.quantity}</div>
                                                    )) : '-'}
                                                </td>
                                            ))}
                                            <td>
                                                {header?.find(header => log[header.offer_slug])?.offer_slug &&
                                                    log[header?.find(header => log[header.offer_slug])?.offer_slug]?.[0]?.expiry_date
                                                    ? log[header?.find(header => log[header.offer_slug])?.offer_slug]?.[0]?.expiry_date
                                                    : '-'}
                                            </td>

                                            {/* {header1?.map(header => (

                                                <td key={header.name}>
                                                    {log[header.name] && log[header.name].some(item => item.product_name === header.name)
                                                        ? log[header.name].map(item => (
                                                            item.product_name === header.name && <div key={item.product_id}>{item.quantity}</div>
                                                        ))
                                                        : '-'
                                                    }
                                                </td>

                                            ))} */}
                                             {header1?.map(header => (
                                                <td key={header.offer_slug}>
                                                    {log[header.offer_slug] ? log[header.offer_slug].map(item => (
                                                        <div key={item.product_id}>{item.quantity}</div>
                                                    )) : '-'}
                                                </td>
                                            ))}

                                            <td>
                                                {header1?.find(header => log[header.offer_slug])?.offer_slug &&
                                                    log[header1?.find(header => log[header.offer_slug])?.offer_slug]?.[0]?.expiry_date
                                                    ? log[header1?.find(header => log[header.offer_slug])?.offer_slug]?.[0]?.expiry_date
                                                    : '-'}
                                            </td>

                                            <td className='ps-4'>{totalQuantity < 10 ? `0${totalQuantity}` : totalQuantity}</td>
                                        </tr>

                                    )
                                }
                                )}

                            </tbody>
                        </table>
                    </div>

                </div>

            </div>
            <div>

            </div>
        </div>
    )
}

export default QoutaAndCredets