import React, { useState } from "react";

import img from '../../Images/AgentProfile/Rectangle 1813.png'
import img1 from '../../Images/Frame 1300192747.png'
import img2 from '../../Images/Frame 1300192750.png'
import img3 from '../../Images/AgentProfile/Group 1000014158.png'
import img_2 from "../../Images/listing/Ellipse 374.png";
import { IoDiamond } from 'react-icons/io5'
import { CiLocationOn } from 'react-icons/ci'
import { FaPhoneAlt, FaShareAlt } from 'react-icons/fa'
import { MdArrowOutward } from 'react-icons/md'
import { IoIosMail, IoMdArrowDropdown } from 'react-icons/io'
import whatsapp from "../../Images/listing/whatsapp.png";
import { IoIosCall } from "react-icons/io";


function Profile() {

   



  
    const [fieldErrors, setFieldErrors] = React.useState({})
    const [data, setData] = useState({
        email: "",
        password: "",
        name: "",
        contact: "",
    })
    const validateStep = () => {
        let errors = {

        };
        if (!data.name) {
            errors.name = "Name is required.";
        }
        if (!data.email) {
            errors.email = "Email is required.";
        } else if (!isValidEmail(data.email)) {
            errors.email = "Please enter a valid email address.";
        }
        if (!data.subject) {
            errors.subject = "Subject is required.";
        }

        if (!data.contact) {
            errors.contact = "Contact is required.";
        }
        else if (!isValidPakistaniPhoneNumber(data.contact)) {
            errors.contact = "Please enter a valid Pakistan phone number.";
        }
        setFieldErrors(errors);
        // Check if there are any errors
        return Object.keys(errors).length === 0;
    };
    const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const formatPakistaniPhoneNumber = (phoneNumber) => {

        let phone = phoneNumber.replace(/\D/g, ''); // Remove non-digit characters

        // Handle different input formats
        if (phone.startsWith('92')) {
            phone = phone.slice(2);
        } else if (phone.startsWith('0')) {
            phone = phone.slice(1);
        }

        if (phone.length === 10) {
            return `+92${phone}`;
        }

        return phoneNumber; // Return original input if it doesn't match the expected formats
    };
    const isValidPakistaniPhoneNumber = (phoneNumber) => {
        // Remove all non-digit characters except '+'
        // eslint-disable-next-line
        let cleanedPhoneNumber = phoneNumber.replace(/[^\d\+]/g, '');

        // Log the cleaned phone number
        console.log(`Cleaned Phone Number: "${cleanedPhoneNumber}"`);
        const pakistaniPhoneRegex = /^(?:0|\+92)?\s?(?:\d\s?){9,10}$/;
        // Regular expression for Pakistani phone number
        // const pakistaniPhoneRegex = /^\+92\d{11}$/;

        // Validate phone number format and length
        const isValid = pakistaniPhoneRegex.test(cleanedPhoneNumber);
        console.log(`Is Valid: ${phoneNumber}`);

        return isValid;
    };
    const submit = (event) => {
        event.preventDefault();
        if (validateStep()) {
            console.log("hello");
        }
    }





    return (
        <>
            <div className="container-fluid mt-4 px-3">
                <div className="row px-4">
                    <div className="col-lg-8 col-12 p-0">
                        <div className="row m-0 py-3 px-2 main-row">
                            <div className="col-10 p-2 d-flex">
                                <div className="img-box">
                                    <img src={img} alt="" />
                                </div>
                                <div className="imgbox-text mx-2 px-1">
                                    <div className="first-box d-flex">
                                        <div className="heading">
                                            Stallion Real Estate & Builders
                                        </div>
                                        <div className="primium-card mx-3">
                                            <IoDiamond className='mx-1' /> Titanium plus
                                        </div>
                                    </div>
                                    <div className="second-box my-1">
                                        <div className="location">
                                            <span><CiLocationOn /> </span>  Lahore
                                        </div>
                                    </div>
                                    <div className="third-box d-flex">
                                        <div className="house-box" style={{ marginRight: "16px" }}><span><img src={img1} alt="" /></span>52 for Sale</div>
                                        <div className="house-box"><span><img src={img2} alt="" /></span>100 for Rent</div>

                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-2 col-12 pt-1 d-flex justify-content-lg-start align-items-start  justify-content-end ">
                                <button className='btn share-btn my-1' style={{ marginLeft: "8px" }}> <FaShareAlt /> Share</button>
                            </div>
                        </div>

                        <div className="row second-row px-0 mx-0 mt-4 pt-1">
                            <div className="col-12 p-0 m-0">
                                <div className="properties">
                                    Properties By Stallion Real Estate & Builders
                                </div>
                            </div>
                        </div>

                        <div className="row third-row p-0 m-0">
                            <div className="col-md-6 col-12  py-2 special-col" style={{ paddingRight: "12px", paddingLeft: "0" }}>
                                <div className="house-card pb-2">
                                    <div className="house-box d-flex justify-content-between align-items-center px-2 py-2">
                                        <div className="house-img d-flex align-items-center">
                                            <span> <img src={img1} alt="" /></span>
                                            52 for Sale
                                        </div>
                                        <div className="view d-flex align-items-center">
                                            <a href="/">View All <MdArrowOutward /></a>
                                        </div>
                                    </div>
                                    <div className="card-details d-flex px-1 my-1">
                                        <div className="details-box mx-1">
                                            <div className="quantity">22</div>
                                            <div className="house-type">Residential Plots</div>
                                        </div>
                                        <div className="details-box1 mx-1">
                                            <div className="quantity">22</div>
                                            <div className="house-type1  text-center">Commercial Plots</div>
                                        </div>
                                        <div className="details-box mx-1">
                                            <div className="quantity">22</div>
                                            <div className="house-type">Building</div>
                                        </div>
                                        <div className="details-box mx-1">
                                            <div className="quantity">22</div>
                                            <div className="house-type">Houses</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-12  special-col-1  py-2" style={{ paddingLeft: "12px", paddingRight: "0" }}>
                                <div className="house-card pb-2">
                                    <div className="house-box d-flex justify-content-between align-items-center px-2 py-2">
                                        <div className="house-img d-flex align-items-center">
                                            <span> <img src={img2} alt="" /></span>
                                            52 for Sale
                                        </div>
                                        <div className="view d-flex align-items-center">
                                            <a href="/">View All <MdArrowOutward /></a>
                                        </div>
                                    </div>
                                    <div className="card-details d-flex px-1 my-1">
                                        <div className="details-box mx-1">
                                            <div className="quantity">22</div>
                                            <div className="house-type">House</div>
                                        </div>
                                        <div className="details-box mx-1">
                                            <div className="quantity">22</div>
                                            <div className="house-type">Offices</div>
                                        </div>
                                        <div className="details-box mx-1">
                                            <div className="quantity">22</div>
                                            <div className="house-type">Building</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row forth-row px-0 mx-0 mt-3 pt-1">
                            <div className="col-12 p-0 m-0">
                                <div className="team">
                                    Agency Team
                                </div>
                            </div>
                        </div>


                        <div className="row mx-0 mt-3 p-0 d-flex justify-content-md-start justify-content-center">
                            <div className="col-md-3 col-8 p-0 ">
                                <div className="team-card text-center py-2">
                                    <div className="user-img mt-1">
                                        <img className='shadow-img' src={img3} alt="" />
                                    </div>
                                    <div className="user-name text-center mt-2 mb-1">Ehsaan Elahi</div>
                                    <div className="user-post mb-2">CEO</div>
                                    <div className="contact mb-1">
                                        <div className="contact-box" style={{ marginRight: "5px" }}> <IoIosMail /> Email</div>
                                        <div className="contact-box call-box" style={{ marginLeft: "5px" }}> <span><FaPhoneAlt /></span> Call</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 col-8 p-0 mx-3 my-md-0 my-4">
                                <div className="team-card text-center py-2">
                                    <div className="user-img mt-1">
                                        <img src={img3} alt="" />
                                    </div>
                                    <div className="user-name text-center mt-2 mb-1">Ehsaan Elahi</div>
                                    <div className="user-post mb-2">Plots Agent</div>
                                    <div className="contact mb-1">
                                        <div className="contact-box" style={{ marginRight: "5px" }}> <IoIosMail /> Email</div>
                                        <div className="contact-box call-box" style={{ marginLeft: "5px" }}> <span><FaPhoneAlt /></span> Call</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 col-8 p-0 ">
                                <div className="team-card text-center py-2">
                                    <div className="user-img mt-1">
                                        <img src={img3} alt="" />
                                    </div>
                                    <div className="user-name text-center mt-2 mb-1">Ehsaan Elahi</div>
                                    <div className="user-post mb-2">Home Agent</div>
                                    <div className="contact mb-1">
                                        <div className="contact-box" style={{ marginRight: "5px" }}> <IoIosMail /> Email</div>
                                        <div className="contact-box call-box" style={{ marginLeft: "5px" }}> <span><FaPhoneAlt /></span> Call</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row fifth-row mt-4 pt-3 mx-0 px-0">
                            <div className="col-8 p-0">
                                <div className="agency">
                                    About Agency
                                </div>
                            </div>
                            <div className="col-12 p-0 mt-3">
                                <div className="agency-text">
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                                </div>
                            </div>
                            <div className="col-12 px-2 d-flex justify-content-end align-items-center">
                                <div className="read-function px-4 mb-3">
                                    Read more <IoMdArrowDropdown />
                                </div>
                            </div>
                            <div className='hr' />
                        </div>
                        <div className="row fifth-row mt-4 pt-3 mx-0 px-0">
                            <div className="col-8 p-0">
                                <div className="agency d-flex">
                                    <div className="user-image"><img src={img3} alt="" /></div>
                                    Ehsan Elahi (CEO)
                                </div>
                            </div>
                            <div className="col-12 p-0 mt-3">
                                <div className="agency-text">
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                                </div>
                            </div>
                            <div className="col-12 px-2 d-flex justify-content-end align-items-center">
                                <div className="read-function px-4 mb-3">
                                    Read more <IoMdArrowDropdown />
                                </div>
                            </div>
                            <div className='hr' />
                        </div>
                    </div>
                    <div className="col-lg-4 col-12 my-lg-0 mt-5 mb-4" style={{ paddingLeft: "26px" }}>
                        <div className="logo_prop p-3">
                            <div className="logo_info">
                                <div className="logo_img">

                                    <img src={img_2} alt="" />
                                </div>

                                <p className="heading_info">Stallion Real Estate & Builders</p>
                                <p className="content_info">
                                    Ehsaan Elahi
                                    <br />
                                    Agent ID: 50402718
                                </p>
                                <div className="info_btns">
                                    <button><IoIosCall className="me-2" />Call</button>
                                    <button><img src={whatsapp} alt="" className="me-2" />WhatsApp</button>
                                </div>

                            </div>
                            <form onSubmit={submit}>
                                <div className='inp_login'>
                                    <label className="logo_label">Name</label>
                                    <input onChange={(e) => setData({ ...data, name: e.target.value })} type='text' placeholder='Name' />
                                    {fieldErrors?.name && <span className='text_error'>{fieldErrors?.name}</span>}        </div>
                                <div className='inp_login'>
                                    <label className="logo_label">Email</label>
                                    <input onChange={(e) => setData({ ...data, email: e.target.value })} type='email' placeholder='Email' />
                                    {fieldErrors?.email && <span className='text_error'>{fieldErrors?.email}</span>}
                                </div>
                                <div className='inp_login'>
                                    <label className="logo_label">Subject</label>
                                    <input onChange={(e) => setData({ ...data, subject: e.target.value })} type='text' placeholder='General Inquiry' />
                                    {fieldErrors?.subject && <span className='text_error'>{fieldErrors?.subject}</span>}
                                </div>

                                <div className='inp_login'>
                                    <label className="logo_label">Contact</label>
                                    <div className='contact_contry'>
                                        <div className='img_container'>
                                            <img src='./image/Group.png' alt='...' />
                                            <span>+92</span>
                                        </div>
                                        <input style={{ borderRadius: " 0 5px 5px 0 ", borderLeft: "none" }} onChange={(e) => setData({ ...data, contact: formatPakistaniPhoneNumber(e.target.value) })} type='number' placeholder='e.g 3324751060' />
                                    </div>
                                    {fieldErrors?.contact && <span className='text_error'>{fieldErrors?.contact}</span>}
                                </div>
                                <div className='inp_login'>
                                    <label className="logo_label">Message</label>
                                    <textarea style={{ minHeight: "100px", width: "100%", border: "1px solid lightgray", borderRadius: "5px", padding: ".5rem" }} onChange={(e) => setData({ ...data, email: e.target.value })} type='email' placeholder='Write your message.....' />
                                    {fieldErrors?.email && <span className='text_error'>{fieldErrors?.email}</span>}
                                </div>
                                <button type='submit' className='Sign_inBtn py-3'>Send Email</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Profile
