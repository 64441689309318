import React from 'react'

import img from '../../Images/HousingSocietyDetailsExpand/Rectangle 1795.png'
import { IoLocationOutline } from 'react-icons/io5'
import { PiShareFatThin } from 'react-icons/pi'
import { CiHeart } from 'react-icons/ci'

function Banner() {
  return (
    <>
      <div className="container-fluid px-4 pt-4 border">
        <div className="row border mt-1 mx-0 p-0 ">
          <div className="col-8">
            <div className="front-img">

            </div>
            <div className="details-card-box px-2 py-4">
              <div className="location-box">
                <div className="row m-0 p-0">
                  <div className="col-10 p-0 border">
                    <div className="location-details-box d-flex">
                      <div className="img-box my-1">
                        <img src={img} alt="" />
                      </div>
                      <div className="location mx-3">
                        <div className="society-name">Icon Park Diamond Society</div>
                        <div className="society-location my-1"><IoLocationOutline />  Raiwind Road, Lahore </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-2 border">
                    <div className="location-sharing-icons d-flex">
                      <div className="icon"><CiHeart /></div>
                      <div className="icon"><PiShareFatThin /></div>
                      <div className="icon"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-4"></div>
        </div>
      </div>
    </>
  )
}

export default Banner
