import React, { useEffect, useState } from 'react'
import { useAuth } from '../../../Context/ContextProvider'
import { GoDotFill } from 'react-icons/go'
import { BsFire } from 'react-icons/bs'
import { RiFireFill } from 'react-icons/ri'
import { HiOutlineDotsVertical } from 'react-icons/hi'
import { FiEye } from 'react-icons/fi'
import { MdOutlineEdit } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import { Menu, MenuItem } from '@mui/material'

function TableDashboard() {
    function formatPrice(value) {
        if (value >= 10000000) {
            // Convert to Crore
            return (value / 10000000).toFixed(2) + ' Cr';
        } else if (value >= 100000) {
            // Convert to Lakh
            return (value / 100000).toFixed(2) + ' Lakh';
        } else if (value >= 1000) {
            // Convert to Thousand
            return (value / 1000).toFixed(2) + ' Thousand';
        } else {
            // Less than 1000, show as is
            return value.toString();
        }
    }
    const [anchorEl1, setAnchorEl1] = React.useState(null);
    const open1 = Boolean(anchorEl1);
    const handleClick1 = (event) => {
        setAnchorEl1(event.currentTarget);
    };
    const handleClose1 = () => {
        setAnchorEl1(null);
    };
    const {recentProperty,getQoutaState,upgradeProperty,deleteProperty}=useAuth()
    const [propertyList,setPropertyList]=useState([])
    const [productData, setProductData] = useState([])
    const [property_ids,setPropoertyID]=useState("")

    useEffect(()=>{
        const fetchCart = async () => {
            try {
                let result = await recentProperty();
                if (result?.success) {
                    let propertyData = result?.data?.data?.properties;
                    setPropertyList(propertyData)
          
                }
            } catch (error) {
                console.error("An error occurred while fetching data:", error);
            }
        };
        const fetchData2 = async () => {
            try {
                let result = await getQoutaState();
                if (result?.success) {
                    setProductData(result?.data?.data?.product_logs)

                }

            } catch (error) {
                console.error("An error occurred while fetching data:", error);
            }
        };
        fetchCart()
        fetchData2()
    },[])
    const navigate=useNavigate()
  return (
    <div className='add_propertey_main p-0 mt-2'>
        <h5 className='heading'>Recent Listings</h5>
        <div className='locationPurpos my-4'>
                    <div className='listing_table'>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col">ID</th>
                                    <th scope="col">Property</th>
                                    <th scope="col">Price</th>
                                    <th scope="col">Health</th>
                                    <th scope="col">Plateform</th>
                                    <th scope="col">Views</th>
                                    <th scope="col">Leads</th>
                                    <th scope="col">Posted On</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Quota</th>
                                    <th scope="col">Upgrades</th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {propertyList?.map((item,i)=>{
                                    return(
                                        <tr>
                                        <th scope="row">{i+1}</th>
                                        <td><span >{item?.title}</span></td>
                                        <td>{formatPrice(item?.price)}</td>
                                        <td><GoDotFill style={{ color: "#FFB7A0", fontSize: "20px" }} /></td>
                                        <td><span className='plateform'>Pakistan Property</span></td>
                                        <td>260</td>
                                        <td>260</td>
                                        <td>Jul 25,2024</td>
                                        <td><span className='status'>{item?.status}</span></td>
                                        <td>260</td>
                                        <td><div className='d-flex align-items-center'>
                                                {productData?.map((items) => {
                                                    if (items?.name !== "Listing") {
                                                        return (
                                                            <span title={items?.name} onClick={() => {
                                                                if (items?.available) {
                                                                    upgradeProperty({ product_id: items?.product_id, property_id: item?.id })
                                                                }
                                                            }} style={{ cursor: `${items?.available ? "pointer" : "not-allowed"}` }} className='icons'>
                                                                <img src={items?.image} alt='...' />
                                                            </span>
                                                        )
                                                    }

                                                })}
                                               
                                           
                                            </div></td>
                                        <td><div className='d-flex align-items-center'>
                                            <span className='icons'>
                                            <FiEye />
    
                                            </span>
                                            <span className='icons'>
    
                                                <MdOutlineEdit onClick={()=>{
                                                    navigate(`/agent/add-property/${item?.id}`)
                                                }}/>
                                            </span>
                                            <HiOutlineDotsVertical 
                                              aria-label="more"
                                              id="long-button"
                                       
                                              aria-controls={open1 ? 'long-menu' : undefined}
                                              aria-expanded={open1 ? 'true' : undefined}
                                              aria-haspopup="true"
                                              onClick={(e)=>{
                                                  setPropoertyID(item?.id)
                                                  handleClick1(e)
                                              }}
                                            style={{fontSize:"20px",cursor:"pointer"}} />
                                        </div></td>
    
                                    </tr>
                                    )
                                })}
                           

                            </tbody>
                        </table>
                    </div>
                    <Menu
                        id="long-menu"

                        MenuListProps={{
                            'aria-labelledby': 'long-button',
                        }}
                        anchorEl={anchorEl1}
                        open={open1}
                        className='locationPurpos_menu'
                        onClose={handleClose1}
                        slotProps={{
                            paper: {
                                style: {
                                    // maxHeight: ITEM_HEIGHT * 4.5,
                                    width: '100px',
                                    padding: "0"
                                },
                            },
                        }}
                    >
                        <MenuItem style={{ paddingY: "0" }} onClick={handleClose1}>
                            <div className='icons_back me-2'><svg xmlns="http://www.w3.org/2000/svg" width="12" height="9" viewBox="0 0 12 9" fill="none">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M1.28287 0.118514C1.03764 -0.0722122 0.684239 -0.0280335 0.493513 0.217181C0.302787 0.462402 0.346966 0.815807 0.592186 1.00653L1.87341 2.00305C1.3787 2.55676 0.954738 3.19526 0.610428 3.87901L0.598233 3.90314C0.546866 4.00439 0.480548 4.13517 0.450882 4.29644C0.427285 4.42469 0.427285 4.57533 0.450882 4.70352C0.480548 4.86479 0.546866 4.99557 0.598233 5.09688L0.610428 5.12101C1.7015 7.28774 3.5924 8.99998 6.00003 8.99998C7.25507 8.99998 8.36971 8.53469 9.30385 7.78229L10.7171 8.88147C10.9624 9.07221 11.3157 9.02805 11.5065 8.7828C11.6972 8.53761 11.653 8.1842 11.4078 7.99345L1.28287 0.118514ZM8.38557 7.06803L7.37437 6.28155C6.99424 6.57529 6.51752 6.75 6.00003 6.75C4.75737 6.75 3.75002 5.74262 3.75002 4.50001C3.75002 4.18237 3.81583 3.88014 3.93456 3.60615L2.76261 2.69464C2.32495 3.17235 1.93835 3.74328 1.61522 4.38498C1.59907 4.41704 1.58711 4.44083 1.57702 4.46153C1.56814 4.47976 1.56261 4.49174 1.55901 4.50001C1.56261 4.50828 1.56814 4.52026 1.57702 4.53848C1.58711 4.55918 1.59907 4.58292 1.61522 4.61504C2.60232 6.57529 4.18156 7.87499 6.00003 7.87499C6.86138 7.87499 7.66907 7.58334 8.38557 7.06803ZM4.88549 4.34577C4.87857 4.39617 4.87503 4.4477 4.87503 4.50001C4.87503 5.12134 5.37869 5.625 6.00003 5.625C6.14999 5.625 6.29314 5.59564 6.42398 5.54237L4.88549 4.34577Z" fill="#646C6D" />
                                <path d="M5.41016 2.32824L8.25015 4.53717C8.25037 4.5248 8.25048 4.51237 8.25048 4.49999C8.25048 3.25735 7.24311 2.25 6.0005 2.25C5.7962 2.25 5.59826 2.27722 5.41016 2.32824Z" fill="#646C6D" />
                                <path d="M10.3851 4.61502C10.1905 5.00134 9.97297 5.36207 9.73509 5.6922L10.6226 6.38249C10.9102 5.98813 11.1668 5.56406 11.3899 5.12098L11.4021 5.09685C11.4535 4.99555 11.5198 4.86477 11.5494 4.7035C11.573 4.5753 11.573 4.42467 11.5494 4.29642C11.5198 4.13515 11.4535 4.00437 11.4021 3.90312L11.3899 3.87899C10.2988 1.71226 8.40793 0 6.00033 0C5.03548 0 4.15368 0.274965 3.37305 0.743953L4.34572 1.50048C4.86532 1.25706 5.42068 1.12499 6.00033 1.12499C7.81877 1.12499 9.39798 2.4247 10.3851 4.38496C10.4013 4.41702 10.4132 4.44081 10.4233 4.46151C10.4322 4.47974 10.4377 4.49172 10.4413 4.49999C10.4377 4.50825 10.4322 4.52024 10.4233 4.53846C10.4132 4.55916 10.4013 4.5829 10.3851 4.61502Z" fill="#646C6D" />
                            </svg></div>  Hide
                        </MenuItem>
                        <MenuItem style={{ paddingY: "0" }} onClick={() => {
                            handleClose1()
                            deleteProperty(property_ids)
                        }}>
                            <div className='icons_back me-2'><svg xmlns="http://www.w3.org/2000/svg" width="12" height="14" viewBox="0 0 12 14" fill="none">
                                <path d="M4.63672 6.375V10.125" stroke="#646C6D" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M7.36426 6.375V10.125" stroke="#646C6D" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M0.545898 3.875H11.455" stroke="#646C6D" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M1.90918 3.875H6.00009H10.091V10.75C10.091 11.7856 9.17525 12.625 8.04554 12.625H3.95463C2.82496 12.625 1.90918 11.7856 1.90918 10.75V3.875Z" stroke="#646C6D" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M3.95508 2.625C3.95508 1.93464 4.5656 1.375 5.31871 1.375H6.68235C7.43549 1.375 8.04599 1.93464 8.04599 2.625V3.875H3.95508V2.625Z" stroke="#646C6D" stroke-linecap="round" stroke-linejoin="round" />
                            </svg></div>  Delete
                        </MenuItem>

                    </Menu>
                </div>
    </div>
  )
}

export default TableDashboard