import React from 'react'
import img from '../../Images/unsplash_77JACslA8G0.png'
import img1 from '../../Images/Group 1000014178.png'
import { GoArrowUpRight } from 'react-icons/go'
import { GrInstagram, GrLinkedinOption } from 'react-icons/gr'
import { FaFacebookF, FaTwitter } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
function BlogListing() {
    const navigate=useNavigate()

    return (
        <>
            <div className='blog-listing'>
                <div className='row m-0 '>
                    <div className='col-lg-9 col-md-12 col-12 list-col'>

                        <div className='row p-2'>
                       
                                <div className='col-lg-6 col-12 px-3'>
                                    <div onClick={()=>{
                        navigate("/Blogsingle")
                    }} className='Interior-card'>
                                        <div className='Interior-img'>
                                            <div className='img-heading'>
                                                <h4>Interior Design</h4>
                                            </div>
                                            <img src={img} alt='' />
                                        </div>
                                        <div className='listing-heading'>
                                            <h3>Viverra accumsan, sed vestibulum sit<br /> turpis neque, sit.</h3>
                                            <p>At accumsan condimentum donec dictumst eros,<br /> tempus in diam. Ornare gravida quis eu blandit<br /> lectus vestibulum egestas. Congue neque...</p>
                                            <div className='list-span'>
                                                <div>
                                                    <h2>Pakistan Property</h2>
                                                    <h3 style={{ marginTop: '-7px' }}>March 16,2024.6 min read</h3>
                                                </div>
                                                <div className='read-more'>
                                                    <h3 style={{ color: '#1877F2' }}>Read More<span><GoArrowUpRight /></span></h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className='col-lg-6 col-12 px-3'>
                                    <div className='Interior-card'>
                                        <div className='Interior-img'>
                                            <div className='img-heading'>
                                                <h4>Interior Design</h4>
                                            </div>
                                            <img src={img} alt='' />
                                        </div>
                                        <div className='listing-heading'>
                                            <h3>Viverra accumsan, sed vestibulum sit<br /> turpis neque, sit.</h3>
                                            <p>At accumsan condimentum donec dictumst eros,<br /> tempus in diam. Ornare gravida quis eu blandit<br /> lectus vestibulum egestas. Congue neque...</p>
                                            <div className='list-span'>
                                                <div>
                                                    <h2>Pakistan Property</h2>
                                                    <h3 style={{ marginTop: '-7px' }}>March 16,2024.6 min read</h3>
                                                </div>
                                                <div className='read-more'>
                                                    <h3 style={{ color: '#1877F2' }}>Read More<span><GoArrowUpRight /></span></h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-12 px-3 py-4'>
                                    <div className='Interior-card'>
                                        <div className='Interior-img'>
                                            <div className='img-heading'>
                                                <h4>Interior Design</h4>
                                            </div>
                                            <img src={img} alt='' />
                                        </div>
                                        <div className='listing-heading'>
                                            <h3>Viverra accumsan, sed vestibulum sit<br /> turpis neque, sit.</h3>
                                            <p>At accumsan condimentum donec dictumst eros,<br /> tempus in diam. Ornare gravida quis eu blandit<br /> lectus vestibulum egestas. Congue neque...</p>
                                            <div className='list-span'>
                                                <div>
                                                    <h2>Pakistan Property</h2>
                                                    <h3 style={{ marginTop: '-7px' }}>March 16,2024.6 min read</h3>
                                                </div>
                                                <div className='read-more'>
                                                    <h3 style={{ color: '#1877F2' }}>Read More<span><GoArrowUpRight /></span></h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className='col-lg-6 col-12 px-3 py-4'>
                                    <div className='Interior-card'>
                                        <div className='Interior-img'>
                                            <div className='img-heading'>
                                                <h4>Interior Design</h4>
                                            </div>
                                            <img src={img} alt='' />
                                        </div>
                                        <div className='listing-heading'>
                                            <h3>Viverra accumsan, sed vestibulum sit<br /> turpis neque, sit.</h3>
                                            <p>At accumsan condimentum donec dictumst eros,<br /> tempus in diam. Ornare gravida quis eu blandit<br /> lectus vestibulum egestas. Congue neque...</p>
                                            <div className='list-span'>
                                                <div>
                                                    <h2>Pakistan Property</h2>
                                                    <h3 style={{ marginTop: '-7px' }}>March 16,2024.6 min read</h3>
                                                </div>
                                                <div className='read-more'>
                                                    <h3 style={{ color: '#1877F2' }}>Read More<span><GoArrowUpRight /></span></h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-12 px-3'>
                                    <div className='Interior-card'>
                                        <div className='Interior-img'>
                                            <div className='img-heading'>
                                                <h4>Interior Design</h4>
                                            </div>
                                            <img src={img} alt='' />
                                        </div>
                                        <div className='listing-heading'>
                                            <h3>Viverra accumsan, sed vestibulum sit<br /> turpis neque, sit.</h3>
                                            <p>At accumsan condimentum donec dictumst eros,<br /> tempus in diam. Ornare gravida quis eu blandit<br /> lectus vestibulum egestas. Congue neque...</p>
                                            <div className='list-span'>
                                                <div>
                                                    <h2>Pakistan Property</h2>
                                                    <h3 style={{ marginTop: '-7px' }}>March 16,2024.6 min read</h3>
                                                </div>
                                                <div className='read-more'>
                                                    <h3 style={{ color: '#1877F2' }}>Read More<span><GoArrowUpRight /></span></h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className='col-lg-6 col-12 px-3'>
                                    <div className='Interior-card'>
                                        <div className='Interior-img'>
                                            <div className='img-heading'>
                                                <h4>Interior Design</h4>
                                            </div>
                                            <img src={img} alt='' />
                                        </div>
                                        <div className='listing-heading'>
                                            <h3>Viverra accumsan, sed vestibulum sit<br /> turpis neque, sit.</h3>
                                            <p>At accumsan condimentum donec dictumst eros,<br /> tempus in diam. Ornare gravida quis eu blandit<br /> lectus vestibulum egestas. Congue neque...</p>
                                            <div className='list-span'>
                                                <div>
                                                    <h2>Pakistan Property</h2>
                                                    <h3 style={{ marginTop: '-7px' }}>March 16,2024.6 min read</h3>
                                                </div>
                                                <div className='read-more'>
                                                    <h3 style={{ color: '#1877F2' }}>Read More<span><GoArrowUpRight /></span></h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                          
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-12 col-12 p-0'>
                        <div className='side-card'>
                            <div className='upr-section'>
                                <h2>Follow Us</h2>
                                <div className='blog-icon'>
                                    <div><FaFacebookF /></div>
                                    <div><FaTwitter /></div>
                                    <div><GrLinkedinOption /></div>
                                    <div><GrInstagram /></div>
                                </div>
                            </div>
                            <div className='lower-section'>
                                <div className='lower-img'>
                                    <img src={img1} alt='' />
                                </div>
                                <h3>Newsletter</h3>
                                <p>Subscribe For Daily Blog Alert</p>
                                <div className='pading'>
                                    <div className='lower-input'>
                                        <h5>Full Name</h5>
                                        <input type='' placeholder='Last Name' />
                                    </div>
                                    <div style={{ marginTop: '25px' }} className='lower-input'>
                                        <h5>Email</h5>
                                        <input type='' placeholder='maherarslan7022gmail.com' />
                                    </div>
                                    <div className='Blog-button'>
                                        <button>Subscribe</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BlogListing
