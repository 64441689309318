import React, { useEffect, useState } from 'react'
import { CiMoneyBill, CiSearch } from 'react-icons/ci'
import { IoKeyOutline } from 'react-icons/io5'
import { useAuth } from '../../Context/ContextProvider'

function PopularProperties({setPropertyData}) {
  const {getTypePublic,serchProperty}=useAuth()
  const [type,setType]=useState([])
  const [type_id,setTypeId]=useState()
  useEffect(()=>{
    const fetchData = async () => {
      try {
        let result = await getTypePublic();
        if (result?.success) {
          const propertyTypes = result.data.data.property_types;
          setTypeId(propertyTypes?.length?propertyTypes[0]?.id:"")
          setType(propertyTypes);
        }
      } catch (error) {
        console.error("An error occurred while fetching data:", error);
      }
    };
    fetchData()
  },[])
  return (
    <div className='popular_properties container-fluid main_banner_home'>
      <h1 className='my-4'>Popular Properties</h1>
      <div className='d-flex flex-wrap justify-content-between'>

      <div className='btn_popular'>
        {type?.map((item)=>{
          return(
            <button onClick={async()=>{
              setTypeId(item?.id)
           let result= await serchProperty({property_type_id:item?.id})
           if(result?.success){
            setPropertyData(result?.data?.data?.properties)
           }
            }} style={{background:`${type_id===item?.id?"white":"none"}`,border:`${type_id===item?.id?"1px solid lightgray":"none"}`}}><CiMoneyBill/> {item?.name==="Sell"?"Buy":item?.name}</button>

          )
        })}
        {/* <button onClick={()=>setBuy(true)} style={{background:`${buy?"white":"none"}`,border:`${buy?"1px solid lightgray":"none"}`}}><IoKeyOutline/> Rent</button> */}
      </div>
      <div className='inp_populer'>
        <CiSearch style={{fontSize:"23px",padding:"0",margin:"0 .5rem"}}/><input className='p-0' placeholder='Search' type='search'/>
      </div>
      </div>
    </div>
  )
}

export default PopularProperties
