import React from 'react'
import NavBar from '../Camponent/NavBarFooter/NavBar'
import Banner from '../Camponent/News/Banner'
import Latestnews from '../Camponent/News/Latestnews'
import Footer from '../Camponent/NavBarFooter/Footer'

function News() {
  return (
  <>
  <NavBar/>
  <Banner/>
  <Latestnews/>
  <Footer/>
  </>
  )
}

export default News
