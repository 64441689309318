import React from 'react'
import { GoArrowUpRight } from 'react-icons/go'

function Valuesection() {
  return (
    <>
    <div className='value-section px-4'>
        <h2>21 D, Block, Phase 8 (Ex Park View), DHA Lahore. </h2>
        <div className='row m-0'>
            <div className='col-lg-12'>
               <div className='mian-card'>
               <div className='value-card'>
                <h4>Property Type:<span>Building</span></h4>
                <h4>Plot Siz:<span>8 Marla</span></h4>
                <h4>Covered Area:<span>2178 Square Feet</span></h4>
               </div>
                <div className='value-card'>
                <h4>Constructed Yea:<span>2022</span></h4>
                <h4>Construction Type:<span>Finished</span></h4>
                <h4>Furnished:<span> Non Furnished</span></h4>
                </div>
                <div className='line'></div>
                <div className='value-card'>
                <div className='vc-sec'>
                <span className='estimated'>Estimated price</span>
                <div className='pkr'>
                    <h4>PKR<span>12 Crore</span></h4>
                </div>
                </div>
                </div>
                <div className='value-card p-0'>
                <h4 style={{color:'#1877F2'}}>See inside the estimate<span style={{color:'#1877F2'}}><GoArrowUpRight /></span></h4>
                <h4 style={{color:'#1877F2'}}>How we calculate our estimate<span style={{color:'#1877F2'}}><GoArrowUpRight /></span></h4>
                <h4>Next update in 13 days<span></span></h4>
                </div>
               </div>
            </div>
        </div>
    </div>
    </>
  )
}

export default Valuesection
