import React from 'react'
import NavBar from '../Camponent/NavBarFooter/NavBar'
import NeighborhoodProperties from '../Camponent/HomePage/NeighborhoodProperties'
import Banner from '../Camponent/Housingsocity/Banner'
import Category from "../Camponent/Housingsocity/Catogery"
import Footer from '../Camponent/NavBarFooter/Footer'
import TopProject from '../Camponent/Housingsocity/TopProject'

function HousingSociety() {
  return (
    <div>
      <NavBar/>
      <Banner/>
      <TopProject/>
      <NeighborhoodProperties/>
      <Category/>
      <Footer/>
    </div>
  )
}

export default HousingSociety
