import React, { useState } from 'react'
import img1 from '../../Images/HomePage/property-1.jpg'
import { CiLocationOn, CiMoneyBill, CiSearch } from 'react-icons/ci'
import { BsShop } from 'react-icons/bs'
import { PiBuilding, PiBuildingOfficeLight } from 'react-icons/pi'
import { TbSquareRotated } from 'react-icons/tb'
import { IoKeyOutline } from 'react-icons/io5'
function OurProject() {
    const [buy, setBuy] = useState(false)
    let array = [img1, img1, img1, img1, img1, img1]
    return (
        <div className='container-fluid main_banner_home'>
            <div className='popular_properties'>
                <h1 className='my-4'>Our Projects</h1>
                <div className='d-flex flex-wrap justify-content-between'>
                    <div className='btn_popular'>
                        <button onClick={() => setBuy(false)} style={{ background: `${!buy ? "white" : "none"}` ,border:`${!buy? "1px solid lightgray":"none"}`}}><CiMoneyBill /> High Rise</button>
                        <button onClick={() => setBuy(true)} style={{ background: `${buy ? "white" : "none"}` ,border:`${buy? "1px solid lightgray":"none"}` }}><IoKeyOutline /> Housing Society</button>
                    </div>
                    <div className='inp_populer'>
                        <CiSearch style={{ fontSize: "23px", padding: "0", margin: "0 .5rem" }} /><input className='p-0' placeholder='Search' type='search' />
                    </div>
                </div>
            </div>
            <div className='our_project'>
                <div className='row m-0'>
                    {array?.map((item) => {
                        return (
                            <div className='col-xl-3 col-lg-4 col-md-6 col-12 p-0 pe-2 my-2'>
                                <div className="property-item rounded">
                                    <div className="position-relative">
                                        <div style={{ width: "100%", overflow: "hidden" }}>
                                            <img src={item} alt="" />
                                        </div>
                                        {/* <a href="..." style={{border:"1px solid"}} className='overflow-hidden'></a> */}
                                        <div className="position-absolute  pt-1 px-3"><img src='./image/Frame.svg' alt='...' />Trending</div>
                                        <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path id="Vector 2" d="M8 8L0 0H8V8Z" fill="#046B6D" />
                                        </svg>

                                    </div>
                                    <div className='p-3'>

                                        <h4>Beach Resort by Icon,
                                            Lahore</h4>
                                        <h6> PKR  68.9 Lakh to 7.82 Crore</h6>
                                        <p><CiLocationOn className='me-2' style={{ fontSize: "18px",color:"#1C9093" }} />Raiwind Road, Lahore </p>
                                        <p><TbSquareRotated className='me-2' style={{ fontSize: "18px" }} />  428 sqft to 2862 sqft</p>
                                        <hr className='p-0 pb-1 m-0'/>
                                        <div className='card_footer d-flex justify-content-between'>
                                            <span><BsShop className='me-2' />  Shops</span>
                                            <span><PiBuildingOfficeLight className='me-2' />  Office</span>
                                            <span> <PiBuilding className='me-2' />Apartment</span>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>

        </div>
    )
}

export default OurProject
