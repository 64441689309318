import React from 'react'
import Checkbox from '@mui/material/Checkbox';
import { teal } from '@mui/material/colors';
import logo from '../../Images/logo.png'
const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
function PlatFormSection({data,setData}) {
    return (
        <div className='locationPurpos my-2'>
            <div className='d-flex align-items-center' style={{border:"1px solid lightgray",width:"max-content",padding:"0 0.5rem",borderRadius:"5px"}}>
                <Checkbox
                    {...label}
                    checked={data?.plateform?true:false}
                    onChange={(e)=>setData({...data,plateform:e.target.checked})} 
                    sx={{
                        color: teal[800],
                        '&.Mui-checked': {
                            color: teal[600],
                        },
                    }}
                />
                <img style={{width:"100px",height:"30px"}} src={logo} alt='...'/>
            </div>
        </div>
    )
}

export default PlatFormSection
