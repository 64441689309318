import React from 'react';
function Banner() {
    return (
        
            <div className="container-fluid about-us  p-0 m-0">
                    <div className="img-box">
                        <div className="row text-row m-0 p-0 ">
                            <div className="col-12 ">
                           <div className="img-text pt-5">
                           <div className="label mt-5 pt-1">About Us</div>
                           <div className="details mt-3">We are a team of professionals and passion</div>
                           </div>
                            </div>
                        </div>
                   
                    </div>
            
            </div>
        
    )
}

export default Banner
