import React, { useEffect, useState } from 'react'
import LocationPurpose from '../Component/LocationPurpose'
import FeatureAmenities from '../Component/FeatureAmenities'
import PriceArea from '../Component/PriceArea'
import AdInformation from '../Component/AdInformation'
import PropertyImageVideo from '../Component/PropertyImageVideo'
import ContactInformation from '../Component/ContactInformation'
import PlatFormSection from '../Component/PlatFormSection'
import { useAuth } from '../../Context/ContextProvider'
import { useParams } from 'react-router-dom'

function AddProperty() {
    const [lable, setLable] = useState("Home")

        const parms=useParams()
        const [city_value,setCityValue]=useState({
            city:"",
            location:""
        })
        const [latitude,setlatitude]=useState({
            lat:"",
            lng:""
        })
        const [load,setLoad]=useState(false)
        const [image, setImages] = useState([])
    const {handleAddProperty,getProprteyById,handleUpdateProperty,userData}=useAuth()
    const [data, setData] = useState({
        type: "",
        property_type: "",
        property_sub_type: "",
        city_id: "",
        state_id: "",
        plot_number: "",
        area: "",
        area_unit: "",
        price: "",
        currency: "",
        installment: false,
        possession: false,
        bedrooms: "",
        bathrooms: "",
        amenities: [],
        ad_title: "",
        ad_description: "",
        images: [],
        video_links: ["", ""],
        email: userData?.data?.user?.email,
        mobile: [userData?.data?.user?.phone_number?userData?.data?.user?.phone_number:""],
        land_line: userData?.data?.user?.landline,
        plateform: true
    });

    useEffect(() => {
        const fetchCart = async () => {
            try {
                let result = await getProprteyById(parms?.id);
                if (result?.success) {
                    let propertyData = result?.data?.data?.property;
                    const transformedAmenities = propertyData?.amenities?.map(category => {
                        const transformedData = category.data.map(item => {
                            return {
                                amenity_id: item.amenity_id,
                                lable: item.label,  // Fixing the typo 'lable' as per your request
                                type: item.type,
                                selected_options: [item.selected_options]  // Wrapping selected_options in an array
                            };
                        });
                    
                        return {
                            amenity_category_id: category.amenity_category_id,
                            category_name: category.category_name,
                            data: transformedData,
                            property_type_id: 3 // Assuming you're setting property_type_id to 3
                        };
                    });
                    setData(prevData => ({
                        ...prevData,
                        type: propertyData?.property_type_id,
                        property_type: propertyData?.category_id,
                        property_sub_type: propertyData?.sub_category_id,
                        city_id: propertyData?.city_code,
                        state_id: propertyData?.location_id,
                        plot_number: propertyData?.plot_number,
                        area: propertyData?.area_size,
                        area_unit: propertyData?.unit_area,
                        price: propertyData?.price,
                        currency: propertyData?.currency,
                        installment: propertyData?.installments_available,
                        possession: propertyData?.ready_for_possession,
                        monthly_installments: propertyData?.monthly_installments,
                        number_of_installments: propertyData?.number_of_installments,
                        advanced_amount: propertyData?.advanced_amount,
                        bedrooms: propertyData?.bedrooms,
                        bathrooms: propertyData?.bathrooms,
                        amenities: transformedAmenities,
                        ad_title: propertyData?.title,
                        ad_description: propertyData?.description,
                        video_links: propertyData?.video_links,
                        email: propertyData?.email?propertyData?.email:userData?.data?.user?.email,
                        mobile: propertyData?.contacts?propertyData?.contacts:[userData?.data?.user?.phone_number],
                        land_line: propertyData?.land_line?propertyData?.land_line:userData?.data?.user?.landline,
                        plateform: true,
                        id:propertyData?.id,
                    }));
                    setCityValue({
                        city:propertyData?.city?.name,
                        location:propertyData?.location?.name
                    })
                    setlatitude(propertyData?.geo_location)
                    setImages(propertyData?.images)
                    setLoad(true)
                }
            } catch (error) {
                console.error("An error occurred while fetching data:", error);
            }
        };
    
        if (parms?.id) {
            fetchCart();
        } else {
            setData({
                type: "",
                property_type: "",
                property_sub_type: "",
                city_id: "",
                state_id: "",
                plot_number: "",
                area: "",
                area_unit: "",
                price: "",
                currency: "",
                installment: false,
                possession: false,
                bedrooms: "",
                bathrooms: "",
                amenities: [],
                ad_title: "",
                ad_description: "",
                images: [],
                video_links: ["", ""],
                email: userData?.data?.user?.email,
                mobile: [userData?.data?.user?.phone_number?userData?.data?.user?.phone_number:""],
                land_line: userData?.data?.user?.landline,
                plateform: true
            });
        }
        // eslint-disable-next-line
    }, [parms?.id]);
    const [fieldErrors, setFieldErrors] = React.useState({})
    const validateStep = () => {
        let errors = {
        };
        if (!data.city_id) {
            errors.city_id = "City is required.";
        }
        if (!data.state_id) {
            errors.state_id = "State is required.";
        }
        if (!data.area) {
            errors.area = "Area is required.";
        }
        if (!data.area_unit) {
            errors.area_unit = "Area unit is required.";
        }
        if (!data.price) {
            errors.price = "Price is required.";
        }
        if (!data.ad_title) {
            errors.ad_title = "Title is required.";
        }
        if (!data.ad_description) {
            errors.ad_description = "Description is required.";
        }
        if (data.installment) {
            if (!data.monthly_installments) {
                errors.monthly_installments = "Monthly Installments is required.";
            }
            if (!data.number_of_installments) {
                errors.number_of_installments = "No of Installments is required.";
            }
            if (!data.advanced_amount) {
                errors.advanced_amount = "Advance Amount is required.";
            }
        }
        if (!data.email) {
            errors.email = "Email is required.";
        } else if (!isValidEmail(data.email)) {
            errors.email = "Please enter a valid email address.";
        }
        if (!data.land_line) {
            errors.land_line = "Contact is required.";
        }
        else if (!isValidPakistaniLandlineNumber(data.land_line)) {
            errors.land_line = "Please enter a valid Pakistan Lanline.";
        }
        setFieldErrors(errors);
        // Check if there are any errors
        return Object.keys(errors).length === 0;
    };
    const submit = async (event) => {
        event.preventDefault();
        if (validateStep()) {
            if(data?.id){
                handleUpdateProperty(data)
            }
            else{
                handleAddProperty(data)

            }
            
        }
    }
    const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };
    
    const isValidPakistaniPhoneNumber = (phoneNumber) => {
        // Remove all non-digit characters except '+'
        // eslint-disable-next-line
        let cleanedPhoneNumber = phoneNumber.replace(/[^\d\+]/g, '');

        // Log the cleaned phone number
        const pakistaniPhoneRegex = /^(?:0|\+92)?\s?(?:\d\s?){9,10}$/;
        // Regular expression for Pakistani phone number
        // const pakistaniPhoneRegex = /^\+92\d{11}$/;

        // Validate phone number format and length
        const isValid = pakistaniPhoneRegex.test(cleanedPhoneNumber);

        return isValid;
    };
    const isValidPakistaniLandlineNumber = (phoneNumber) => {
        // Remove all non-digit characters except '+'
        let cleanedPhoneNumber = phoneNumber.replace(/[^\d\+]/g, '');
    
        // Log the cleaned phone number
    
        // Regular expression for a specific Pakistani landline number format
        const pakistaniLandlineRegex = /^\+92\d{2}\d{8}$/;
    
        // Validate landline number format
        const isValid = pakistaniLandlineRegex.test(cleanedPhoneNumber);
    
        return isValid;
    };
    
    
    return (
        <div className='add_propertey_main'>
            <h3>Add Listing</h3>
            <div className='content_box'>
                <LocationPurpose load={load} setlatitude={setlatitude} latitude={latitude} fieldErrors={fieldErrors} setCityValue={setCityValue} city_value={city_value}  data={data} setData={setData} lable={lable} setLable={setLable} />
                <PriceArea fieldErrors={fieldErrors}  data={data} setData={setData} lable={lable} setLable={setLable} />
                <FeatureAmenities fieldErrors={fieldErrors}  data={data} setData={setData} lable={lable} setLable={setLable} />
                <AdInformation fieldErrors={fieldErrors}  data={data} setData={setData} lable={lable} setLable={setLable} />
                <PropertyImageVideo setImages={setImages} image={image} fieldErrors={fieldErrors}  data={data} setData={setData} lable={lable} setLable={setLable} />
                <ContactInformation fieldErrors={fieldErrors}  data={data} setData={setData} lable={lable} setLable={setLable} />
                <PlatFormSection fieldErrors={fieldErrors}  data={data} setData={setData} lable={lable} setLable={setLable} />
                <div className='d-flex justify-content-end pt-3'>
                <button className='submit_btn' onClick={submit}>{data?.id?"Update":"Submit"} </button>
                </div>
            </div>
        </div>
    )
}

export default AddProperty
