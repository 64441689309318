import React, { useState } from 'react'
import img from '../../Images/Rectangle 185.png'
import { HiOutlineHome } from 'react-icons/hi'
import { CiSearch } from 'react-icons/ci'
import { FaChevronDown } from 'react-icons/fa'
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { teal } from '@mui/material/colors';
import Radio from '@mui/material/Radio';
import { Divider } from '@mui/material'
function Banner() {
  const [selectedValue, setSelectedValue] = React.useState('f');

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const controlProps = (item) => ({
    checked: selectedValue === item,
    onChange: handleChange,
    value: item,
    name: 'color-radio-button-demo',
    inputProps: { 'aria-label': item },
  });
  const [buy, setBuy] = useState(true)
  const [type_pan, setTypePan] = useState(0)
  const [moreOption, setMoreOption] = useState(false)
  const [propertyType, setProperty] = useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [anchorEl1, setAnchorEl1] = React.useState(null);
  const open1 = Boolean(anchorEl1);
  const handleClick1 = (event) => {
    setAnchorEl1(event.currentTarget);
  };
  const handleClose1 = () => {
    setAnchorEl1(null);
  };
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const open2 = Boolean(anchorEl2);
  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };
  const [anchorEl3, setAnchorEl3] = React.useState(null);
  const open3 = Boolean(anchorEl3);
  const handleClick3 = (event) => {
    setAnchorEl3(event.currentTarget);
  };
  const handleClose3 = () => {
    setAnchorEl3(null);
  };
  const [anchorEl4, setAnchorEl4] = React.useState(null);
  const open4 = Boolean(anchorEl4);
  const handleClick4 = (event) => {
    setAnchorEl4(event.currentTarget);
  };
  const handleClose4 = () => {
    setAnchorEl4(null);
  };
  return (
    <div className='container-fluid main_banner_home'>

      <div className='banner_section'>

        <img src={img} id="bgImage" alt='banner-image'/>
        {/* <img src={img} id="bgVideo" alt='banner-image'/> */}
        <div class="content">
          <div className='p-0 m-0 w-100'>

            <h1>EXPLORE, DISCOVER, LIVE</h1>
            <p>We are a real estate agency that will help you find the best residence you dream of,<br /> let’s discuss for your dream house?</p>
          </div>
          <div className='tab_banner p-0 m-0'>
            <div>

              <button className='tb_btn' style={{ background: `${buy ? "white" : ""}` }} onClick={() => setBuy(true)}>Buy</button>
              <button className='tb_btn' style={{ background: `${!buy ? "white" : ""}` }} onClick={() => setBuy(false)}>Rent</button>
            </div>
            <div className='d-flex flex-wrap justify-content-between tab_section'>
              <div className='d-flex'>
                <div className='select_banner'>
                  <HiOutlineHome className='text-dark' />
                  <select>
                    <option>City</option>
                  </select>

                </div>
                <div className='select_banner'>
                  <CiSearch className='text-dark' />
                  <input type='search' placeholder='Search by location' />
                </div>

              </div>
              <div className='select_banner_btn'>
                <button onClick={() => setMoreOption(!moreOption)}>More Option</button>
                <Divider className='mt-1' style={{borderColor:"lightgray",height:"15px",border:"1px solid"}} orientation="vertical" flexItem />
                <button>Search</button>
              </div>
            </div>
          </div>
          {moreOption && <>
            <div className='mor_option d-flex flex-wrap justify-content-between my-2'>
              <button onClick={() => setProperty(!propertyType)} className='more_btn mt-2'>Property Type<FaChevronDown /> </button>
              <button id="basic-button"
                aria-controls={open2 ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open2 ? 'true' : undefined}
                onClick={handleClick2} className='more_btn mt-2'>Price (PKR)<FaChevronDown /></button>
              <button
                id="basic-button"
                aria-controls={open3 ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open3 ? 'true' : undefined}
                onClick={handleClick3} className='more_btn mt-2'>Area (Marla)<FaChevronDown /></button>
              <button
                id="basic-button"
                aria-controls={open4 ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open4 ? 'true' : undefined}
                onClick={handleClick4} className='more_btn mt-2'>Room<FaChevronDown /></button>
            </div>
            {propertyType && <div className='tab_pan_main' style={{ background: "white", borderRadius: "5px", padding: "1rem 0" }}>
              <div className='tab_pan d-flex justify-content-center'>
                <h6 className={`${type_pan === 0 && "active_tab"}`} onClick={() => setTypePan(0)}>Home</h6>
                <h6 className={`${type_pan === 1 && "active_tab"}`} onClick={() => setTypePan(1)}>Plots</h6>
                <h6 className={`${type_pan === 2 && "active_tab"}`} onClick={() => setTypePan(2)}>Commercial</h6>
              </div>
              {type_pan === 0 && <div className='option_tabs'>
                <p>House</p>
                <p>apartment</p>
                <p>Upper Portion</p>
                <p>Lower Portion</p>
                <p>Farm House</p>
                <p>Room</p>
                <p>Penthouse</p>
              </div>}
              {type_pan === 1 && <div className='option_tabs'>
                <p>Residential Plot</p>
                <p>Commercial Plot</p>
                <p>Agricultural Land</p>
                <p>Industrial Land</p>
                <p>Plot File</p>
                <p>Plot Form</p>

              </div>}
              {type_pan === 2 && <div className='option_tabs'>
                <p>Office</p>
                <p>Shop</p>
                <p>Warehouse</p>
                <p>Factory</p>
                <p>Farm House</p>
                <p>Building</p>
                <p>Other</p>
              </div>}
            </div>}
          </>}
          <div className='unit_ul my-2'>
            <ul>
              <li
                ><button id="basic-button"
         
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick} style={{padding:"0",margin:"0",background:"none",border:"none",color:"white"}}>Change Area Unit</button></li>
              <li  ><button id="basic-button"
                aria-controls={open1 ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open1 ? 'true' : undefined}
                onClick={handleClick1} style={{padding:"0",margin:"0",background:"none",border:"none",color:"white"}}>Change Currency</button> 
              </li>
              <li style={{ border: "none" }}>Reset Search</li>
            </ul>
            <div className='menu_item'>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl1}
                open={open1}
                style={{ paddingTop: "1rem" }}
                onClose={handleClose1}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                <MenuItem onClick={handleClose1}><p className='px-2'>         <Radio
                  {...controlProps('f')}
                  sx={{
                    color: teal[800],
                    margin: "0 1rem",
                    padding: "0",
                    '&.Mui-checked': {
                      color: teal[600],
                    },
                  }}
                />Pakistan (PKR)</p></MenuItem>
                <MenuItem onClick={handleClose1}><p className='px-2'>         <Radio
                  {...controlProps('e')}
                  sx={{
                    color: teal[800],
                    margin: "0 1rem",
                    padding: "0",
                    '&.Mui-checked': {
                      color: teal[600],
                    },
                  }}
                />Canadian Dollar (CAD)</p></MenuItem>
                <MenuItem onClick={handleClose1}><p className='px-2'>         <Radio
                  {...controlProps('e')}
                  sx={{
                    color: teal[800],
                    margin: "0 1rem",
                    padding: "0",
                    '&.Mui-checked': {
                      color: teal[600],
                    },
                  }}
                />Saudi Arabia (SAR)</p></MenuItem>
                <MenuItem onClick={handleClose1}><p className='px-2'>         <Radio
                  {...controlProps('e')}
                  sx={{
                    color: teal[800],
                    margin: "0 1rem",
                    padding: "0",
                    '&.Mui-checked': {
                      color: teal[600],
                    },
                  }}
                />United Arab Emirates (AED)</p></MenuItem>
                <MenuItem onClick={handleClose1}><p className='px-2'>         <Radio
                  {...controlProps('e')}
                  sx={{
                    color: teal[800],
                    margin: "0 1rem",
                    padding: "0",
                    '&.Mui-checked': {
                      color: teal[600],
                    },
                  }}
                />United Kingdom (GBP)</p></MenuItem>
                <MenuItem onClick={handleClose1}><p className='px-2'>         <Radio
                  {...controlProps('e')}
                  sx={{
                    color: teal[800],
                    margin: "0 1rem",
                    padding: "0",
                    '&.Mui-checked': {
                      color: teal[600],
                    },
                  }}
                />United States of America (USD)</p></MenuItem>
                <MenuItem onClick={handleClose1}><p className='px-2'>         <Radio
                  {...controlProps('e')}
                  sx={{
                    color: teal[800],
                    margin: "0 1rem",
                    padding: "0",
                    '&.Mui-checked': {
                      color: teal[600],
                    },
                  }}
                />Euro (EUR)</p></MenuItem>
                <MenuItem onClick={handleClose1}><p className='px-2'>         <Radio
                  {...controlProps('e')}
                  sx={{
                    color: teal[800],
                    margin: "0 1rem",
                    padding: "0",
                    '&.Mui-checked': {
                      color: teal[600],
                    },
                  }}
                />Australian Dollar (AUD)</p></MenuItem>
              </Menu>

              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                style={{ paddingTop: "1rem" }}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                <MenuItem onClick={handleClose}><p className='px-2'>         <Radio
                  {...controlProps('f')}
                  sx={{
                    color: teal[800],
                    margin: "0 1rem",
                    padding: "0",
                    '&.Mui-checked': {
                      color: teal[600],
                    },
                  }}
                />Marla</p></MenuItem>
                <MenuItem onClick={handleClose}><p className='px-2'>         <Radio
                  {...controlProps('f')}
                  sx={{
                    color: teal[800],
                    margin: "0 1rem",
                    padding: "0",
                    '&.Mui-checked': {
                      color: teal[600],
                    },
                  }}
                />Kanal</p></MenuItem>
                <MenuItem onClick={handleClose}><p className='px-2'>         <Radio
                  {...controlProps('f')}
                  sx={{
                    color: teal[800],
                    margin: "0 1rem",
                    padding: "0",
                    '&.Mui-checked': {
                      color: teal[600],
                    },
                  }}
                />Square Yards</p></MenuItem>
                <MenuItem onClick={handleClose}><p className='px-2'>         <Radio
                  {...controlProps('f')}
                  sx={{
                    color: teal[800],
                    margin: "0 1rem",
                    padding: "0",
                    '&.Mui-checked': {
                      color: teal[600],
                    },
                  }}
                />Square Meter</p></MenuItem>
                <MenuItem onClick={handleClose}><p className='px-2'>         <Radio
                  {...controlProps('f')}
                  sx={{
                    color: teal[800],
                    margin: "0 1rem",
                    padding: "0",
                    '&.Mui-checked': {
                      color: teal[600],
                    },
                  }}
                />Square Feet</p></MenuItem>
                <MenuItem onClick={handleClose}><p className='px-2'>         <Radio
                  {...controlProps('f')}
                  sx={{
                    color: teal[800],
                    margin: "0 1rem",
                    padding: "0",
                    '&.Mui-checked': {
                      color: teal[600],
                    },
                  }}
                />Acre</p></MenuItem>
               
              </Menu>

              <Menu
                id="basic-menu"
                anchorEl={anchorEl2}
                open={open2}
                onClose={handleClose2}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
              
                  <div className='price_filter'>
                    <div className='d-flex filter_title justify-content-between align-items-center'>
                      <p className='p-0 m-0'>Budget Range</p>
                      <button id="basic-button"
                style={{ position: "relative" }}
                aria-controls={open1 ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open1 ? 'true' : undefined}
                onClick={handleClick1}>PKR <FaChevronDown /></button>
                    </div>
                    <div className='row m-0 my-2'>
                      <div className='col-6'>
                        <h6>Min</h6>
                      </div>
                      <div className='col-6'>
                        <h6>Max</h6>

                      </div>
                    </div>
                    <div className='row m-0 mb-2'>
                      <div className='col-6 p-0 px-2'>
                        <h5>0</h5>
                      </div>
                      <div className='col-6 p-0 px-2'>
                        <h5>Any</h5>

                      </div>
                    </div>
                    <div className='row m-0'>
                      <div className='col-6 m-0 p-0 px-2'>
                        <h5>0</h5>
                        <h5>500,000</h5>
                        <h5>1000,00</h5>
                        <h5>3000,00</h5>
                        <h5>3000,00</h5>

                      </div>
                      <div className='col-6 m-0 p-0 px-2'>
                        <h5>0</h5>
                        <h5>500,000</h5>
                        <h5>1000,00</h5>
                        <h5>3000,00</h5>
                        <h5>3000,00</h5>


                      </div>

                    </div>
                  </div>


              </Menu>

              <Menu
                id="basic-menu"
                anchorEl={anchorEl3}
                open={open3}
                onClose={handleClose3}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                
                  <div className='price_filter'>
                    <div className='d-flex filter_title justify-content-between align-items-center'>
                      <p className='p-0 m-0'>Area Range</p>
                      <button id="basic-button"
                style={{ position: "relative" }}
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}>Marla <FaChevronDown /></button>
                    </div>
                    <div className='row m-0 my-2'>
                      <div className='col-6'>
                        <h6>Min</h6>
                      </div>
                      <div className='col-6'>
                        <h6>Max</h6>

                      </div>
                    </div>
                    <div className='row m-0 mb-2'>
                      <div className='col-6 p-0 px-2'>
                        <h5>0</h5>
                      </div>
                      <div className='col-6 p-0 px-2'>
                        <h5>Any</h5>

                      </div>
                    </div>
                    <div className='row m-0'>
                      <div className='col-6 m-0 p-0 px-2'>
                        <h5>0</h5>
                        <h5>5</h5>
                        <h5>1</h5>
                        <h5>3</h5>
                        <h5>3</h5>

                      </div>
                      <div className='col-6 m-0 p-0 px-2'>
                        <h5>0</h5>
                        <h5>5</h5>
                        <h5>1</h5>
                        <h5>3</h5>
                        <h5>3</h5>


                      </div>

                    </div>
                  </div>


              </Menu>

              <Menu
                id="basic-menu"
                anchorEl={anchorEl4}
                open={open4}
                onClose={handleClose4}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                <MenuItem style={{ padding: "0" }} onClick={handleClose4}>
                  <div className='price_filter'>
                    
      
 
                    <div className='row m-0'>
                      <div className='col-12 m-0 p-0 px-2'>
                        <h5>All</h5>
                        <h5>Studio</h5>
                        <h5>0</h5>
                        <h5>1</h5>
                        <h5>2</h5>
                        <h5>3</h5>
                        <h5>4</h5>
                        <h5>5</h5>

                      </div>
        

                    </div>
                  </div></MenuItem>


              </Menu>
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}

export default Banner
