import React, { useEffect, useState } from 'react'
import img from '../../../Images/view-icon-big.png'
import img1 from '../../../Images/clicks-icon-big.png'
import img2 from '../../../Images/leads-icon-gig.png'
import img3 from '../../../Images/call-icon-big.png'
import img5 from '../../../Images/sms-icon-big.png'
import img6 from '../../../Images/email-icon-big.png'
import img7 from '../../../Images/whatsapp-icon-big.png'
import { CiMoneyBill, CiSearch } from 'react-icons/ci'
import { IoHomeOutline, IoKeyOutline } from 'react-icons/io5'
function DashBoardState({analytics}) {
    const [buy, setBuy] = useState("buy")
    const [type, setType] = useState([])
    useEffect(()=>{
        
    },[])
    return (
        <>
            <div className='popular_properties'>
                <h5 className='heading'>Analytics</h5>
                <div className='d-flex flex-wrap justify-content-between'>
                    <div className='btn_popular'>
                        <button onClick={() => setBuy("all")} style={{ background: `${buy==="all" ? "white" : "none"}`, border: `${buy==="all" ? "1px solid lightgray" : "none"}` }}><IoHomeOutline /> All</button>
                        <button onClick={() => setBuy("rent")} style={{ background: `${buy==="rent" ? "white" : "none"}`, border: `${buy==="rent" ? "1px solid lightgray" : "none"}` }}><IoKeyOutline /> Rent</button>
                        <button onClick={() => setBuy("buy")} style={{ background: `${buy==="buy"? "white" : "none"}`, border: `${buy==="buy" ? "1px solid lightgray" : "none"}` }}><CiMoneyBill /> Buy</button>
                    </div>
                    <div className='inp_populer'>
                        <CiSearch style={{ fontSize: "23px", padding: "0", margin: "0 .5rem" }} /><input className='p-0' placeholder='Search' type='search' />
                    </div>
                </div>
            </div>
            <div className='card_main_listing satate_dashboard mt-3'>
                <div className='dashboard_listing_page'>
                    <div className='box'>
                        <div className='icons'>
                            <img src={img} alt='...' />
                        </div>
                        <div className="ms-2">
                            <p className='m-0 title'>Active</p>
                            <h6>{analytics?.active}</h6>
                            <p className='m-0 count'>No Data</p>
                        </div>
                    </div>
                    <div className='box'>
                        <div className='icons'>
                            <img src={img1} alt='...' />
                        </div>
                        <div className="ms-2">
                            <p className='m-0 title'>Clicks</p>
                            <h6>{analytics?.clicks}</h6>

                            <p className='m-0 count'>No Data</p>
                        </div>
                    </div>
                    <div className='box'>
                        <div className='icons'>
                            <img src={img2} alt='...' />
                        </div>
                        <div className="ms-2">
                            <p className='m-0 title'>Leads</p>
                            <h6>{analytics?.leads}</h6>

                            <p className='m-0 count'>No Data</p>
                        </div>
                    </div>
                    <div className='box'>
                        <div className='icons'>
                            <img src={img3} alt='...' />
                        </div>
                        <div className="ms-2">
                            <p className='m-0 title'>Call</p>
                            <h6>{analytics?.call}</h6>

                            <p className='m-0 count'>No Data</p>
                        </div>
                    </div>
                    <div className='box'>
                        <div className='icons'>
                            <img src={img5} alt='...' />
                        </div>
                        <div className="ms-2">
                            <p className='m-0 title'>SMS</p>
                            <h6>{analytics?.sms}</h6>

                            <p className='m-0 count'>No Data</p>
                        </div>
                    </div>
                    <div className='box'>
                        <div className='icons'>
                            <img src={img6} alt='...' />
                        </div>
                        <div className="ms-2">
                            <p className='m-0 title'>Email</p>
                            <h6>{analytics?.email}</h6>

                            <p className='m-0 count'>No Data</p>
                        </div>
                    </div>
                    <div className='box'>
                        <div className='icons'>
                            <img src={img7} alt='...' />
                        </div>
                        <div className="ms-2">
                            <p className='m-0 title'>WhatsApp</p>
                            <h6>{analytics?.whatsapp}</h6>
                            <p className='m-0 count'>No Data</p>
                        </div>
                    </div>
                </div>
                <hr />
                <div className='chart'>
                    <div className='text-center'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="42" height="39" viewBox="0 0 42 39" fill="none">
                            <path d="M9.84172 21.0821L16.3365 15.3166L20.8995 19.8809L29.3027 11.4777L31.0824 13.2587L32.6229 7.5127L26.8769 9.0519L28.3836 10.5586L20.8995 18.0427L16.3898 13.5317L8.97852 20.1097L9.84172 21.0821Z" fill="#1C9093" />
                            <path d="M0 1.3H3.8974V27.3039H0V28.6039H16.7297L12.1147 38.4488L13.2925 39L16.1434 32.916H25.454L28.3049 39L29.4827 38.4488L24.8677 28.6039H41.6V27.3039H37.7026V1.3H41.6V0H0V1.3ZM24.8456 31.616H16.7531L18.1649 28.6039H23.4325L24.8456 31.616ZM36.4026 27.3039H5.1974V1.3H36.4026V27.3039Z" fill="#1C9093" />
                        </svg>
                        <h6>View In-Depth Insights</h6>
                        <p>See the number of views, clicks and leads that your listing has received.</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DashBoardState