import React from 'react'
import NavBar from '../Camponent/NavBarFooter/NavBar'
import Banner from '../Camponent/Blogs/Banner'
import BlogListing from '../Camponent/Blogs/BlogListing'
import Footer from '../Camponent/NavBarFooter/Footer'

function Blogs() {
  return (
    <>
    <NavBar/>
    <Banner/>
    <BlogListing/>
    <Footer/>
    </>
  )
}

export default Blogs
