import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import HomePage from './Pages/HomePage';
import Profile from './Pages/Profile';
import MiniDrawer from './AgentDashBorad/SideBar/MiniDrawer';
import Contactus from './Pages/Contactus';
import Lease from './Pages/Lease';
import LeasDetail from './Pages/LeasDetail';
import HousingSociety from './Pages/HousingSociety';
import InstantValue from './Pages/InstantValue';
import AboutUs from './Pages/AboutUs';
import Agents from './Pages/Agents';
import AgentsProfile from './Pages/AgentsProfile';
import InstantProperty from './Pages/InstantProperty';
import Blogs from './Pages/Blogs';
import Blogsingle from './Pages/Blogsingle';
import ListingProperty from './Pages/Listingproperty';
import News from './Pages/News';
import Newssingle from './Pages/Newssingle';
import HousingSocietyDetails from './Pages/HousingSocietyDetailsExp';
import { useAuth } from './Context/ContextProvider';
import { useEffect } from 'react';
import ScrollToTop from './Camponent/NavBarFooter/ScrollToTop';
import TermsCondition from './Pages/TermsCondition';
import PrivacyPolicy from './Pages/PrivacyPolicy';

function App() {
  const {currencyData,areaUnitData}=useAuth()
  useEffect(()=>{
    currencyData()
    areaUnitData()
  },[])
  return (
<BrowserRouter>
<ScrollToTop />
<Routes>
  <Route path='/' element={<HomePage/>}/>
  <Route path='/profile/:id' element={<Profile/>}/>
  <Route path='/contact' element={<Contactus/>}/>
  <Route path='/Lease' element={<Lease/>}/>
  <Route path='/Lease-detail' element={<LeasDetail/>}/>
  <Route path='/HousingSocity' element={<HousingSociety/>}></Route>
  <Route path='/InstantValue'element={<InstantValue/>}/>
  <Route path='/InstantProperty'element={<InstantProperty/>}/>
  <Route path='/Blogs'element={<Blogs/>}/>
  <Route path='/Blogsingle'element={<Blogsingle/>}/>
  <Route path='/Listingproperty'element={<ListingProperty/>}/>
  <Route path='/Listingproperty/:id'element={<ListingProperty/>}/>
  <Route path='/News'element={<News/>}/>
  <Route path='/Newssingle'element={<Newssingle/>}/>
  <Route path='/about-us'element={<AboutUs/>}/>
  <Route path='/agents'element={<Agents/>}/>
  <Route path='/AgentProfile'element={<AgentsProfile/>}/>
  <Route path='/HousingSocietyDetailsExpand'element={<HousingSocietyDetails/>}/>
  <Route path='/agent/*' element={<MiniDrawer/>}></Route>
  <Route path='/term-and-condition' element={<TermsCondition/>}/>
  <Route path='/privacy-policy' element={<PrivacyPolicy/>}/>
</Routes>
</BrowserRouter>
  );
}

export default App;
