import React from 'react'
import NavBar from '../Camponent/NavBarFooter/NavBar'
import Properties from '../Camponent/Agent Profile/Properties'
import Footer from '../Camponent/NavBarFooter/Footer'
import Profile from '../Camponent/Agent Profile/Profile'

function AgentsProfile() {
  return (
   <>
   <NavBar/>
   <Profile/>
   <Properties/>
   <Footer/>
   </>
  )
}

export default AgentsProfile
