import React from 'react'
import NavBar from '../Camponent/NavBarFooter/NavBar'
import Banner from '../Camponent/HomePage/Banner'
import Instant from '../Camponent/InstantValue/Instant'
import PropertyVlaue from '../Camponent/InstantValue/PropertyVlaue'
import Footer from '../Camponent/NavBarFooter/Footer'


function InstantValue() {
  return (
    <div>
      <NavBar/>
      <Banner/>
      <Instant/>
      <PropertyVlaue/>
      <Footer/>
    </div>
  )
}

export default InstantValue
