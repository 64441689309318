import React from 'react'
import NavBar from '../Camponent/NavBarFooter/NavBar'
import Banner from '../Camponent/HomePage/Banner'
import PropertyVlaue from '../Camponent/InstantValue/PropertyVlaue'
import Footer from '../Camponent/NavBarFooter/Footer'
import CurrentlyMarkit from '../Camponent/InstantProperty/CurrentlyMarkit'
import Valuesection from '../Camponent/InstantProperty/Valuesection'

function InstantProperty() {
  return (
    <>
    <NavBar/>
    <Banner/>
    <CurrentlyMarkit/>
    <Valuesection/>
    <PropertyVlaue/>
    <Footer/>
    </>
  )
}

export default InstantProperty
