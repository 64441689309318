import React from 'react'
import img2 from '../../Images/5b1a07e4a81b1-300x170.jpg'
import img3 from '../../Images/News-Cover-1170x400-1-300x170.jpg'
import img4 from '../../Images/Syed-Murad-Ali-Shah-300x170.jpg'
import img5 from '../../Images/news-1170-x-400-9-300x170.jpg'
import img6 from '../../Images/MOU_with_PHEC_RUDA-4-1536x1290-2-300x170.jpg'
import img7 from '../../Images/Rectangle 24 (1).png'
import img8 from '../../Images/Rectangle 24 (2).png'
import img9 from '../../Images/Rectangle 24 (3).png'
import img10 from '../../Images/Rectangle 24 (4).png'
import img11 from '../../Images/Rectangle 24 (5).png'
import img12 from '../../Images/Rectangle 24 (8).png'
import img13 from '../../Images/Rectangle 24 (5).png'
import img14 from '../../Images/Rectangle 24 (2).png'
function Latestnews() {
  return (
    <>
    <div className='latest-news px-4'>
        <div className='row m-0'>
        <div className='col-lg-8'>
            <div className='latest-card'>
               <div style={{marginTop:'30px'}} className='latest-heading'>
                <h3>Latest News</h3>
                <div></div>
                </div> 
                <div className='latest-card1'>
                <div className='row'>
                    <div className='col-lg-6 p-0'>
                        <div className='box'>
                        <div style={{ position: 'relative' }} className='nsh'>
                                        <img style={{ width: "100%",borderRadius:'8px' }} src={img2}  alt='...'/>
                                        <div style={{ position: 'absolute', top: "110px" }} className='ns-img'>
                                            <div className='ns-h'>
                                                <h3 style={{fontSize:'20px',fontWeight:'500',fontFamily:'var(--font-family)'}}>2-day Mango Festival starts in Multan<br/>
                                                today</h3>
                                            </div>
                                            <div style={{marginLeft:'10px'}} className='d-flex'>
                                                <h4 style={{marginRight:'12px'}}>By Ginny Dennis</h4>
                                                <h4>Feb. 4, 2023</h4>
                                            </div>
                                        </div>

                                    </div>
                        </div>
                    </div>
                    <div className='col-lg-6 p-0'>
                    <div className='box'>
                        <div style={{ position: 'relative' }} className='nsh'>
                                        <img style={{ width: "100%",borderRadius:'8px' }} src={img3}  alt='...'/>
                                        <div style={{ position: 'absolute', top: "110px" }} className='ns-img'>
                                            <div className='ns-h'>
                                                <h3 style={{fontSize:'20px',fontWeight:'500',fontFamily:'var(--font-family)'}}>2-day Mango Festival starts in Multan<br/>
                                                today</h3>
                                            </div>
                                            <div style={{marginLeft:'10px'}} className='d-flex'>
                                                <h4 style={{marginRight:'12px'}}>By Ginny Dennis</h4>
                                                <h4>Feb. 4, 2023</h4>
                                            </div>
                                        </div>

                                    </div>
                        </div>
                    </div>
                   <div className='my-news d-flex'>
                   <div style={{paddingRight:'13px'}} className='late-new'>
                   <div className='col-lg-12 p-0'>
                        <div className='late-heading'>
                            <h3>Sindh to rehabilitate, revive natural route of Dhoro Puran</h3>
                            <div  className='d-flex'>
                                                <h4 style={{marginRight:'12px'}}>By Ginny Dennis</h4>
                                                <h4>Feb. 4, 2023</h4>
                                            </div>
                                            <hr/>
                                            <p>The announcement addresses significant issues caused by the Left Bank Outfall Drain (LBOD) project from the 1980s. The LBOD, constructed by the federal government, has led to severe environmental and agricultural problems. </p>
                                            <p>The excavation of its spinals blocked natural waterways, resulting in floods that submerged lands in Badin, Mirpurkhas, Sanghar, and Nawabshah, causing  ...</p>
                        </div>
                    </div>
                   </div>
                   <div style={{paddingLeft:'13px'}} className='late-new'>
                   <div className='col-lg-12 p-0'>
                    <div  className='late-heading'>
                            <h3>200,000 households in Sindh to get solar panels</h3>
                            <div  className='d-flex'>
                                                <h4 style={{marginRight:'12px'}}>By Ginny Dennis</h4>
                                                <h4>Feb. 4, 2023</h4>
                                            </div>
                                            <hr/>
                                            <p>The announcement addresses significant issues caused by the Left Bank Outfall Drain (LBOD) project from the 1980s. The LBOD, constructed by the federal government, has led to severe environmental and agricultural problems. </p>
                                            <p>The excavation of its spinals blocked natural waterways, resulting in floods that submerged lands in Badin, Mirpurkhas, Sanghar, and Nawabshah, causing  ...</p>
                        </div>
                    </div>
                   </div>
                   </div>
                   <div className='col-lg-6 p-0'>
                        <div className='box'>
                        <div style={{ position: 'relative' }} className='nsh'>
                                        <img style={{ width: "100%",borderRadius:'8px' }} src={img4}  alt='...'/>
                                        <div style={{ position: 'absolute', top: "110px" }} className='ns-img'>
                                            <div className='ns-h'>
                                                <h3 style={{fontSize:'20px',fontWeight:'500',fontFamily:'var(--font-family)'}}>2-day Mango Festival starts in Multan<br/>
                                                today</h3>
                                            </div>
                                            <div style={{marginLeft:'10px'}} className='d-flex'>
                                                <h4 style={{marginRight:'12px'}}>By Ginny Dennis</h4>
                                                <h4>Feb. 4, 2023</h4>
                                            </div>
                                        </div>

                                    </div>
                        </div>
                    </div>
                    <div className='col-lg-6 p-0'>
                    <div className='box'>
                        <div style={{ position: 'relative' }} className='nsh'>
                                        <img style={{ width: "100%",borderRadius:'8px' }} src={img5}  alt='...'/>
                                        <div style={{ position: 'absolute', top: "110px" }} className='ns-img'>
                                            <div className='ns-h'>
                                                <h3 style={{fontSize:'20px',fontWeight:'500',fontFamily:'var(--font-family)'}}>2-day Mango Festival starts in Multan<br/>
                                                today</h3>
                                            </div>
                                            <div style={{marginLeft:'10px'}} className='d-flex'>
                                                <h4 style={{marginRight:'12px'}}>By Ginny Dennis</h4>
                                                <h4>Feb. 4, 2023</h4>
                                            </div>
                                        </div>

                                    </div>
                        </div>
                    </div>
                    <div className='col-lg-6 py-0 px-2'>
                        <div className='late-heading  '>
                            <h3>Sindh to rehabilitate, revive natural route of Dhoro Puran</h3>
                            <div  className='d-flex'>
                                                <h4 style={{marginRight:'12px'}}>By Ginny Dennis</h4>
                                                <h4>Feb. 4, 2023</h4>
                                            </div>
                                            <hr/>
                                            <p>The announcement addresses significant issues caused by the Left Bank Outfall Drain (LBOD) project from the 1980s. The LBOD, constructed by the federal  problems. </p>
                                            <p>The excavation of its spinals blocked natural waterways, resulting in floods that submerged lands in Badin, Mirpurkhas,..</p>
                        </div>
                    </div>
                    <div className='col-lg-6 p-0'>
                    <div className='box'>
                        <div style={{ position: 'relative' }} className='nsh'>
                                        <img style={{ width: "100%",borderRadius:'8px' }} src={img6}  alt='...'/>
                                        <div style={{ position: 'absolute', top: "110px" }} className='ns-img'>
                                            <div className='ns-h'>
                                                <h3 style={{fontSize:'20px',fontWeight:'500',fontFamily:'var(--font-family)'}}>2-day Mango Festival starts in Multan<br/>
                                                today</h3>
                                            </div>
                                            <div style={{marginLeft:'10px'}} className='d-flex'>
                                                <h4 style={{marginRight:'12px'}}>By Ginny Dennis</h4>
                                                <h4>Feb. 4, 2023</h4>
                                            </div>
                                        </div>

                                    </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
        <div className='col-lg-4'>
            <div className='side-latest-card'>
                <h3 style={{marginBottom:'35px'}} className='px-3'>Trending Headlines</h3>
                <div className='mandorty d-flex'>
                <div className='side-latest'>
                    <img src={img7} alt='...'/>
                </div>
                <div className='m-tags'>
                <div className='side-late-heading'>
                    <h3>M-Tags made mandatory for travelling on Lahore-Sialkot Motorway M-11</h3>
                    <h4>Lahore: All vehicles on the Lahore-Sialkot Motorway (M-11) must have an M-Tag for access starting August 15th, 2024, as reported on July 26.L</h4>
                    <div className='d-flex'>
                                                <h4 style={{marginRight:'35px'}}>By Ginny Dennis</h4>
                                                <h4>Feb. 4, 2023</h4>
                                            </div>
                </div> 
                </div>
                </div>
                <hr className='mx-3'/>

                <div className='mandorty d-flex'>
                <div className='side-latest'>
                    <img src={img8} alt='...'/>
                </div>
                <div className='m-tags'>
                <div className='side-late-heading'>
                    <h3>M-Tags made mandatory for travelling on Lahore-Sialkot Motorway M-11</h3>
                    <h4>Lahore: All vehicles on the Lahore-Sialkot Motorway (M-11) must have an M-Tag for access starting August 15th, 2024, as reported on July 26.L</h4>
                    <div className='d-flex'>
                                                <h4 style={{marginRight:'35px'}}>By Ginny Dennis</h4>
                                                <h4>Feb. 4, 2023</h4>
                                            </div>
                </div> 
                </div>
                </div>
                <hr className='mx-3'/>

                <div className='mandorty d-flex'>
                <div className='side-latest'>
                    <img style={{borderRadius:'5px'}} src={img9} alt='...'/>
                </div>
                <div className='m-tags'>
                <div className='side-late-heading'>
                    <h3>M-Tags made mandatory for travelling on Lahore-Sialkot Motorway M-11</h3>
                    <h4>Lahore: All vehicles on the Lahore-Sialkot Motorway (M-11) must have an M-Tag for access starting August 15th, 2024, as reported on July 26.L</h4>
                    <div className='d-flex'>
                                                <h4 style={{marginRight:'35px'}}>By Ginny Dennis</h4>
                                                <h4>Feb. 4, 2023</h4>
                                            </div>
                </div> 
                </div>
                </div>
                <hr className='mx-3'/>

                <div className='mandorty d-flex'>
                <div className='side-latest'>
                    <img style={{borderRadius:'5px'}} src={img10} alt='...'/>
                </div>
                <div className='m-tags'>
                <div className='side-late-heading'>
                    <h3>M-Tags made mandatory for travelling on Lahore-Sialkot Motorway M-11</h3>
                    <h4>Lahore: All vehicles on the Lahore-Sialkot Motorway (M-11) must have an M-Tag for access starting August 15th, 2024, as reported on July 26.L</h4>
                    <div className='d-flex'>
                                                <h4 style={{marginRight:'35px'}}>By Ginny Dennis</h4>
                                                <h4>Feb. 4, 2023</h4>
                                            </div>
                </div> 
                </div>
                </div>
                <hr className='mx-3'/>

                <div className='mandorty d-flex'>
                <div className='side-latest'>
                    <img style={{borderRadius:'5px'}} src={img11} alt='...'/>
                </div>
                <div className='m-tags'>
                <div className='side-late-heading'>
                    <h3>M-Tags made mandatory for travelling on Lahore-Sialkot Motorway M-11</h3>
                    <h4>Lahore: All vehicles on the Lahore-Sialkot Motorway (M-11) must have an M-Tag for access starting August 15th, 2024, as reported on July 26.L</h4>
                    <div className='d-flex'>
                                                <h4 style={{marginRight:'35px'}}>By Ginny Dennis</h4>
                                                <h4>Feb. 4, 2023</h4>
                                            </div>
                </div> 
                </div>
                </div>
                <hr className='mx-3'/>


                <div className='mandorty d-flex'>
                <div className='side-latest'>
                    <img style={{borderRadius:'5px'}} src={img12} alt='...'/>
                </div>
                <div className='m-tags'>
                <div className='side-late-heading'>
                    <h3>M-Tags made mandatory for travelling on Lahore-Sialkot Motorway M-11</h3>
                    <h4>Lahore: All vehicles on the Lahore-Sialkot Motorway (M-11) must have an M-Tag for access starting August 15th, 2024, as reported on July 26.L</h4>
                    <div className='d-flex'>
                                                <h4 style={{marginRight:'35px'}}>By Ginny Dennis</h4>
                                                <h4>Feb. 4, 2023</h4>
                                            </div>
                </div> 
                </div>
                </div>
                <hr className='mx-3'/>


                <div className='mandorty d-flex'>
                <div className='side-latest'>
                    <img style={{borderRadius:'5px'}} src={img13} alt='...'/>
                </div>
                <div className='m-tags'>
                <div className='side-late-heading'>
                    <h3>M-Tags made mandatory for travelling on Lahore-Sialkot Motorway M-11</h3>
                    <h4>Lahore: All vehicles on the Lahore-Sialkot Motorway (M-11) must have an M-Tag for access starting August 15th, 2024, as reported on July 26.L</h4>
                    <div className='d-flex'>
                                                <h4 style={{marginRight:'35px'}}>By Ginny Dennis</h4>
                                                <h4>Feb. 4, 2023</h4>
                                            </div>
                </div> 
                </div>
                </div>
                <hr className='mx-3'/>



                <div className='mandorty d-flex'>
                <div className='side-latest'>
                    <img style={{borderRadius:'5px'}} src={img14} alt='...'/>
                </div>
                <div className='m-tags'>
                <div className='side-late-heading'>
                    <h3>M-Tags made mandatory for travelling on Lahore-Sialkot Motorway M-11</h3>
                    <h4>Lahore: All vehicles on the Lahore-Sialkot Motorway (M-11) must have an M-Tag for access starting August 15th, 2024, as reported on July 26.L</h4>
                    <div className='d-flex'>
                                                <h4 style={{marginRight:'35px'}}>By Ginny Dennis</h4>
                                                <h4>Feb. 4, 2023</h4>
                                            </div>
                </div> 
                </div>
                </div>
                
            </div>
            
        </div>
        </div>
    </div>
    </>
  )
}

export default Latestnews
