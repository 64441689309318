import React from 'react';
import img from '../../Images/Agents/image 19.png';
import img1 from '../../Images/Agents/Frame 1300192747.png';
import img2 from '../../Images/Agents/Frame 1300192750.png';
import img3 from '../../Images/Agents/image 20.png';
import img4 from '../../Images/Agents/image 21.png';
import img5 from '../../Images/Agents/image 22.png';
import { IoLocationOutline } from 'react-icons/io5';
import { MdArrowOutward } from 'react-icons/md';

function SecondSection() {


    return (
        <>
            <div className='agents '>
                <div className="row px-4 my-4 m-0 ">
                    <div className="col-6 p-0">
                        <div className="titanium">
                        Titanium Agencies
                        </div>
                    </div>
                </div>
                <div className='row mx-0 py-0 px-2 agents-box-row'> 
                  <div className="main-agentcard-box mx-1 d-flex row">{/* here row is having flexwarap : no wrap + overflow : hidden */}
                  <div className="agents-card border d-flex">
                        <div className="agents-img">
                            <img src={img} alt="" />
                        </div>
                        <div className="agents-details px-2">
                            <div className="first-heading mt-1">Property View</div>
                            <div className="location">
                                <span className='location-icon'><IoLocationOutline /></span>
                                <span className='location-details'>Lahore </span>
                            </div>
                            <div className="house-box d-flex ">
                                <div className="box-1 d-flex">
                                <div className="box1-img"> <img className='w-100' src={img1} alt="" /></div>
                                    <div className="box1-text">52 for Sale</div>
                                </div>

                                <div className="box-1 d-flex align-items-center">
                                <div className="box1-img"> <img className='w-100' src={img2} alt="" /></div>
                                    <div className="box1-text">100 for Rent</div>
                                </div>
                            </div>
                            <div className="link px-1">
                                View Agency Properties <MdArrowOutward />
                                </div>
                        </div>
                    </div>
                    <div className="agents-card border  d-flex">
                        <div className="agents-img1">
                            <img src={img3} alt="" />
                        </div>
                        <div className="agents-details px-2">
                            <div className="first-heading mt-1">Madina Estate Advisor ...</div>
                            <div className="location">
                                <span className='location-icon'><IoLocationOutline /></span>
                                <span className='location-details'>Lahore </span>
                            </div>
                            <div className="house-box d-flex ">
                                <div className="box-1 d-flex">
                                <div className="box1-img"> <img className='w-100' src={img1} alt="" /></div>
                                    <div className="box1-text">52 for Sale</div>
                                </div>

                                <div className="box-1 d-flex align-items-center">
                                <div className="box1-img"> <img className='w-100' src={img2} alt="" /></div>
                                    <div className="box1-text">100 for Rent</div>
                                </div>
                            </div>
                            <div className="link px-1">
                                View Agency Properties <MdArrowOutward />
                                </div>
                        </div>
                    </div>
                    <div className="agents-card border d-flex">
                        <div className="agents-img2">
                            <img src={img4} alt="" />
                        </div>
                        <div className="agents-details px-2">
                            <div className="first-heading mt-1">Sohan Estate</div>
                            <div className="location">
                                <span className='location-icon'><IoLocationOutline /></span>
                                <span className='location-details'>Lahore </span>
                            </div>
                            <div className="house-box d-flex ">
                                <div className="box-1 d-flex">
                                <div className="box1-img"> <img className='w-100' src={img1} alt="" /></div>
                                    <div className="box1-text">52 for Sale</div>
                                </div>

                                <div className="box-1 d-flex align-items-center">
                                <div className="box1-img"> <img className='w-100' src={img2} alt="" /></div>
                                    <div className="box1-text">100 for Rent</div>
                                </div>
                            </div>
                            <div className="link px-1">
                                View Agency Properties <MdArrowOutward />
                                </div>
                        </div>
                    </div>
                    <div className="agents-card border d-flex">
                        <div className="agents-img2">
                            <img src={img5} alt="" />
                        </div>
                        <div className="agents-details px-2">
                            <div className="first-heading mt-1">MOJ Estate</div>
                            <div className="location">
                                <span className='location-icon'><IoLocationOutline /></span>
                                <span className='location-details'>Lahore </span>
                            </div>
                            <div className="house-box d-flex ">
                                <div className="box-1 d-flex">
                                <div className="box1-img"> <img className='w-100' src={img1} alt="" /></div>
                                    <div className="box1-text">52 for Sale</div>
                                </div>

                                <div className="box-1 d-flex ">
                                <div className="box1-img"> <img className='w-100' src={img2} alt="" /></div>
                                    <div className="box1-text">100 for Rent</div>
                                </div>
                            </div>
                            <div className="link px-1">
                                View Agency Properties <MdArrowOutward />
                                </div>
                        </div>
                    </div>
                  </div>
                    
                </div>
            </div>
        </>
    );
}

export default SecondSection;