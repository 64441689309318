import React from 'react'
import img from '../../Images/Mask Group.png'
import img1 from '../../Images/logo.png'
import img2 from '../../Images/Group.png'
import img3 from '../../Images/Vector (1).png'
import img4 from '../../Images/Group 1000014144.png'
import img5 from '../../Images/Vector (2).png'
import { IoMdCheckmark } from 'react-icons/io'
import { SlLocationPin } from 'react-icons/sl'
function TopProject() {
  return (
    <>
    <div className='top-project px-4'>
        <h2>Top Projects</h2>
        <div className='row'>
            <div className='col-lg-4'>
            <div className='project-card'>
            <img src={img} className='main_img' alt='...'/>
            <div className='over_lay'></div>
            <div className='project-logo'>
                <div className='p-fold'>
                <div className='markit-project'>
                  <div className='p-markit'>Marketed By</div>
                  <div className='project-icon'>
                    <img src={img1} alt='...'/>
                    
                  </div>
                </div>
                {/* <div className='fold-card'>
                <span><AiOutlineFire/></span>
                <h3>Trending</h3>
                </div> */}
                </div>
                <div className='project-img'>
                <div className='pro-icon'>
                    <img src={img5} alt='...'/>
                  </div>
                  <div className='pro-icon'>
                    <img src={img2} alt='...'/>
                  </div>
                  <div className='pro-icon'>
                    <img src={img3} alt='...'/>
                  </div>
                  <div style={{border:'none'}} className='pro-icon'>
                    <img src={img4} alt='...'/>
                  </div>
                </div>
              

                </div>
                <div style={{top:"80px"}} className='position-relative'>
                <div className="position-absolute  pt-1 px-3"><img src='./image/Frame.svg' alt='...'/>POPULAR</div>
                        <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path id="Vector 2" d="M8 8L0 0H8V8Z" fill="#046B6D" />
                        </svg>
               </div>
              <div className='project-heading'>
              <div className='tik'>
              <span style={{marginLeft:'6px',marginTop:'-4px',color: ' #1C9093',fontSize:'16px'}}><IoMdCheckmark /></span>
              <h4>Verified</h4>
              </div>
             <h2>Beach Resort by Icon,<br/>Lahore</h2>
             <span className='d-flex align-items-center my-2'><SlLocationPin className='me-2' />Raiwind Road, Lahore</span>
             <span className='d-flex align-items-center my-2'><SlLocationPin className='me-2' />5 Marla, 10 Marla, 1 Kanal</span>
            <div>
            <p>PKR  68.9 Lakh to 7.82 Crore</p>
            </div>
              </div>
            </div>
            </div>
            <div className='col-lg-4'>
            <div className='project-card'>
            <img src={img} className='main_img' alt='...'/>
            <div className='over_lay'></div>
            <div className='project-logo'>
                <div className='p-fold'>
                <div className='markit-project'>
                  <div className='p-markit'>Marketed By</div>
                  <div className='project-icon'>
                    <img src={img1} alt='...'/>
                    
                  </div>
                </div>
                {/* <div className='fold-card'>
                <span><AiOutlineFire/></span>
                <h3>Trending</h3>
                </div> */}
                </div>
                <div className='project-img'>
                <div className='pro-icon'>
                    <img src={img5} alt='...'/>
                  </div>
                  <div className='pro-icon'>
                    <img src={img2} alt='...'/>
                  </div>
                  <div className='pro-icon'>
                    <img src={img3} alt='...'/>
                  </div>
                  <div style={{border:'none'}} className='pro-icon'>
                    <img src={img4} alt='...'/>
                  </div>
                </div>
              

                </div>
                <div style={{top:"80px"}} className='position-relative'>
                <div className="position-absolute  pt-1 px-3"><img src='./image/Frame.svg' alt='...'/>POPULAR</div>
                        <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path id="Vector 2" d="M8 8L0 0H8V8Z" fill="#046B6D" />
                        </svg>
               </div>
              <div className='project-heading'>
              <div className='tik'>
              <span style={{marginLeft:'6px',marginTop:'-4px',color: ' #1C9093',fontSize:'16px'}}><IoMdCheckmark /></span>
              <h4>Verified</h4>
              </div>
             <h2>Beach Resort by Icon,<br/>Lahore</h2>
             <span className='d-flex align-items-center my-2'><SlLocationPin className='me-2' />Raiwind Road, Lahore</span>
             <span className='d-flex align-items-center my-2'><SlLocationPin className='me-2' />5 Marla, 10 Marla, 1 Kanal</span>
            <div>
            <p>PKR  68.9 Lakh to 7.82 Crore</p>
            </div>
              </div>
            </div>
            </div>
            <div className='col-lg-4'>
            <div className='project-card'>
            <img src={img} className='main_img' alt='...'/>
            <div className='over_lay'></div>
            <div className='project-logo'>
                <div className='p-fold'>
                <div className='markit-project'>
                  <div className='p-markit'>Marketed By</div>
                  <div className='project-icon'>
                    <img src={img1} alt='...'/>
                    
                  </div>
                </div>
                {/* <div className='fold-card'>
                <span><AiOutlineFire/></span>
                <h3>Trending</h3>
                </div> */}
                </div>
                <div className='project-img'>
                <div className='pro-icon'>
                    <img src={img5} alt='...'/>
                  </div>
                  <div className='pro-icon'>
                    <img src={img2} alt='...'/>
                  </div>
                  <div className='pro-icon'>
                    <img src={img3} alt='...'/>
                  </div>
                  <div style={{border:'none'}} className='pro-icon'>
                    <img src={img4} alt='...'/>
                  </div>
                </div>
              

                </div>
                <div style={{top:"80px"}} className='position-relative'>
                <div className="position-absolute  pt-1 px-3"><img src='./image/Frame.svg' alt='...'/>POPULAR</div>
                        <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path id="Vector 2" d="M8 8L0 0H8V8Z" fill="#046B6D" />
                        </svg>
               </div>
              <div className='project-heading'>
              <div className='tik'>
              <span style={{marginLeft:'6px',marginTop:'-4px',color: ' #1C9093',fontSize:'16px'}}><IoMdCheckmark /></span>
              <h4>Verified</h4>
              </div>
             <h2>Beach Resort by Icon,<br/>Lahore</h2>
             <span className='d-flex align-items-center my-2'><SlLocationPin className='me-2' />Raiwind Road, Lahore</span>
             <span className='d-flex align-items-center my-2'><SlLocationPin className='me-2' />5 Marla, 10 Marla, 1 Kanal</span>
            <div>
            <p>PKR  68.9 Lakh to 7.82 Crore</p>
            </div>
              </div>
            </div>
            </div>
        </div>
    </div>
    </>
  )
}

export default TopProject
