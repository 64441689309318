import React, { useState } from "react";

import img from "../../Images/listing/Rectangle_185.png";
import img_2 from "../../Images/listing/Ellipse 374.png";
import whatsapp from "../../Images/listing/whatsapp.png";
import logo from "../../Images/com.png";
import { IoIosCall } from "react-icons/io";
import { teal } from '@mui/material/colors';
import Radio from '@mui/material/Radio';
import Checkbox from '@mui/material/Checkbox';
import { MdOutlineBed } from "react-icons/md";
import { LuBath } from "react-icons/lu";
import { FaCaretDown, FaRegSquare } from "react-icons/fa"
import Card from "./Card";
import flag from '../../Images/listing/pak.png'
import { useParams } from "react-router-dom";
import { useAuth } from "../../Context/ContextProvider";
import Call from "./Call";
const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
function Banner({ profileData }) {
    const { properyEnquery, whatsappImpretion } = useAuth()
    const [open, setOpen] = useState(false);
    const handleChange = (event) => {
        setData({ ...data, type: event.target.value });
    };
    const [open1, setOpen1] = useState(false);

    const handleOpen = () => setOpen1(true);
    const controlProps = (item) => ({
        checked: data.type === item,
        onChange: handleChange,
        value: item,
        name: 'color-radio-button-demo',
        inputProps: { 'aria-label': item },
    });
    const parms = useParams()
    const [fieldErrors, setFieldErrors] = React.useState({})
    const [data, setData] = useState({
        email: "",
        name: "",
        contact_number: "",
        message: "",
        property_id: parms?.id,
        type: "buyer/tenant",
        keep_informed: "",


    })
    const validateStep = () => {
        let errors = {

        };
        if (!data.name) {
            errors.name = "Name is required.";
        }
        if (!data.email) {
            errors.email = "Email is required.";
        } else if (!isValidEmail(data.email)) {
            errors.email = "Please enter a valid email address.";
        }

        if (!data.message) {
            errors.message = "Message is required.";
        }

        if (!data.type) {
            errors.type = "Type is required.";
        }

        if (!data.contact_number) {
            errors.contact = "Contact is required.";
        }
        else if (!isValidPakistaniPhoneNumber(data.contact_number)) {
            errors.contact = "Please enter a valid Pakistan phone number.";
        }
        setFieldErrors(errors);
        // Check if there are any errors
        return Object.keys(errors).length === 0;
    };
    const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const formatPakistaniPhoneNumber = (phoneNumber) => {

        let phone = phoneNumber.replace(/\D/g, ''); // Remove non-digit characters

        // Handle different input formats
        if (phone.startsWith('92')) {
            phone = phone.slice(2);
        } else if (phone.startsWith('0')) {
            phone = phone.slice(1);
        }

        if (phone.length === 10) {
            return `+92${phone}`;
        }

        return phoneNumber; // Return original input if it doesn't match the expected formats
    };
    const isValidPakistaniPhoneNumber = (phoneNumber) => {
        // Remove all non-digit characters except '+'
        // eslint-disable-next-line
        let cleanedPhoneNumber = phoneNumber.replace(/[^\d\+]/g, '');

        // Log the cleaned phone number
        console.log(`Cleaned Phone Number: "${cleanedPhoneNumber}"`);
        const pakistaniPhoneRegex = /^(?:0|\+92)?\s?(?:\d\s?){9,10}$/;
        // Regular expression for Pakistani phone number
        // const pakistaniPhoneRegex = /^\+92\d{11}$/;

        // Validate phone number format and length
        const isValid = pakistaniPhoneRegex.test(cleanedPhoneNumber);
        console.log(`Is Valid: ${phoneNumber}`);

        return isValid;
    };
    const submit = (event) => {
        event.preventDefault();
        if (validateStep()) {
            properyEnquery(data);
        }
    }
    console.log(fieldErrors);

    function formatPrice(value) {
        if (value >= 10000000) {
            // Convert to Crore
            return (value / 10000000).toFixed(2) + ' Cr';
        } else if (value >= 100000) {
            // Convert to Lakh
            return (value / 100000).toFixed(2) + ' Lakh';
        } else if (value >= 1000) {
            // Convert to Thousand
            return (value / 1000).toFixed(2) + ' Thousand';
        } else {
            // Less than 1000, show as is
            return value.toString();
        }
    }
    const [showFullText, setShowFullText] = useState(false);
    return (
        //         const [showFullText, setShowFullText] = useState(false);
        // <div className="col-12">
        //           <div
        //             dangerouslySetInnerHTML={{
        //               __html: ,
        //             }}
        //             className="Ser_para"
        //           ></div>
        //           {adminserviceDetail?.quote?.length > 640 && (
        //             <>
        //           <hr className="mb-0" style={{width:"90%", margin:"2rem auto"}}/>
        //             <div className="d-flex justify-content-center">
        //               <button
        //                 className="ser_read "
        //                 onClick={() => setShowFullText(!showFullText)}
        //               >
        //                 {showFullText ? "Read Less" : "Read More"}
        //               </button>
        //             </div>
        //             </>
        //           )}
        //         </div>
        <div className="row">
            <div className="col-lg-8 col-12 my-2">
                <div onClick={() => setOpen(true)} style={{ cursor: "pointer", borderRadius: "5px", overflow: "hidden", position: "relative" }} className="listing_main_img">
                    <img src={profileData?.images?.length ? profileData?.images[0]?.url : img} alt="" />
                    <div style={{ position: "absolute", zIndex: "99", background: "white", top: "0", display: "flex", padding: ".2rem 1rem ", margin: "1rem", borderRadius: "3px" }} className="img_video_gellery_main">
                        <div style={{ borderRight: "1px solid #1C9093", padding: "0", margin: "0", display: "flex" }}>
                            <span ><svg xmlns="http://www.w3.org/2000/svg" width="12" height="9" viewBox="0 0 12 9" fill="none">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.85742 1.28571H8.89314L8.57171 0.642857C8.38239 0.269036 8.28371 0 7.92885 0H4.07171C3.71685 0 3.59921 0.306643 3.42885 0.642857L3.10742 1.28571H2.14314C1.4331 1.28571 0.857422 1.86139 0.857422 2.57143V7.71429C0.857422 8.42432 1.4331 9 2.14314 9H9.85742C10.5675 9 11.1431 8.42432 11.1431 7.71429V2.57143C11.1431 1.86139 10.5675 1.28571 9.85742 1.28571ZM6.00028 7.71429C4.58021 7.71429 3.42885 6.56293 3.42885 5.14286C3.42885 3.72279 4.58021 2.57143 6.00028 2.57143C7.42035 2.57143 8.57171 3.72279 8.57171 5.14286C8.57171 6.56293 7.42035 7.71429 6.00028 7.71429ZM6.00028 3.21429C4.93539 3.21429 4.07171 4.07796 4.07171 5.14286C4.07171 6.20775 4.93539 7.07143 6.00028 7.07143C7.06517 7.07143 7.92885 6.20775 7.92885 5.14286C7.92885 4.07796 7.06517 3.21429 6.00028 3.21429Z" fill="#1C9093" />
                            </svg></span>
                            <span>{profileData?.images?.length}</span>
                        </div>
                        <div style={{ display: "flex" }}>
                            <span><svg xmlns="http://www.w3.org/2000/svg" width="14" height="9" viewBox="0 0 14 9" fill="none">
                                <path d="M9.04167 2.88504L13.034 0.598607C13.5625 0.290304 14 0.508032 14 1.14322V7.89278C14 8.50997 13.5625 8.76369 13.034 8.45539L9.04167 6.15096V7.85678C9.04167 8.49197 8.53125 9 7.89308 9H1.11183C0.49175 9 0 8.49197 0 7.85678V1.12522C0 0.490033 0.492333 0 1.11183 0H7.89308C8.53125 0 9.04167 0.490033 9.04167 1.12522V2.88504Z" fill="#1C9093" />
                            </svg></span>
                            <span>{profileData?.video_links?.length}</span>
                        </div>
                    </div>
                    <div style={{ position: "absolute", zIndex: "99", background: "white", top: "0", display: "flex", padding: ".2rem 1rem ", margin: "1rem", marginLeft: "130px", borderRadius: "3px" }} className="img_video_gellery_main">
                        <div style={{ display: "flex" }}>
                            <span ><svg xmlns="http://www.w3.org/2000/svg" width="11" height="9" viewBox="0 0 11 9" fill="none">
                                <path d="M1.24219 4.39945L4.02861 7.37401L10 1" stroke="#1C9093" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" />
                            </svg></span>
                            <span>Verified</span>
                        </div>

                    </div>
                </div>
                <div className="listing_main p-3">
                    <h1>{profileData?.title}</h1>
                    <h2>PKR {formatPrice(profileData?.price ? profileData?.price : 0)} <span>Added: 2 Hours ago</span></h2>
                    <div className="d-flex">
                        <div className="d-flex property_small">
                            <small className="flex-fill text-center py-2 d-flex justify-content-center align-items-center"><FaRegSquare style={{ color: "#1C9093", fontSize: "20px", margin: "0 .5rem" }} />{profileData?.area_size} {profileData?.unit_area}</small>
                            <small className="flex-fill text-center py-2 d-flex justify-content-center align-items-center"><MdOutlineBed style={{ color: "#1C9093", fontSize: "20px", margin: "0 .5rem" }} />{profileData?.bedrooms} Beds</small>
                            <small className="flex-fill text-center py-2 d-flex justify-content-center align-items-center"><LuBath style={{ color: "#1C9093", fontSize: "20px", margin: "0 .5rem" }} />{profileData?.bathrooms} Bathroom</small>
                        </div>
                    </div>
                    <h1>Description</h1>
                    <p className="discription">
                        {!showFullText && profileData?.description?.length < 640
                            ? profileData?.description
                            : `${profileData?.description?.slice(0, 640)}...`}
                    </p>
                    {profileData?.description?.length > 640 && <div className="d-flex justify-content-end">
                        <button    onClick={() => setShowFullText(!showFullText)} className="read_more">  {showFullText ? "Read Less" : "Read More"} <FaCaretDown className="mx-2" />  </button>
                    </div>}
                    <hr />
                    <div className="aminty">
                        <h1>Amenities</h1>
                        {profileData?.amenities?.length ? profileData?.amenities?.map((item) => {
                            return (
                                <div className="mian_feature">
                                    <h3>{item?.category_name}</h3>
                                    <div className="row">
                                        {item?.data?.map((items) => {
                                            return (
                                                <div className="col-lg-3 col-6 my-1">
                                                    <span>{items?.label}</span>
                                                </div>
                                            )
                                        })}



                                    </div>
                                </div>
                            )
                        }) : <div className="mian_feature">
                            <h3 style={{ fontSize: "16px" }}>Amenities not found in this Property !</h3>

                        </div>}
                    </div>
                    <hr />
                    {/* <div className="d-flex justify-content-end">
                        <button className="read_more">Read More <FaCaretDown className="mx-2" />  </button>
                    </div> */}
                    <h1>Location & Nearby</h1>
                    <h3>Society Map <span className="mx-2">Nearby</span></h3>
                    <iframe title="map" src={`https://maps.google.com/maps?q=${profileData?.location?.latitude},${profileData?.location?.longitude}&z=15&output=embed`} width="100%" height="450" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    {/* <hr /> */}
                    {/* <h1>Home Finance</h1>
                    <div className="house_finance">
                        <p>Calculate and view the monthly mortgage on this house</p>
                        <div className="d-flex align-items-center">
                            <h5 className="me-2">Conventional</h5>
                            <img src={logo} alt="..." />
                        </div>
                        <div className="row">
                            <div className="col-lg-5 col-md-6 col-12">
                                <div className='inp_login'>
                                    <label className="logo_label">Interest Plan</label>
                                    <select onChange={(e) => setData({ ...data, email: e.target.value })} type='email' placeholder='Email' >
                                        <option>HBFC Khaas</option>
                                    </select>
                                </div>

                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="payment_card">
                                    <h1 className="m-0">PKR 3.82 Lakh</h1>
                                    <h6 className="m-0">Monthly Payment</h6>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-12">
                                <div className="payment_card">
                                    <h1 className="m-0">PKR 3.82 Crore</h1>
                                    <h6 className="m-0">Bank Finance Amount</h6>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-12 col-12">
                                <div className="payment_card">
                                    <div className="d-flex justify-content-center">
                                        <h6 className="mx-2 my-0">Interest</h6>
                                        <h6 className="mx-2 my-0">Principal</h6>
                                    </div>
                                    <div className="bar_main">
                                        <div className="bar"></div>
                                    </div>
                                    <h6 className="my-0">Bank Finance Amount</h6>
                                </div>
                            </div>
                        </div>
                        <div className="row my-2">
                            <div className="col-lg-6 col-12">
                                <div className='inp_login pt-0'>
                                    <label className="logo_label m-0">Property Price</label>
                                    <input onChange={(e) => setData({ ...data, email: e.target.value })} type='email' placeholder='38,500,000 PKR' />
                                    <PrettoSlider
                                        style={{ marginTop: "1rem" }}
                                        valueLabelDisplay="auto"
                                        aria-label="pretto slider"
                                        defaultValue={20}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-6 col-12">
                                <div className='inp_login pt-0'>
                                    <label className="logo_label m-0">Loan Period</label>
                                    <input onChange={(e) => setData({ ...data, email: e.target.value })} type='email' placeholder='25 Years' />
                                    <PrettoSlider
                                        style={{ marginTop: "1rem" }}
                                        valueLabelDisplay="auto"
                                        aria-label="pretto slider"
                                        defaultValue={20}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-6 col-12">
                                <div className='inp_login pt-0'>
                                    <label className="logo_label m-0">Interest Rate</label>
                                    <input onChange={(e) => setData({ ...data, email: e.target.value })} type='email' placeholder='Email' />
                                    <PrettoSlider
                                        style={{ marginTop: "1rem" }}
                                        valueLabelDisplay="auto"
                                        aria-label="pretto slider"
                                        defaultValue={20}
                                    />

                                </div>
                            </div>
                            <div className="col-lg-6 col-12">
                                <div className='inp_login'>
                                    <label className="logo_label m-0">Interest Rate</label>
                                    <input onChange={(e) => setData({ ...data, email: e.target.value })} type='email' placeholder='Email' />
                                    <PrettoSlider
                                        style={{ marginTop: "1rem" }}
                                        valueLabelDisplay="auto"
                                        aria-label="pretto slider"
                                        defaultValue={20}
                                    />
                                </div>
                            </div>

                        </div>
                        <p><span>DISCLAIMER:</span> Interest rates may vary from the  value shown on Zameen.com. Actual interest rate prevalent as per bank’s  policy will be applicable at the time of application.</p>
                        <button className="apply_btn">Apply For Loan</button>
                    </div> */}
                </div>
            </div>
            <div className="col-lg-4 col-12 my-2">
                <div className="logo_prop p-3">
                    <div className="logo_info">
                        <div className="logo_img">

                            <img src={profileData?.user?.avatar ? profileData?.user?.avatar : img_2} alt="" />
                        </div>

                        <p className="heading_info">Al Karam Property</p>
                        <p className="content_info">
                            {profileData?.user?.name}
                            <br />
                            Agent ID: 50402718
                        </p>
                        <div className="info_btns">
                            <button onClick={() => {
                                handleOpen()

                            }}><IoIosCall className="me-2" />Call</button>

                            <a onClick={() => whatsappImpretion("whatsapp", parms?.id)} href={`https://wa.me/${profileData?.user?.whatsapp_number}`} target="_blank" rel="noopener noreferrer">
                                <button><img src={whatsapp} alt="" className="me-2" />WhatsApp</button>
                            </a>
                        </div>

                    </div>
                    <form onSubmit={submit}>
                        <div className='inp_login'>
                            <label className="logo_label">Name</label>
                            <input onChange={(e) => setData({ ...data, name: e.target.value })} type='text' placeholder='Name' />
                            {fieldErrors?.name && <span className='text_error'>{fieldErrors?.name}</span>}        </div>
                        <div className='inp_login'>
                            <label className="logo_label">Email</label>
                            <input onChange={(e) => setData({ ...data, email: e.target.value })} type='email' placeholder='Email' />
                            {fieldErrors?.email && <span className='text_error'>{fieldErrors?.email}</span>}
                        </div>

                        <div className='inp_login'>
                            <label className="logo_label">Contact</label>
                            <div className='contact_contry'>
                                <div className='img_container'>
                                    <img src={flag} alt='...' />
                                    <span>+92</span>
                                </div>
                                <input style={{ borderRadius: " 0 5px 5px 0 ", borderLeft: "none" }} onChange={(e) => setData({ ...data, contact_number: formatPakistaniPhoneNumber(e.target.value) })} type='number' placeholder='e.g 3324751060' />
                            </div>
                            {fieldErrors?.contact && <span className='text_error'>{fieldErrors?.contact}</span>}
                        </div>
                        <div className='inp_login'>
                            <label className="logo_label">Message</label>
                            <textarea style={{ minHeight: "100px", width: "100%", border: "1px solid lightgray", borderRadius: "5px", padding: ".5rem" }} onChange={(e) => setData({ ...data, message: e.target.value })} type='email' placeholder='Write your message.....' />
                            {fieldErrors?.message && <span className='text_error'>{fieldErrors?.message}</span>}
                        </div>
                        <div className='form_radio'>
                            <label className="logo_label">I am a:</label>
                            <Radio
                                {...controlProps('buyer/tenant')}
                                checked={data?.type==="buyer/tenant"?true:false}
                                sx={{
                                    color: teal[800],
                                    '&.Mui-checked': {
                                        color: teal[600],
                                    },
                                }}
                            />
                            <span style={{ color: "#646C6D" }}>Buyer / Tenant</span>
                            <Radio
                                {...controlProps('agent')}
                                checked={data?.type==="agent"?true:false}
                                sx={{
                                    color: teal[800],
                                    '&.Mui-checked': {
                                        color: teal[600],
                                    },
                                }}
                            />
                            <span style={{ color: "#646C6D" }}>Agent</span>
                            <Radio
                                {...controlProps('other')}
                                checked={data?.type==="other"?true:false}
                                sx={{
                                    color: teal[800],
                                    '&.Mui-checked': {
                                        color: teal[600],
                                    },
                                }}
                            />
                            <span style={{ color: "#646C6D" }}>Other</span>
                            {fieldErrors?.type && <span className='text_error'>{fieldErrors?.type}</span>}
                        </div>
                        {/* <div className='inp_login px-2'>
                            <input onChange={(e) => setData({ ...data, name: e.target.value })} type='text' placeholder='Investor' />
                            {fieldErrors?.email && <span className='text_error'>{fieldErrors?.email}</span>}
                        </div> */}
                        <div className='form_radio d-flex align-items-center'>
                            <Checkbox
                                {...label}
                               style={{marginTop:".5rem"}}
                                onChange={(e) => setData({ ...data, keep_informed: e.target.checked ? true : false })}
                                sx={{
                                    color: teal[800],
                                    '&.Mui-checked': {
                                        color: teal[600],
                                    },
                                }}
                            />
                            <label className="logo_label">Keep me informed about similar properties.</label>
                            {fieldErrors?.email && <span className='text_error'>{fieldErrors?.email}</span>}
                        </div>
                        <button type='submit' className='Sign_inBtn py-3'>Send Email</button>
                    </form>
                </div>
            </div>
            <Card open={open} setOpen={setOpen} profileData={profileData} />
            <Call land_line={profileData?.land_line} open={open1} setOpen={setOpen1} />
        </div>
    );
}

export default Banner;
