import React, { useState } from 'react';
import img from '../../Images/About/Frame 1300193069.png';

function Entrance() {
    const [active,setActive]=useState("High Rise")
    let array=["High Rise","Housing Society","Lease","Instant Valuation","Post Property"]
    return (
        <>
            <div className="container-fluid mx-0 entrance-container p-0">
                <div className="row my-4 mx-0 p-0">
                    <div className="main-img">
                        <img className="w-100 h-100" src={img} alt="..." />
                    </div>
                </div>
                <div className="row mt-4 pt-1 px-0 mx-0">
                    <div className="col-xl-2 col-md-3 col-12 m-0 p-0">
                        <div className="item-box my-4 mx-0">
                            <div className="first-item my-2"></div>
                            {array?.map((item)=>{
                                return(
                                    <>
                                    {active===item?    <div style={{cursor:"pointer"}} onClick={()=>setActive(item)} className="second-item px-2 my-3">
                                <span className="item-line"></span> {item}
                            </div>:<div style={{cursor:"pointer"}} onClick={()=>setActive(item)} className="first-item my-2">{item}</div>}
                                    </>
                                )
                            })}
                            {/* <div className="first-item my-2">High Rise</div>
                            <div className="first-item my-2">High Rise</div> */}
                        
                            {/* <div className="first-item item-third">Lease</div>
                            <div className="first-item my-3">Instant Valuation</div>
                            <div className="first-item ">Post Property</div> */}
                        </div>
                    </div>

                    <div className="col-xl-5 col-md-9  col-12 details-col">
                        <div className="main-parabox">
                            <p className="para mt-3">
                                Welcome to Pakistan Property, your premier provider of luxury real estate services across Pakistan. Since our founding in 2018, we have established ourselves as leaders in the real estate sector, catering primarily to the sales market. Known for our professional expertise and exceptional customer service, we specialize in both residential and commercial properties, tailored to meet the needs of a diverse and sophisticated clientele.
                            </p>

                            <p className="para my-4 py-2">
                                At Pakistan Property, we are a dynamic, forward-thinking company that blends deep local knowledge with international market expertise. Our team comprises seasoned real estate consultants, economists, designers, engineers, and architects—all dedicated to exceeding our clients' expectations with precision and innovation.
                            </p>
                            <p className="para mb-xl-0 mb-4 pb-xl-0 pb-4">
                                At Pakistan Property, we provide a comprehensive suite of luxury real estate services designed to cater to every aspect of property ownership and development. Here’s a closer look at our specialized services.
                            </p>
                        </div>
                    </div>

                    <div className="col-xl-5 col-12 colorbox-col" style={{ paddingLeft: '75px', paddingRight: "22px" }}>
                        <div className="colored-box"></div>
                    </div>
                </div>

            </div>
        </>
    );
}

export default Entrance;
