import React from 'react'
import img from '../../Images/About/Rectangle 1858.png'
import img1 from '../../Images/About/Rectangle 1859.png'
import img2 from '../../Images/About/Rectangle 1860.png'
import img3 from '../../Images/About/Rectangle 1861.png'
import img4 from '../../Images/About/Rectangle 1862.png'
import img5 from '../../Images/About/Rectangle 1863.png'
function WhatWeDo() {
    return (
        <>
            <div className="container-fluid  we-container px-4">
                <div className="row  mb-3">
                    <div className="main-heading mt-5">WHAT WE DO</div>
                </div>
                <div className="row ">
                    <div className="col-lg-6 col-12 px-3">
                        <div className="main-images-box">
                            <div className="row mt-2 about-img-row">
                                <div className="col-md-5 col-sm-6 col-10 mx-1">
                                    <div className="about-img">
                                        <img src={img} alt="" />
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-5 col-10 my-sm-0 my-4">
                                    <div className="about-img">
                                        <img src={img1} alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="row my-sm-4 my-0 py-sm-3 py-0 about-img-row">
                                <div className="col-md-5 col-sm-6 col-10 img-col ">
                                    <div className="about-img">
                                        <img src={img2} alt="" />
                                    </div>
                                </div>
                                <div className="col-md-3 col-sm-4 p-0 col-10 my-sm-0 my-4">
                                    <div className="about-img">
                                        <img src={img3} alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="row about-img-row">
                                <div className="col-sm-4 col-10">
                                    <div className="about-img">
                                        <img src={img4} alt="" />
                                    </div>
                                </div>
                                <div className="col-sm-7 col-10 mx-1 mt-sm-0 mt-4 " >
                                    <div className="about-img">
                                        <img src={img5} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-12 mt-lg-0 mt-5 pt-lg-0 pt-4 textbox-col">
                        <div className="textbox-1 row p-0">
                            <div className="textbox1-heading same-padding">
                                PakistanProperty.com: Your Partner in Finding the Perfect Home
                            </div>
                            <div className="textbox1-paragraph1 my-2 same-padding">
                                At PakistanProperty.com, we believe that finding a home is more than just a transaction; it's about finding a place that truly resonates with you. We understand that the search for the perfect property can be both exciting and daunting.
                            </div>

                            <div className="textbox1-paragraph2 my-3 same-padding">
                                That's why we've created a platform that empowers both real estate agents and clients, providing a seamless and secure environment for all your property needs.
                            </div>

                        </div>
                        <div className="textbox-2 row p-0">
                            <div className="texbox2-heading1 my-1 same-padding mx-0">
                                Empowering Agents, Empowering Clients
                            </div>
                            <div className="textbox2-heading2 my-2 same-padding mx-0">
                                For Real Estate Agents:
                            </div>
                            <div className="textbox2-listdata mx-0 same-space">
                                <ul className='ul-tag'>
                                    <li className='my-1'>Showcase your listings with ease using our intuitive property management tools.</li>
                                    <li className='my-1'>Expand your reach and connect with a wider audience of potential buyers and renters.</li>
                                    <li className='my-1'>Streamline your workflow and boost efficiency with our comprehensive agent tools.</li>
                                </ul>
                            </div>
                        </div>
                        <div className="textbox-3 row p-0 my-2 ">
                            <div className="textbox3-heading same-padding mb-2">
                                For Clients:
                            </div>
                            <div className="textbox3-listdata mx-0 same-space">
                                <ul className='ul-tag'>
                                    <li className='my-1'>Effortlessly search and discover properties that match your criteria.</li>
                                    <li className='my-1'>Access detailed property information and make informed decisions.</li>
                                    <li className='my-1'>Connect with agents seamlessly and conduct secure transactions.</li>
                                </ul>
                            </div>
                        </div>
                        <div className="textbox-4 row p-0">
                            <div className="texbox4-heading my-1 same-padding mx-0">
                                Our Commitment
                            </div>
                            <div className="textbox4-paragraph my-2 same-padding mx-0">
                                We are dedicated to revolutionizing the real estate landscape in Pakistan. By focusing on empowerment, efficiency, and innovation, we aim to:
                            </div>
                            <div className="textbox4-listdata mx-0 same-space">
                                <ul className='ul-tag'>
                                    <li className='my-1'>Bridge the gap between real estate agents and clients.</li>
                                    <li className='my-1'>Foster transparency and trust throughout the property search process.</li>
                                    <li className='my-1'>Provide a secure and reliable platform for all transactions.</li>
                                    <li className='my-1'>Continuously innovate and adapt to the evolving needs of the market.</li>
                                </ul>
                            </div>
                        </div>
                        <div className="textbox-5 row p-0">
                            <div className="texbox5-heading my-1 same-padding mx-0">
                                Join the Future of Real Estate One Home at a time
                            </div>
                            <div className="textbox5-paragraph mt-1 mb-4 pb-2 same-padding mx-0">
                                PakistanProperty.com invites you to embark on a journey to redefine the standards of property management and transactions in Pakistan. Together, let's create a future where finding your perfect home is a seamless and enjoyable experience.</div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}

export default WhatWeDo
