import React, { useEffect, useRef, useState } from 'react'
import { FiEye } from 'react-icons/fi'
import { GoDotFill } from 'react-icons/go'
import { HiOutlineDotsVertical } from 'react-icons/hi'
import { MdOutlineEdit } from 'react-icons/md'
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { useAuth } from '../../Context/ContextProvider'
import { useNavigate } from 'react-router-dom'
import logo from '../../Images/New folder/logo.png'
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Button, IconButton } from '@mui/material'
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { BsInfoCircle } from 'react-icons/bs'
function MyListing() {
    const { handleClick,
        setError, getcateGory, getsubCateGory, serchProperty, getProprtey, getType, deleteProperty, getLOcation, getCity, getQoutaState, upgradeProperty, rePostProperty } = useAuth()
    const BootstrapTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} arrow classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.arrow}`]: {
            color: theme.palette.common.black,
        },
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.common.black,
        },
    }));
    const [list, setList] = useState([])
    const [type, setType] = useState([])
    const [city_value, setCityValue] = useState([])
    const [tableList, settableList] = useState([])
    const [city, setcity] = useState([])
    const [allcity, setallcity] = useState([])
    const [category, setCategory] = useState([])
    const [sub_category, setsub_category] = useState([])
    const [location, setLocation] = useState([])
    const [status, setstatus] = useState("active")
    const navigate = useNavigate()
    const [show, setShow] = useState(false)
    const [show1, setShow1] = useState(false)
    const [show2, setShow2] = useState(false)
    const [show3, setShow3] = useState(false)
    const [productData, setProductData] = useState([])
    const [property_ids, setPropoertyID] = useState("")
    const [paginationData, setPaginationData] = useState({
        perPage: 25
    })
    const wrapperRef = useRef(null);
    // Close the dropdown if clicked outside
    const handleClickOutside = (event) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            setShow1(false);
            setShow(false);
            setShow2(false);
            setShow3(false);
            if (!data?.city_code && !data?.location_id) {
                setCityValue({
                    city: "",
                    location: ""
                })
            }
            else if (!data?.city_code) {
                setCityValue({
                    ...city_value,
                    city: "",
                })
            }
            else {
                setCityValue({
                    ...city_value,
                    location: "",
                })
            }
        }
    };
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        // Cleanup the event listener on component unmount
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    const [anchorEl1, setAnchorEl1] = React.useState(null);
    const open1 = Boolean(anchorEl1);
    const handleClick1 = (event) => {
        setAnchorEl1(event.currentTarget);
    };
    const handleClose1 = () => {
        setAnchorEl1(null);
    };
    const [data, setData] = useState({
        city_code: "",
        location_id: "",
        property_type_id: "",
        sub_category_id: "",
        currency: "",
        unit_area: "",
        status: "",
        rooms: "",
        price: {
            min: "",
            max: ""
        },
        area_size: {
            min: "",
            max: ""
        },

    })
    let statusesToInclude = ["active", "pending", "rejected", "expired", "deleted", "downgraded", "inactive"]
    useEffect(() => {
        const fetchData = async () => {
            try {
                let result = await getProprtey(paginationData);
                let tabData = []
                if (result?.success) {
                    result?.data?.data?.statuses?.filter((item) => {
                        if (item?.status?.toLowerCase() === status) {
                            setList(item?.properties)
                            setPaginationData({
                                ...paginationData,
                                total_pages: item.total_pages
                            })
                        }
                    })

                    // Filter the data based on the status and populate the tabData array
                    result?.data?.data?.statuses?.forEach((item) => {
                        if (statusesToInclude?.includes(item?.status?.toLowerCase())) {
                            tabData.push({
                                name: item?.status?.toLowerCase(),
                                total: item?.total,
                                properties: item?.properties,
                                total_pages: item?.total_pages,
                                current_page: item?.current_page
                            });
                        }
                    });

                    // Log the filtered data to the console

                    // Update the state with the filtered data
                    settableList(tabData);
                }
            } catch (error) {
                console.error("An error occurred while fetching data:", error);
            }
        };
        const fetchData2 = async () => {
            try {
                let result = await getQoutaState();
                if (result?.success) {
                    setProductData(result?.data?.data?.offer_logs)

                }

            } catch (error) {
                console.error("An error occurred while fetching data:", error);
            }
        };
        const fetchCity = async () => {
            try {
                let result = await getCity();
                if (result?.success) {
                    setcity(result.data);
                    setallcity(result.data);
                }
            } catch (error) {
                console.error("An error occurred while fetching data:", error);
            }
        };
        const fetchData1 = async () => {
            try {
                let result = await getType();
                if (result?.success) {
                    const propertyTypes = result.data.data.property_types;
                    setType(propertyTypes);

                    const selectedTypeId = data?.type || propertyTypes[0]?.id;
                    let result1 = await getcateGory(selectedTypeId);
                    if (result1?.success) {
                        const categories = result1.data.data.categories;
                        setCategory(categories);

                        const selectedCategoryId = data?.property_type || categories[0]?.id;
                        let result2 = await getsubCateGory(selectedCategoryId);
                        if (result2?.success) {
                            setsub_category(result2.data.data.sub_categories);

                            // Update the data state based on the fetched results
                            setData(prevData => ({
                                ...prevData,
                                type: selectedTypeId,
                                property_type: selectedCategoryId,
                                property_sub_type: data?.property_sub_type || result2.data.data.sub_categories[0]?.id
                            }));
                        }
                    }
                }
            } catch (error) {
                console.error("An error occurred while fetching data:", error);
            }
        };
        fetchData();
        fetchData1();
        fetchData2();
        fetchCity();
        // eslint-disable-next-line
    }, [])
    function formatPrice(value) {
        if (value >= 10000000) {
            // Convert to Crore
            return (value / 10000000).toFixed(2) + ' Cr';
        } else if (value >= 100000) {
            // Convert to Lakh
            return (value / 100000).toFixed(2) + ' Lakh';
        } else if (value >= 1000) {
            // Convert to Thousand
            return (value / 1000).toFixed(2) + ' Thousand';
        } else {
            // Less than 1000, show as is
            return value.toString();
        }
    }
    const handlePageChange = async (event, value) => {

        try {
            setPaginationData({ ...paginationData, page_number: value })
            let result = await getProprtey({...paginationData, page: value, toPaginate: status });
            let tabData = []
            if (result?.success) {
                result?.data?.data?.statuses?.filter((item) => {
                    if (item?.status?.toLowerCase() === status) {
                        setList(item?.properties)
                    }
                })

                // Filter the data based on the status and populate the tabData array
                result?.data?.data?.statuses?.forEach((item) => {
                    if (statusesToInclude?.includes(item?.status?.toLowerCase())) {
                        tabData.push({
                            name: item?.status?.toLowerCase(),
                            total: item?.total,
                            properties: item?.properties,
                            total_pages: item?.total_pages,
                            current_page: item?.current_page
                        });
                    }
                });

                // Log the filtered data to the console

                // Update the state with the filtered data
                settableList(tabData);
            }
        } catch (error) {
            console.error("An error occurred while fetching data:", error);
        } // Use the new page number provided by MUI
        // Perform your data fetching or state update here
    };
    console.log(status);
    function formatDate(dateString) {
        const date = new Date(dateString);

        const options = {
            year: 'numeric',
            month: 'short',
            day: 'numeric'
        };

        return date.toLocaleDateString('en-US', options);
    }
    return (
        <div className='add_propertey_main'>
            <h3>My Listing</h3>
            <div className='content_box'>
                <div className='locationPurpos'>
                    <div className='row'>
                        <div className='col-lg-3 col-md-6 col-12'>
                            <div className='inp_login'>
                                <label>Property ID</label>
                                <input type='text' placeholder='Last Name' />
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-6 col-12'>
                            <div className='inp_login'>
                                <label>City</label>
                                <input onClick={() => setShow1(true)} value={city_value?.city} onChange={async (e) => {
                                    setCityValue({ ...city_value, city: e.target.value })
                                    if (e.target.value) {
                                        setShow1(true)
                                        let array = allcity?.filter((item) => item?.city?.toLowerCase().includes(e.target.value?.toLowerCase()));
                                        setcity(array)
                                    }
                                    else {
                                        setShow1(false)
                                        setcity(allcity)
                                    }
                                }} placeholder='Select Location' />
                                {show1 && <ul ref={wrapperRef}>
                                    {city?.map((item) => {
                                        return (
                                            <li onClick={() => {
                                                setCityValue({ ...city_value, city: item?.city })
                                                setData({ ...data, city_code: item?.app_code })
                                                setShow1(false)
                                            }}>{item?.city}</li>
                                        )
                                    })}
                                </ul>}
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-6 col-12'>
                            <div className='inp_login'>
                                <label>Location</label>
                                <input value={city_value?.location} onChange={async (e) => {
                                    setCityValue({ ...city_value, location: e.target.value })
                                    if (e.target.value) {
                                        setShow(true)
                                    }
                                    else {
                                        setShow(false)
                                    }
                                    let result = await getLOcation({
                                        city_code: data?.city_code,
                                        keyword: e.target.value,
                                    });
                                    if (result?.success) {
                                        setLocation(result?.data.status === false ? [] : result?.data);
                                    }
                                }} placeholder='Select Location' />
                                {show && <ul ref={wrapperRef}>
                                    {location?.map((item) => {
                                        return (
                                            <li onClick={() => {
                                                setCityValue({ ...city_value, location: item?.name })
                                                setData({ ...data, location_id: item?.id })
                                                setShow(false)
                                            }}>{item?.name}</li>
                                        )
                                    })}
                                </ul>}
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-6 col-12'>
                            <div className='inp_login'>
                                <label>Purpose </label>
                                <select onChange={(e) => setData({ ...data, property_type_id: e.target.value })}>
                                    {type?.map((item) => {
                                        <option hidden>Choose tyoe</option>
                                        return (
                                            <option value={item?.id}>{item?.name}</option>
                                        )
                                    })}
                                </select>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-6 col-12'>
                            <div className='inp_login'>
                                <label>Property Type</label>
                                <select onChange={async (e) => {
                                    let result2 = await getsubCateGory(e.target.value)
                                    setData({ ...data, category_id: e.target.value })
                                    if (result2?.success) {
                                        setsub_category(result2?.data?.data?.sub_categories)
                                    }
                                }}>
                                    <option hidden>Choose City</option>
                                    {category?.map((item) => {
                                        return (
                                            <option value={item?.id}>{item?.name}</option>
                                        )
                                    })}
                                </select>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-6 col-12'>
                            <div className='inp_login'>
                                <label>Property Sub Type</label>
                                <select onChange={(e) => {
                                    setData({ ...data, sub_category_id: e.target.value })
                                }}>
                                    {sub_category?.map((item) => {
                                        return (
                                            <option value={item?.id}>{item?.name}</option>
                                        )
                                    })}
                                </select>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-6 col-12'>
                            <div className='inp_login'>
                                <label>Listed Date</label>
                                <div style={{ border: "1px solid lightgray", borderRadius: "5px" }} className='d-flex justify-content-between align-items-center pe-2'>
                                    {/* <input  style={{width:"80%",border:"none"}} type='text' placeholder='Last Name' /> */}
                                    <input id='date' onChange={(e) => setData({ ...data, listed_date: e.target.value })} style={{ width: "100%", border: "none" }} type='date' placeholder='Last Name' />
                                    {/* <div onClick={handleClickDate} >
                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="20" viewBox="0 0 22 20" fill="none">
                                    <path d="M0 6.75146V17.0387C0 18.5314 1.23366 19.7458 2.75002 19.7458H19.2501C20.7665 19.7458 22.0002 18.5314 22.0002 17.0387V6.75146H0ZM6.60005 16.4973C6.60005 17.0955 6.1078 17.5801 5.50004 17.5801H4.40003C3.79228 17.5801 3.30002 17.0955 3.30002 16.4973V15.4144C3.30002 14.8161 3.79228 14.3315 4.40003 14.3315H5.50004C6.1078 14.3315 6.60005 14.8161 6.60005 15.4144V16.4973ZM6.60005 11.0829C6.60005 11.6812 6.1078 12.1658 5.50004 12.1658H4.40003C3.79228 12.1658 3.30002 11.6812 3.30002 11.0829V10.0001C3.30002 9.40178 3.79228 8.9172 4.40003 8.9172H5.50004C6.1078 8.9172 6.60005 9.40178 6.60005 10.0001V11.0829ZM12.6501 16.4973C12.6501 17.0955 12.1578 17.5801 11.5501 17.5801H10.4501C9.84232 17.5801 9.35007 17.0955 9.35007 16.4973V15.4144C9.35007 14.8161 9.84232 14.3315 10.4501 14.3315H11.5501C12.1578 14.3315 12.6501 14.8161 12.6501 15.4144V16.4973ZM12.6501 11.0829C12.6501 11.6812 12.1578 12.1658 11.5501 12.1658H10.4501C9.84232 12.1658 9.35007 11.6812 9.35007 11.0829V10.0001C9.35007 9.40178 9.84232 8.9172 10.4501 8.9172H11.5501C12.1578 8.9172 12.6501 9.40178 12.6501 10.0001V11.0829ZM18.7001 16.4973C18.7001 17.0955 18.2079 17.5801 17.6001 17.5801H16.5001C15.8924 17.5801 15.4001 17.0955 15.4001 16.4973V15.4144C15.4001 14.8161 15.8924 14.3315 16.5001 14.3315H17.6001C18.2079 14.3315 18.7001 14.8161 18.7001 15.4144V16.4973ZM18.7001 11.0829C18.7001 11.6812 18.2079 12.1658 17.6001 12.1658H16.5001C15.8924 12.1658 15.4001 11.6812 15.4001 11.0829V10.0001C15.4001 9.40178 15.8924 8.9172 16.5001 8.9172H17.6001C18.2079 8.9172 18.7001 9.40178 18.7001 10.0001V11.0829Z" fill="#646C6D" />
                                    <path d="M19.2501 1.87869H18.7001C18.7001 0.985328 17.9576 0.254395 17.0501 0.254395C16.1426 0.254395 15.4001 0.985328 15.4001 1.87869H6.60005C6.60005 0.985328 5.85754 0.254395 4.95004 0.254395C4.04253 0.254395 3.30002 0.985328 3.30002 1.87869H2.75002C1.23201 1.87869 0 3.0915 0 4.58586V5.66872H22.0002V4.58586C22.0002 3.0915 20.7682 1.87869 19.2501 1.87869ZM4.95004 1.33726C5.25254 1.33726 5.50004 1.5809 5.50004 1.87869V2.96156C5.50004 3.25935 5.25254 3.50299 4.95004 3.50299C4.64753 3.50299 4.40003 3.25935 4.40003 2.96156V1.87869C4.40003 1.5809 4.64753 1.33726 4.95004 1.33726ZM17.0501 1.33726C17.3526 1.33726 17.6001 1.5809 17.6001 1.87869V2.96156C17.6001 3.25935 17.3526 3.50299 17.0501 3.50299C16.7476 3.50299 16.5001 3.25935 16.5001 2.96156V1.87869C16.5001 1.5809 16.7476 1.33726 17.0501 1.33726Z" fill="#646C6D" />
                                </svg>
                                </div> */}
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-6 col-12'>
                            <div style={{ position: "relative" }} className='inp_login'>
                                <label>Price Range   </label>
                                <input readOnly value={data?.price?.min + " " + "to" + " " + data?.price?.max + " " + data?.currency} onClick={() => setShow2(true)} type='text' placeholder='Last Name' />
                                {show2 && <div ref={wrapperRef} style={{ position: "absolute", top: "100%", width: "100%", minHeight: "120px", padding: ".5rem 1rem" }} className='filter_box'>
                                    <span>Price Range</span>
                                    <hr className='p-0 m-0' />
                                    <div className='d-flex justify-content-around my-2'>
                                        <input onChange={(e) => setData({ ...data, price: { ...data?.price, min: e.target.value } })} style={{ width: "40%", padding: "0 1rem" }} placeholder='min' />
                                        <span>to</span>
                                        <input onChange={(e) => setData({ ...data, price: { ...data?.price, max: e.target.value } })} style={{ width: "40%", padding: "0 1rem" }} placeholder='max' />
                                    </div>
                                    <div className='d-flex justify-content-around'>
                                        <select onChange={(e) => {
                                            setData({ ...data, currency: e.target.value })
                                        }} style={{ padding: ".1rem 1rem", width: "95%" }}>
                                            <option hidden>Currency</option>
                                            <option value={"pkr"}>PKR</option>
                                            <option value={"usd"}>USD</option>
                                        </select>

                                    </div>
                                </div>}
                            </div>
                        </div>
                        <div className='row justify-content-between'>
                            <div className='col-lg-3 col-md-6 col-12'>
                                <div style={{ position: "relative" }} className='inp_login'>
                                    <label>Area Range</label>
                                    <input readOnly value={data?.area_size?.min + "to" + data?.area_size?.max + data?.unit_area} onClick={() => setShow3(true)} type='text' placeholder='Last Name' />
                                    {show3 && <div ref={wrapperRef} style={{ position: "absolute", top: "100%", width: "100%", minHeight: "120px", padding: ".5rem 1rem" }} className='filter_box'>
                                        <span>Area Range</span>
                                        <hr className='p-0 m-0' />
                                        <div className='d-flex justify-content-around my-2'>
                                            <input onChange={(e) => setData({ ...data, area_size: { ...data?.area_size, min: e.target.value } })} style={{ width: "40%", padding: "0 1rem" }} placeholder='min' />
                                            <span>to</span>
                                            <input onChange={(e) => setData({ ...data, area_size: { ...data?.area_size, max: e.target.value } })} style={{ width: "40%", padding: "0 1rem" }} placeholder='max' />
                                        </div>
                                        <div className='d-flex justify-content-around'>
                                            <select onChange={(e) => {
                                                setData({ ...data, unit_area: e.target.value })
                                            }} style={{ padding: ".1rem 1rem", width: "95%" }}>
                                                <option hidden>Area Unit</option>
                                                <option value={"Kanal"}>Kanal</option>
                                                <option value={"Sq.ft"}>Sq.ft</option>
                                            </select>

                                        </div>
                                    </div>}
                                </div>
                            </div>
                            <div className='col-lg-3 col-md-6 col-12 d-flex justify-content-end align-items-center'>
                                <button onClick={() => serchProperty(data)} style={{ background: "#1C9093", color: "white", border: "none", padding: ".8rem 1.5rem" }}>Search</button>
                            </div>
                        </div>

                    </div>

                </div>
                <div className='locationPurpos my-4'>
                    <div className='d-flex justify-content-between align-items-center'>

                        <div className='listing_tabs'>
                            {tableList?.map((item) => {
                                return (
                                    <span onClick={() => {
                                        setList(item?.properties)
                                        setstatus(item?.name)
                                        setPaginationData({
                                            total_pages: item.total_pages
                                        })
                                    }} style={{ textTransform: "capitalize", color: `${item?.name === status ? "#1C9093" : ""}`, cursor: "pointer" }} className='me-3'>{item?.name}({item?.total})</span>

                                )
                            })}
                        </div>
                        <div className='inp_login d-flex align-items-center'>
                            <label style={{ whiteSpace: "nowrap", marginRight: ".5rem" }}>Show entries:</label>
                            <select value={paginationData?.perPage} onChange={async (e) => {

                                let result = await getProprtey({ ...paginationData, toPaginate: status, perPage: e.target.value });
                                let tabData = []
                                if (result?.success) {
                                    result?.data?.data?.statuses?.filter((item) => {
                                        if (item?.status?.toLowerCase() === status) {
                                            setList(item?.properties)
                                            setPaginationData({ ...paginationData, perPage: parseInt(e.target.value), total_pages: item?.total_pages })
                                        }
                                    })

                                    // Filter the data based on the status and populate the tabData array
                                    result?.data?.data?.statuses?.forEach((item) => {
                                        if (statusesToInclude?.includes(item?.status?.toLowerCase())) {
                                            tabData.push({
                                                name: item?.status?.toLowerCase(),
                                                total: item?.total,
                                                properties: item?.properties,
                                                total_pages: item?.total_pages,
                                                current_page: item?.current_page
                                            });
                                        }
                                    });

                                    // Log the filtered data to the console

                                    // Update the state with the filtered data
                                    settableList(tabData);
                                }
                            }}>

                                <option value={10}>10</option>
                                <option value={25}>25</option>
                                <option value={50}>50</option>

                            </select>
                        </div>
                    </div>
                    <div className='listing_table'>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col">ID</th>
                                    <th scope="col">Property</th>
                                    <th scope="col">Price</th>
                                    <th scope="col">Health</th>
                                    <th scope="col">Plateform</th>
                                    <th scope="col">Views</th>
                                    <th scope="col">Leads</th>
                                    <th scope="col">Posted On</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Quota</th>
                                    {status !== "pending" ? <th scope="col">Upgrades</th> : ""}
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {list.length ? list?.map((item, i) => {
                                    return (
                                        <tr>
                                            <th scope="row">{i + 1}</th>
                                            <td><span >{item?.title}</span></td>
                                            <td>{formatPrice(item?.price)}</td>
                                            <td><GoDotFill style={{ color: "#FFB7A0", fontSize: "20px" }} /></td>
                                            <td><span className='plateform'>Pakistan Property</span></td>
                                            <td>260</td>
                                            <td>260</td>
                                            <td>
                                                <div className='d-flex justify-content-start'>
                                                    {formatDate(item?.started_at)}
                                                    <BootstrapTooltip title={item?.ended_at}>
                                                        <Button style={{ padding: "0", margin: "0", border: "none" }}><BsInfoCircle /></Button>
                                                    </BootstrapTooltip>
                                                </div>
                                            </td>
                                            <td><span className='status' style={{ textTransform: "capitalize" }}>{status}</span></td>
                                            <td>260</td>
                                            {status === "active" && <td><div className='d-flex align-items-center'>
                                                {productData?.map((items) => {
                                                    if (items?.name !== "Listing") {
                                                        return (
                                                            <span title={items?.name} onClick={() => {
                                                                if (items?.available) {
                                                                    upgradeProperty({ offer_id: items?.offer_id, property_id: item?.id })
                                                                }
                                                            }} style={{ cursor: `${items?.available ? "pointer" : "not-allowed"}` }} className='icons'>
                                                                <img src={items?.image} alt='...' />
                                                            </span>
                                                        )
                                                    }

                                                })}


                                            </div></td>}

                                            {status === "expired" || status === "deleted" || status === "downgraded" || status === "rejected" ? <td>

                                                <button onClick={() => {
                                                    rePostProperty(item?.id)
                                                }} className='d-flex justify-content-between'>Re-post to <img src={logo} style={{ width: "20px", marginLeft: "20px" }} alt='...' /></button>

                                            </td> : ""}
                                            {status === "inactive" && <td>

                                                <button onClick={async () => {
                                                    const listingItem = productData?.find(item => item?.name === "Listing" && item?.available);
                                                    if (listingItem) {
                                                        let result = await upgradeProperty({ offer_id: listingItem?.offer_id, property_id: item?.id });
                                                        if (result?.success) {
                                                            let result1 = await getProprtey({ page: paginationData?.page_number, toPaginate: status })
                                                            let tabData = []
                                                            if (result1?.success) {
                                                                result1?.data?.data?.statuses?.filter((item) => {
                                                                    if (item?.status?.toLowerCase() === status) {
                                                                        setList(item?.properties)
                                                                    }
                                                                })

                                                                // Filter the data based on the status and populate the tabData array
                                                                result1?.data?.data?.statuses?.forEach((item) => {
                                                                    if (statusesToInclude?.includes(item?.status?.toLowerCase())) {
                                                                        tabData.push({
                                                                            name: item?.status?.toLowerCase(),
                                                                            total: item?.total,
                                                                            properties: item?.properties,
                                                                            total_pages: item?.total_pages,
                                                                            current_page: item?.current_page
                                                                        });
                                                                    }
                                                                });

                                                                // Log the filtered data to the console

                                                                // Update the state with the filtered data
                                                                settableList(tabData);
                                                            }
                                                        }
                                                        return;
                                                    }
                                                    else {
                                                        handleClick()
                                                        setError({
                                                            message: "Qouta Not Found for Listing",
                                                            color: "error"
                                                        })
                                                    }


                                                }} className='d-flex justify-content-between'>Publish Now <img src={logo} style={{ width: "20px", marginLeft: "20px" }} alt='...' /></button>

                                            </td>}
                                            <td>{status !== "inactive" && <div className='d-flex align-items-center'>
                                                <span className='icons'>
                                                    <FiEye />

                                                </span>
                                                <span className='icons'>

                                                    <MdOutlineEdit onClick={() => {
                                                        navigate(`/agent/add-property/${item?.id}`)
                                                    }} />
                                                </span>
                                                <IconButton
                                                    aria-label="more"
                                                    id="long-button"
                                                    style={{ padding: "0", border: "none" }}
                                                    aria-controls={open1 ? 'long-menu' : undefined}
                                                    aria-expanded={open1 ? 'true' : undefined}
                                                    aria-haspopup="true"
                                                    onClick={(e) => {
                                                        setPropoertyID(item?.id)
                                                        handleClick1(e)
                                                    }}
                                                >
                                                    <HiOutlineDotsVertical />
                                                </IconButton>

                                            </div>}</td>

                                        </tr>
                                    )
                                }) :
                                    <tr>
                                        <td className='text-center p-5' colSpan={12}>
                                            <span>Data Not Found !</span>
                                        </td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div>

                    <Menu
                        id="long-menu"

                        MenuListProps={{
                            'aria-labelledby': 'long-button',
                        }}
                        anchorEl={anchorEl1}
                        open={open1}
                        className='locationPurpos_menu'
                        onClose={handleClose1}
                        slotProps={{
                            paper: {
                                style: {
                                    // maxHeight: ITEM_HEIGHT * 4.5,
                                    width: '100px',
                                    padding: "0"
                                },
                            },
                        }}
                    >
                        <MenuItem style={{ paddingY: "0" }} onClick={handleClose1}>
                            <div className='icons_back me-2'><svg xmlns="http://www.w3.org/2000/svg" width="12" height="9" viewBox="0 0 12 9" fill="none">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M1.28287 0.118514C1.03764 -0.0722122 0.684239 -0.0280335 0.493513 0.217181C0.302787 0.462402 0.346966 0.815807 0.592186 1.00653L1.87341 2.00305C1.3787 2.55676 0.954738 3.19526 0.610428 3.87901L0.598233 3.90314C0.546866 4.00439 0.480548 4.13517 0.450882 4.29644C0.427285 4.42469 0.427285 4.57533 0.450882 4.70352C0.480548 4.86479 0.546866 4.99557 0.598233 5.09688L0.610428 5.12101C1.7015 7.28774 3.5924 8.99998 6.00003 8.99998C7.25507 8.99998 8.36971 8.53469 9.30385 7.78229L10.7171 8.88147C10.9624 9.07221 11.3157 9.02805 11.5065 8.7828C11.6972 8.53761 11.653 8.1842 11.4078 7.99345L1.28287 0.118514ZM8.38557 7.06803L7.37437 6.28155C6.99424 6.57529 6.51752 6.75 6.00003 6.75C4.75737 6.75 3.75002 5.74262 3.75002 4.50001C3.75002 4.18237 3.81583 3.88014 3.93456 3.60615L2.76261 2.69464C2.32495 3.17235 1.93835 3.74328 1.61522 4.38498C1.59907 4.41704 1.58711 4.44083 1.57702 4.46153C1.56814 4.47976 1.56261 4.49174 1.55901 4.50001C1.56261 4.50828 1.56814 4.52026 1.57702 4.53848C1.58711 4.55918 1.59907 4.58292 1.61522 4.61504C2.60232 6.57529 4.18156 7.87499 6.00003 7.87499C6.86138 7.87499 7.66907 7.58334 8.38557 7.06803ZM4.88549 4.34577C4.87857 4.39617 4.87503 4.4477 4.87503 4.50001C4.87503 5.12134 5.37869 5.625 6.00003 5.625C6.14999 5.625 6.29314 5.59564 6.42398 5.54237L4.88549 4.34577Z" fill="#646C6D" />
                                <path d="M5.41016 2.32824L8.25015 4.53717C8.25037 4.5248 8.25048 4.51237 8.25048 4.49999C8.25048 3.25735 7.24311 2.25 6.0005 2.25C5.7962 2.25 5.59826 2.27722 5.41016 2.32824Z" fill="#646C6D" />
                                <path d="M10.3851 4.61502C10.1905 5.00134 9.97297 5.36207 9.73509 5.6922L10.6226 6.38249C10.9102 5.98813 11.1668 5.56406 11.3899 5.12098L11.4021 5.09685C11.4535 4.99555 11.5198 4.86477 11.5494 4.7035C11.573 4.5753 11.573 4.42467 11.5494 4.29642C11.5198 4.13515 11.4535 4.00437 11.4021 3.90312L11.3899 3.87899C10.2988 1.71226 8.40793 0 6.00033 0C5.03548 0 4.15368 0.274965 3.37305 0.743953L4.34572 1.50048C4.86532 1.25706 5.42068 1.12499 6.00033 1.12499C7.81877 1.12499 9.39798 2.4247 10.3851 4.38496C10.4013 4.41702 10.4132 4.44081 10.4233 4.46151C10.4322 4.47974 10.4377 4.49172 10.4413 4.49999C10.4377 4.50825 10.4322 4.52024 10.4233 4.53846C10.4132 4.55916 10.4013 4.5829 10.3851 4.61502Z" fill="#646C6D" />
                            </svg></div>  Hide
                        </MenuItem>
                        <MenuItem style={{ paddingY: "0" }} onClick={() => {
                            handleClose1()
                            deleteProperty(property_ids)
                        }}>
                            <div className='icons_back me-2'><svg xmlns="http://www.w3.org/2000/svg" width="12" height="14" viewBox="0 0 12 14" fill="none">
                                <path d="M4.63672 6.375V10.125" stroke="#646C6D" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M7.36426 6.375V10.125" stroke="#646C6D" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M0.545898 3.875H11.455" stroke="#646C6D" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M1.90918 3.875H6.00009H10.091V10.75C10.091 11.7856 9.17525 12.625 8.04554 12.625H3.95463C2.82496 12.625 1.90918 11.7856 1.90918 10.75V3.875Z" stroke="#646C6D" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M3.95508 2.625C3.95508 1.93464 4.5656 1.375 5.31871 1.375H6.68235C7.43549 1.375 8.04599 1.93464 8.04599 2.625V3.875H3.95508V2.625Z" stroke="#646C6D" stroke-linecap="round" stroke-linejoin="round" />
                            </svg></div>  Delete
                        </MenuItem>

                    </Menu>
                </div>
                <Stack spacing={2}>
                    <Pagination color='primary' onChange={handlePageChange} count={paginationData?.total_pages} />

                </Stack>
            </div>
        </div>
    )
}

export default MyListing