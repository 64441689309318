import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useAuth } from '../../Context/ContextProvider'

function NeighborhoodProperties() {
  const {neighbouringProperty,serchProperty,data,setData}=useAuth()
  const [propertyList,setPropertyList]=useState([])
  const navigate=useNavigate()
  useEffect(()=>{
    const fetchData = async () => {
      try {
        let result = await neighbouringProperty();
        if (result?.success) {
          const propertyTypes = result.data.data.properties;
          setPropertyList(propertyTypes)
        }
      } catch (error) {
        console.error("An error occurred while fetching data:", error);
      }
    }
    fetchData()
  },[])
  return (
    <div className='neighborhood_properties row container-fluid main_banner_home mt-5'>
      <h1>Neighborhood Properties</h1>
      {propertyList?.map((item)=>{
        return(
          <div className='col-lg-4 col-md-6 col-12 my-2'>
          <div className='main_card'>
            <div className='img'>
              <img src='./image/rectangle-17@2x.png' alt='...' />
            </div>
            <div className='content_neignor'>
              <h1>{item?.total_properties}</h1>
              <h5>{item?.city}</h5>
              <p style={{cursor:"pointer"}} onClick={async()=>{
                let result = await serchProperty({city_code:item?.city_code})
                setData({...data,city_code:item?.city_code})
                if(result?.success){
                  navigate(`/Listingproperty/Sell`)
                }
              }} className='link'>Explore Properties <svg xmlns="http://www.w3.org/2000/svg" width="12" height="11" viewBox="0 0 12 11" fill="none">
                <path d="M0.732666 10L10.7327 1M10.7327 1H1.73267M10.7327 1V9.1" stroke="#1877F2" stroke-linecap="round" stroke-linejoin="round" />
              </svg></p>
            </div>
          </div>
        </div>
        )
      })}
     
    </div>
  )
}

export default NeighborhoodProperties
