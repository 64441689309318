import React, { useEffect } from 'react';

import img from '../../Images/AgentProfile/Group 1000014158.png';
import flag from '../../Images/listing/pak.png';

// import img2 from '../../src/Images/Rectangle 1732.png';
// import img3 from '../../src/Images/Rectangle 1733.png';
// import img4 from '../../src/Images/Rectangle 1734.png';
// import img5 from '../../src/Images/Rectangle 1735.png';
// import img6 from '../../src/Images/Rectangle 1736.png';
// import img7 from '../../src/Images/Rectangle 1737.png';
// import img8 from '../../src/Images/Rectangle 1738.png';
// import img9 from '../../src/Images/Rectangle 1739.png';
// import img10 from '../../src/Images/Rectangle 1740 (1).png';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { useState } from 'react';
import { IoMdArrowDropdown } from 'react-icons/io';
import { FaPhoneAlt } from 'react-icons/fa';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    boxShadow: 24,
};

function Card({setOpen,open,profileData}) {
    const [entry, setEntry] = useState("");
 
    const [error, setError] = useState(false);
    const [checkbox, setCheckbox] = useState(false);
    const [radio, setRadio] = useState('Buyer/Tenant');
    const [activeDiv, setActiveDiv] = useState('div1');
    const [activeImg, setActiveImg] = useState();
    const [currentDiv, setCurrentDiv] = useState('original')
    const [data, setData] = useState({
        name: '',
        email: '',
        contact: '',
        message: '',
        other: '',
    });

    // const images = [img2, img3, img4, img5, img6, img7, img8, img9, img10];

    const handleSubmit = (e) => {
        e.preventDefault();
        const { name, email, contact, message, other } = data;
        if (!name || !email || !contact || !message || (radio === 'Other' && !other)) {
            setError(true);
        } else {
            setError(false);
            console.log(data);
            setData({
                name: '',
                email: '',
                contact: '',
                message: '',
                other: '',
                radio: ''
            });
            setCheckbox(false);
            setRadio('Buyer/Tenant');
            setActiveImg()
        }
    };

    const handleRadioChange = (e) => {
        const value = e.target.value;
        setRadio(value);
        setEntry(value);
    };

    const handleCheckbox = (e) => {
        setCheckbox(e.target.checked);
    };

    const handleClick = (div) => {
        setActiveDiv(div);
    };

    const handleImageClick = (img) => {
        setActiveImg(img);
    };

    const handleChange = () => {
        setCurrentDiv('new')
    }

    const handleDefault = () => {
        setCurrentDiv('original')
    }
    useEffect(()=>{
        if(profileData?.images?.length){
            setActiveImg(profileData?.images?.length?profileData?.images[0]?.url:img)
        }
    },[profileData])
    return (
        <div>
       
            <Modal open={open} onClose={() => setOpen(false)} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" >
                <Box sx={style}>
                    <div style={{ height: "560px", borderRadius: "5px", backgroundColor: "white", padding: "12px 0 16px 0" }}>
                        <div className="head-box px-4">
                            <div className="heading">Gallery & Map</div>
                            <button onClick={() => setOpen(false)} className='close-btn' ><i className="fa-solid fa-xmark"></i></button>
                        </div>
                        <hr className='mt-2 mb-0' />
                        <div className="row m-0 p-0">
                            <div className="col-6 ps-4 pt-2">
                                <div className="gallery-img-card">
                                    <div className="row m-0 "  >
                                        <div className="col-4 p-0">
                                            <div className={`tab ${activeDiv === 'div1' ? 'active' : 'dis-active'} text-center`} onClick={() => { handleClick('div1'); handleDefault(); }} id='div1'>  Gallery ({profileData?.images?.length}) </div>
                                        </div>
                                        <div className="col-3 p-0">
                                            <div className={`tab ${activeDiv === 'div2' ? 'active' : 'dis-active'} text-center`} onClick={() => { handleClick('div2'); handleChange(); }} id='div2'> Map </div>
                                        </div>
                                        <div className="col-5 p-0" style={{ borderBottom: "1.5px solid #C7C8C9" }} ></div>
                                    </div>
                                    {currentDiv === 'original' ? (
                                        <>
                                            <div className="row m-0 d-flex justify-content-center">
                                                <div className="col-10 p-0 d-flex justify-content-center">
                                                    <div className="img-box my-4">
                                                        <img className="w-100 h-100" src={activeImg} alt="Main" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="main-img-box">
                                                <div className="img-tab d-flex">
                                                    {profileData?.images?.map((img) => (
                                                        <div style={{width:"60px",height:"80px",marginRight:"1rem"}} className='img-tab' onClick={() => handleImageClick(img?.url)} >
                                                            <img style={{width:"100%",height:"100%",objectFit:"cover",borderRadius:"5px"}} src={img?.url} alt='...' />
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </>

                                    ) : (
                                        <div className="row d-flex justify-content-center m-0">
                                            <div className="col-8 py-4 px-0"></div>
                                            <div className="map-box">
                                            <iframe title="map" src={`https://maps.google.com/maps?q=${profileData?.location?.latitude},${profileData?.location?.longitude}&z=15&output=embed`} width="100%" height="300" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className="col-6 pe-4 pt-2">
                                <div className="gallery-input-card p-0 m-0">
                                    <div className="price">PKR3.85 Crore</div>
                                    <button className='call-btn mt-2 mb-3'><FaPhoneAlt style={{ marginRight: "8px", fontSize: "10px" }} /> Call</button>
                                    <div className="company d-flex">
                                        <img src={img} alt="" />
                                        <div className="details mx-2">
                                            <div className="name">Al Karam Property</div>
                                            <div className="owner">Ehsaan Elahi</div>
                                        </div>
                                    </div>
                                    <hr className='mb-0' />
                                    <div className="input-box px-2">
                                        <div className='input-label'>Name</div>
                                        <input type="text"
                                            className={`form-control input ${error && !data.name ? "is-invalid" : ""}`} placeholder="Name" value={data.name}
                                            onChange={(e) => setData({ ...data, name: e.target.value })} />
                                        <p className='alert-msg px-2 my-1'><i>{error && !data.name ? "Name is required" : ''}</i></p>
                                        <div className='input-label'>Email</div>
                                        <input type="email" className={`form-control input ${error && !data.email ? "is-invalid" : ""}`} placeholder="Email"
                                            value={data.email} onChange={(e) => setData({ ...data, email: e.target.value })} />
                                        <p className='alert-msg px-2 my-1'><i>{error && !data.email ? "Email is required" : ''}</i></p>
                                        <div className='input-label'>Contact</div>
                                        <div className="input-group flex-nowrap d-flex align-items-center">
                                            <span className="input-group-text number" id="addon-wrapping"><img style={{ marginRight: "8px" }} src={flag} alt="..." />
                                                +92 <IoMdArrowDropdown /></span>
                                            <input type="number" className={`form-control input ${error && !data.contact ? "is-invalid" : ""}`} placeholder="e.g 3324751060"
                                                value={data.contact} onChange={(e) => setData({ ...data, contact: e.target.value })} />
                                        </div>
                                        <p className='alert-msg px-2 my-1'><i>{error && !data.contact ? "Contact number is required" : ''}</i></p>
                                        <div className='input-label'>Message</div>
                                        <textarea type="text" className={`form-control input message-input ${error && !data.message ? "is-invalid" : ""}`} placeholder="Message"
                                            value={data.message} onChange={(e) => setData({ ...data, message: e.target.value })} />
                                        <p className='alert-msg px-2 my-1'><i>{error && !data.message ? "Message is required" : ''}</i></p>
                                        <div className="option d-flex justify-content-around pe-4 ps-0 my-2">
                                            <div className="text">I am a:</div>
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input" type="radio" name="radioGroup" id="exampleRadios1" value="Buyer/Tenant" checked={radio === 'Buyer/Tenant'}
                                                    onChange={(e) => { handleRadioChange(e); setData({ ...data, radio: e.target.value }); }} />
                                                <label className="form-check-label" htmlFor="exampleRadios1">
                                                    Buyer/Tenant
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" name="radioGroup" id="exampleRadios2" value="Agent" checked={radio === 'Agent'}
                                                    onChange={(e) => { handleRadioChange(e); setData({ ...data, radio: e.target.value }); }} />
                                                <label className="form-check-label" htmlFor="exampleRadios2">
                                                    Agent
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" name="radioGroup" id="exampleRadios3" value="Other" checked={radio === 'Other'}
                                                    onChange={(e) => { handleRadioChange(e); setData({ ...data, radio: e.target.value }); }} />
                                                <label className="form-check-label" htmlFor="exampleRadios3">
                                                    Other
                                                </label>
                                            </div>
                                        </div>
                                        <p className='alert-msg px-2 my-1'><i>{error && radio === '' ? "Please select an option" : ''}</i></p>
                                        <input
                                            type="text" className={`form-control input ${error && radio === 'Other' && !data.other ? "is-invalid" : ""}`}
                                            disabled={radio !== 'Other'} placeholder="Other" value={data.other} onChange={(e) => setData({ ...data, other: e.target.value })} />
                                        <p className='alert-msg px-2 my-1'><i>{error && radio === 'Other' && !data.other ? "Other info is required" : ''}</i></p>
                                        <div className="form-check last-check my-3">
                                            <input className="form-check-input" type="checkbox" id="flexCheckDefault" checked={checkbox}
                                                onChange={handleCheckbox} />
                                            <label className="form-check-label" htmlFor="flexCheckDefault">
                                                Keep me informed about similar properties
                                            </label>
                                        </div>
                                    </div>
                                    <button className="send-btn my-1" onClick={handleSubmit} disabled={!checkbox} >
                                        Send Email
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Box>
            </Modal>
        </div>
    );
}

export default Card;