import { Close } from '@mui/icons-material'
import React from 'react'
import ModalFeature from './ModalFeature';
import { bathrooms, bedrooms } from './dummyData';
function FeatureAmenities({data,setData}) {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleRemove = (category_id, amenitiy_id) => {
        // Iterate through the amenities array and filter the data based on amenitiy_id
        let updatedAmenities = data?.amenities?.map((item) => {
          if (item.amenity_category_id === category_id) {
            return {
              ...item,
              data: item.data.filter((items) => items.amenity_id !== amenitiy_id)
            };
          }
          return item;
        }).filter(item => item.data.length > 0); // Remove categories with empty data
      
        // Update the state with the new array
        setData({ ...data, amenities: updatedAmenities });
      };
      
    return (
        <div className='locationPurpos feature_amenities my-5'>
          
          <div className='d-flex align-items-center'>
          <svg xmlns="http://www.w3.org/2000/svg" width="29" height="31" viewBox="0 0 29 31" fill="none">
  <path d="M6.50077 30.6787C6.46811 30.6828 6.43546 30.6828 6.4028 30.6787C6.19597 30.6379 6.03269 30.4773 5.98778 30.2705L5.08972 25.8019L0.627807 26.7082C0.419617 26.7504 0.207358 26.6633 0.0889563 26.4878C-0.0294243 26.3122 -0.0294244 26.0822 0.0875956 25.9067L5.38623 17.8948C5.46243 17.7751 5.58354 17.6921 5.72233 17.6649C5.86112 17.6363 6.00535 17.6662 6.121 17.7465C6.23803 17.8268 6.31695 17.9506 6.34008 18.0908C6.36457 18.2296 6.33055 18.3725 6.24619 18.4868L1.64424 25.4264L5.38474 24.6671L5.3861 24.6658C5.66777 24.61 5.94265 24.7923 6.00249 25.074L6.76177 28.8187L11.3773 21.8518C11.4494 21.728 11.5692 21.6396 11.7093 21.6069C11.8495 21.5743 11.9964 21.6001 12.1162 21.679C12.2359 21.7579 12.3176 21.8831 12.3434 22.0247C12.3679 22.1662 12.3339 22.3118 12.2482 22.426L6.93595 30.4379C6.8407 30.5862 6.6763 30.6773 6.50077 30.6787Z" fill="#B7B7B7"/>
  <path d="M22.5071 30.6786C22.3316 30.6786 22.1683 30.5915 22.0717 30.4445L16.7595 22.4327C16.5989 22.1932 16.6642 21.868 16.9037 21.7074C17.1445 21.5469 17.4698 21.6108 17.6303 21.8517L22.2458 28.8185L23.0051 25.0738C23.065 24.7922 23.3399 24.6098 23.6215 24.6656L27.362 25.4249L22.7479 18.4866C22.6622 18.3709 22.6268 18.2253 22.6526 18.0838C22.6771 17.9437 22.7588 17.8185 22.8785 17.7396C22.9982 17.6593 23.1466 17.6334 23.2854 17.6661C23.4255 17.6988 23.5453 17.7872 23.6187 17.9124L28.9132 25.9243C29.0302 26.0998 29.0289 26.3298 28.9118 26.5053C28.7935 26.6808 28.5798 26.7679 28.373 26.7244L23.9003 25.8018L23.0022 30.261C22.9614 30.4719 22.7981 30.6365 22.5872 30.6787C22.56 30.6814 22.5343 30.6813 22.5071 30.6786Z" fill="#B7B7B7"/>
  <path d="M14.4953 20.169C11.3997 20.169 8.54076 18.5184 6.99238 15.8377C5.44525 13.1571 5.44525 9.85456 6.99238 7.17406C8.54086 4.49482 11.3996 2.84277 14.4953 2.84277C14.6627 2.84277 14.8437 2.84277 14.9906 2.86318C15.2791 2.88768 15.4927 3.14077 15.4696 3.42924C15.4451 3.71771 15.192 3.9327 14.9035 3.90821C14.7675 3.8946 14.6314 3.8878 14.4953 3.8878C12.0229 3.88644 9.70422 5.08387 8.27428 7.10046C6.84433 9.11705 6.48087 11.701 7.3 14.0332C8.1178 16.3654 10.0173 18.1561 12.3931 18.8365C14.7703 19.5168 17.3284 19.0025 19.2579 17.4567C21.186 15.9109 22.246 13.5271 22.1004 11.0587C21.9535 8.59175 20.62 6.34807 18.5219 5.0404C18.4049 4.96692 18.3205 4.84989 18.2892 4.71519C18.2579 4.58048 18.2824 4.4376 18.3545 4.31923C18.5083 4.0743 18.8308 3.99946 19.0757 4.15322C21.2216 5.48807 22.6762 7.69516 23.0587 10.1921C23.4424 12.6904 22.7158 15.2309 21.0693 17.1495C19.4242 19.0668 17.0226 20.1703 14.4957 20.169L14.4953 20.169Z" fill="#B7B7B7"/>
  <path d="M14.4954 22.9908C11.4447 22.9908 8.5206 21.7784 6.36367 19.6216C4.2083 17.4636 2.99725 14.5379 3 11.4872C3.00273 8.4378 4.21649 5.51239 6.37593 3.35824C8.53401 1.20423 11.461 -0.00406533 14.5115 1.02769e-05C17.5622 0.00409215 20.4848 1.22057 22.6376 3.38137C24.7903 5.54217 25.9972 8.46891 25.9904 11.5197C25.9836 14.5636 24.7698 17.4823 22.6145 19.6322C20.4605 21.7822 17.5391 22.9905 14.495 22.9905L14.4954 22.9908ZM14.4954 1.0696C11.7236 1.0696 9.06611 2.17043 7.10529 4.12985C5.14586 6.09064 4.04503 8.74817 4.04503 11.5199C4.04503 14.2917 5.14586 16.9492 7.10529 18.91C9.06607 20.8694 11.7236 21.9703 14.4954 21.9703C17.2671 21.9703 19.9246 20.8694 21.8854 18.91C23.8449 16.9492 24.9457 14.2917 24.9457 11.5199C24.9457 8.74817 23.8449 6.09067 21.8854 4.12985C19.9247 2.17043 17.2671 1.0696 14.4954 1.0696Z" fill="#B7B7B7"/>
  <path d="M14.2261 6.95898L15.527 9.60023L18.4473 10.0268L16.3354 12.0808L16.8357 14.988L14.2261 13.6134L11.6165 14.988L12.1168 12.0808L10.0049 10.0268L12.9252 9.60023L14.2261 6.95898Z" fill="#1C9093"/>
  <path d="M16.8375 15.9995C16.6716 15.9995 16.5083 15.9574 16.3661 15.8784L14.2278 14.7592L12.0896 15.8784C11.7499 16.0574 11.3364 16.0285 11.0257 15.8046C10.7176 15.5755 10.5596 15.1937 10.6202 14.8118L11.031 12.4314L9.30614 10.7487C9.02964 10.4801 8.92958 10.0772 9.05071 9.7111C9.16921 9.34506 9.48521 9.07646 9.86701 9.02119L12.2528 8.65779L13.3246 6.49322C13.4931 6.14826 13.8433 5.92969 14.2278 5.92969C14.6122 5.92969 14.9624 6.14826 15.131 6.49322L16.2027 8.65779L18.5885 9.00276C18.9704 9.05806 19.2864 9.32402 19.4048 9.69004C19.526 10.0561 19.4259 10.459 19.1494 10.7276L17.4246 12.4129L17.8354 14.7934C17.8959 15.1726 17.7379 15.5544 17.4298 15.7836C17.2613 15.9179 17.0534 15.9916 16.8375 15.9995ZM14.2279 12.6289C14.3911 12.6289 14.5544 12.6684 14.6992 12.7421L15.4945 13.1608L15.3391 12.2786C15.2759 11.9415 15.3839 11.594 15.6288 11.3543L16.3029 10.7328L15.4208 10.5985C15.0916 10.5538 14.8046 10.3457 14.6597 10.0455L14.2279 9.24501L13.8302 10.0482V10.0455C13.6854 10.3457 13.3984 10.5538 13.0692 10.5985L12.187 10.7328L12.8612 11.3543H12.8585C13.0955 11.5887 13.2035 11.9231 13.1482 12.2496L12.9928 13.1318L13.7565 12.7026C13.904 12.6341 14.0646 12.5972 14.2279 12.6025L14.2279 12.6289Z" fill="#1C9093"/>
</svg>
<h4>Feature and Amenities</h4>
            </div> 
            <div className="row">
                <div className="col-12 d-flex">
                    <div className='inp_login'>
                        <label className="logo_label my-2">Bedrooms</label>
                        <div className='d-flex flex-wrap'>
                            {bedrooms?.map((item)=>{
                                return(
                                    <button onClick={()=>setData({...data,bedrooms:item})} className={`${item===data?.bedrooms&&"active_btn"} me-2 mb-2`}>{item}</button>
                                )
                            })}
                      

                        </div>
                    </div>
                    <div className='inp_login ms-3'>
                        <label className="logo_label my-2">Bathrooms</label>
                        <div className='d-flex flex-wrap'>
                            {/* <button className='me-2 active_btn'>Studio</button> */}
                            {bathrooms?.map((item)=>{
                                return(
                                    <button onClick={()=>setData({...data,bathrooms:item})} className={`${item===data?.bathrooms&&"active_btn"} me-2 mb-2`}>{item}</button>
                                )
                            })}

                        </div>
                    </div>
      

                </div>
                    <div className='col-lg-12 col-md-6 col-12'>
                        <div className='d-flex align-items-start inp_login'>
                            <div className='inp_login'>
                            <label className="logo_label my-2">Feature and Amenities</label>
                            <p>Add additional features e.g parking spaces waste,disposal,internet etc.</p>
                            </div>
                            <button onClick={handleOpen} className='me-2 active_btn mt-4 mx-2'>Add +</button>
                        </div>
                    </div>
                    <div className='px-2'>
                        {data?.amenities?.map((item)=>{
                            return (
                         <div className='feature_box'>
                                   <p>{item?.category_name}</p>
                            <div className='feature mb-3'>
                         
                                <div className='d-flex'>
                                {item?.data?.map((items)=>{
                                    return(
                                        <span className='me-2 d-flex' style={{width:"max-content"}}>{items?.lable} {items?.type!=="checkbox"&&":"} {items?.selected_options[0]} <button style={{padding:"0",border:"none",marginLeft:"5px"}} onClick={()=>handleRemove(item?.amenity_category_id,items.amenity_id)}><Close style={{fontSize:"12px",cursor:"pointer"}}/></button></span>
                                    )
                                })}

                                </div>
                            </div>
                         </div>

                            )
                        })}
            

                    </div>
            </div>
            <ModalFeature open={open} handleClose={handleClose} data={data} setData={setData}/>
        </div>
    )
}

export default FeatureAmenities 
