import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { RxCross2 } from 'react-icons/rx';
import img from '../../../src/Images/Ellipse 374.png';
import mobile from '../../../src/Images/Vector (8).png';
import telephone from '../../../src/Images/Group 1000014111.png';
import copy from '../../../src/Images/Group 1000014110.png';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../Context/ContextProvider';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  boxShadow: 24,
};

function Call({open,setOpen,land_line}) {
  console.log(land_line);
  
 const {whatsappImpretion}=useAuth()
  const handleClose = () => setOpen(false);
const parms=useParams()
  const ToCopyData = (e) => {
    const number = e.currentTarget.previousSibling.textContent.trim();
    navigator.clipboard.writeText(number);
    whatsappImpretion("calls",parms?.id)
    console.log('Number copied:', number);
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="call-card">
            <div className="head">
              <div className="heading">Call the Agent</div>
              <div className=" call-cancel-btn" onClick={handleClose}>
                <RxCross2 />
              </div>
            </div>
            <div className="hr"></div>
            <div className="call-card-dealer-details">
              <div className="img-box flex-class">
                <img src={img} alt="..." />
              </div>
              <div className="dealer-company-name flex-class mt-3 mb-1">Al Karam Property</div>
              <div className="dealer-name flex-class">Ehsaan Elahi</div>
              <div className="dealer-id flex-class">Agent ID: 50402718</div>
            </div>
            <div className="hr"></div>
            <div className="contact-box">
              <div className="row m-0 p-1">
                <div className="col-6 contact-col">
                  <div className="mobile-box">
                    <div className="img-box flex-class align-items-center">
                      <img src={mobile} alt="Mobile icon" />
                      <span className="mx-2">Mobile</span>
                    </div>
                    <div className="mobile-number flex-class align-items-center">
                      <span className="num">{land_line?.phone_number}</span>
                      <img className="mx-2" onClick={ToCopyData} src={copy} alt="Copy icon" />
                    </div>
                  </div>
                </div>
                <div className="col-6 contact-col-1">
                  <div className="mobile-box-1">
                    <div className="img-box flex-class align-items-center">
                      <img src={telephone} alt="Telephone icon" />
                      <span className="mx-2">Mobile</span>
                    </div>
                    <div className="mobile-number flex-class align-items-center mt-3">
                      <span className="num">{land_line?.landline}</span>
                      <img className="mx-2" onClick={ToCopyData} src={copy} alt="Copy icon" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="hr"></div>
            </div>
            <div className="last-details mt-2 pt-1 mb-3 pb-1">
              <div className="note">
                Please quote property reference <br />
                ID - <span>ID50402718</span> <br />
                when calling us.
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default Call;