import React, { useState } from 'react'
import { AiOutlineTwitter } from 'react-icons/ai'
import { FaFacebookF, FaInstagram, FaLinkedinIn, FaPhone, FaWhatsapp } from 'react-icons/fa'
import { FaLocationDot } from 'react-icons/fa6'
import { GoArrowRight } from 'react-icons/go'
import { GrMail } from 'react-icons/gr'
import { IoBriefcaseSharp } from 'react-icons/io5'
import logo from '../../Images/logo.png'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../Context/ContextProvider'

function Footer() {
     const navigate=useNavigate()
     const {newslater}=useAuth()
     const [value,setValue]=useState()
     const [errors,setError]=useState()
     const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };
    return (
        <div className='footer mt-5'>
            <div className='d-flex justify-content-between'>
                <div className='footer_logo'>
                    <img src={logo} alt='...' />
                </div>
                <div className='social_link'>
                    <span>Follow Us</span>
                    <a href='...'><FaFacebookF /> </a>
                    <a href='...'><AiOutlineTwitter /> </a>
                    <a href='...'><FaInstagram /> </a>
                    <a href='...'><FaLinkedinIn /> </a>
                </div>
            </div>
            <hr style={{ borderColor: "gray", margin: "1rem" }} />
            <div className='row' >
                <div className='col-lg-4 col-md-6 col-12'>
                    <h6>Subscribe</h6>
                    <div style={{position:"relative"}} className='footer_inp'>
                        <input onChange={(e)=>setValue(e.target.value)} type='email' placeholder='Your e-mail' />
                        <button onClick={()=>{
                            if(!value){
                                setError("Please enter email")
                              

                            }
                            else if(!isValidEmail(value)){
                                setError("Please enter a valid email")
                            }
                            else{
                                newslater(value)
                                setValue("")
                            }
                        }}>Send <GoArrowRight className='mx-2' /></button>
                    {errors && <span style={{top:"150%"}} className='text_error'>{errors}</span>}
                    </div>
                    <hr />
                    <p>Subscribe to our newsletter to receive our weekly feed.</p>
                </div>
            <div className='col-lg-3 col-md-6 col-12'>
                <h5>Contact Us</h5>
                <ul>
                    <li><FaWhatsapp className='me-2'/> 0305-1115551</li>
                    <li><FaPhone className='me-2'/> 0305-1115551</li>
                    <li><GrMail className='me-2'/> social@pakistanproperty.com</li>
                    <li><IoBriefcaseSharp className='me-2'/> Mon-Sat 9:00 am to 10:00pm</li>
                    <li><FaLocationDot className='me-2'/>  Building No. 21, 3rd Floor, Block-CCA,
                        DHA Phase 8 - Ex Park View Phase-8
                        Lahore, 54000.</li>
                </ul>
            </div>
            <div className='col-lg-3 col-md-6 col-12'>
                <h5>Our Services</h5>
                <ul>
                    <li title='Comming Soon' style={{cursor:"not-allowed"}}>High Rise</li>
                    <li title='Comming Soon' style={{cursor:"not-allowed"}}>Housing Society</li>
                    <li title='Comming Soon' style={{cursor:"not-allowed"}}>Lease</li>
                    <li title='Comming Soon' style={{cursor:"not-allowed"}}>Property Prices</li>
                    <li title='Comming Soon' style={{cursor:"not-allowed"}}>Instant Valuation</li>
                    <li title='Comming Soon' style={{cursor:"not-allowed"}}>Real Estate Agency</li>
                    <li style={{cursor:"pointer"}}>Add Property</li>
                    <li style={{cursor:"pointer"}} onClick={()=>navigate("/contact")}>Contact Us</li>
                </ul>
            </div>
            <div className='col-lg-2 col-md-6 col-12'>
                <h5>Quick Links</h5>
                <ul>
                    <li style={{cursor:"pointer"}} onClick={()=>{
                        navigate("/about-us")
                    }}>About</li>
                    <li style={{cursor:"pointer"}} onClick={()=>{
                        navigate("/News")
                    }}>News</li>
                    {/* <li style={{cursor:"pointer"}} onClick={()=>{
                        // navigate("/about-us")
                    }}>FAQ’s</li> */}
                    <li style={{cursor:"pointer"}} onClick={()=>{
                        navigate("/Blogs")
                    }}>Blogs</li>
                    <li style={{cursor:"pointer"}} onClick={()=>{
                        navigate("/privacy-policy")
                    }}>Privacy Policy</li>
                    <li style={{cursor:"pointer"}} onClick={()=>{
                        navigate("/term-and-condition")
                    }}>Terms & Conditions</li>
                </ul>
            </div>
            </div>
            <hr style={{ borderColor: "gray", margin: "1rem" }} />
            <p className='copy_right'>Copyright © 2024.</p>
        </div>
    )
}

export default Footer
