import React, { useState } from 'react'
import img from '../../Images/Agents/Frame 1300193233.jpg'
import { CiSearch } from 'react-icons/ci'
import { FaChevronDown } from 'react-icons/fa'
function Banner() {
  const [type_pan, setTypePan] = useState(0)
  // eslint-disable-next-line
  const [moreOption, setMoreOption] = useState(false)
  const [propertyType, setProperty] = useState(false)
  return (
    <div className='container-fluid px-4'>

      <div className='banner_section'>
        <img src={img} id="bgImage" alt='banner-image' />
        <div class="content new-content">
          <div className='p-0 m-0'>

            <h1 className='mt-3 mb-2'>Find Top Real Estate Agents in Pakistan</h1>
            <p className='mt-4 pt-1 mb-3'>Search the real estate agents in Pakistan dealing in properties for sale and rent.</p>
          </div>
          <div className='tab_banner new-tab-banner p-0 m-0'>
            {/* <div>

              <button className='tb_btn' style={{ background: `${buy ? "white" : ""}` }} onClick={() => setBuy(true)}>Buy</button>
              <button className='tb_btn' style={{ background: `${!buy ? "white" : ""}` }} onClick={() => setBuy(false)}>Rent</button>
            </div> */}
            <div className='d-flex justify-content-between tab_section new-tab-section mb-4'>
              <div className='d-flex'>
                <div className='select_banner'>
                  <select>
                    <option>City</option>
                  </select>
                </div>
                <div className='select_banner'>
                  <select className='property-tab'>
                    <option>Property Type</option>
                  </select>
                </div>
                <div className='select_banner'>
                  <CiSearch className='text-dark' />
                  <input type='search' placeholder='Search by location' />
                </div>
                <div className='select_banner'>
                  <CiSearch className='text-dark' />
                  <input type='search' placeholder='Search by Company' />
                </div>


              </div>
              <div className='select_banner_btn'>
                {/* <button style={{ borderRight: "1px solid lightgray" }} className='px-2' onClick={() => setMoreOption(!moreOption)}>More Option</button> */}
                <button>Search</button>
              </div>
            </div>
          </div>
          {moreOption && <>
            <div style={{ width: "90%" }} className='mor_option d-flex justify-content-between my-2'>
              <button onClick={() => setProperty(!propertyType)} className='more_btn'>Property Type<FaChevronDown /> </button>
              <button className='more_btn'>Price (PKR)<FaChevronDown /></button>
              <button className='more_btn'>Area (Marla)<FaChevronDown /></button>
              <button className='more_btn'>Room<FaChevronDown /></button>
            </div>
            {propertyType && <div style={{ width: "90%", background: "white", borderRadius: "5px", padding: "1rem 0" }}>
              <div className='tab_pan d-flex justify-content-center'>
                <h6 className={`${type_pan === 0 && "active_tab"}`} onClick={() => setTypePan(0)}>Home</h6>
                <h6 className={`${type_pan === 1 && "active_tab"}`} onClick={() => setTypePan(1)}>plots</h6>
                <h6 className={`${type_pan === 2 && "active_tab"}`} onClick={() => setTypePan(2)}>Commercial</h6>
              </div>
              {type_pan === 0 && <div className='option_tabs'>
                <p>House</p>
                <p>apartment</p>
                <p>Upper Portion</p>
                <p>Lower Portion</p>
                <p>Farm House</p>
                <p>Room</p>
                <p>Penthouse</p>
              </div>}
              {type_pan === 1 && <div className='option_tabs'>
                <p>Residential Plot</p>
                <p>Commercial Plot</p>
                <p>Agricultural Land</p>
                <p>Industrial Land</p>
                <p>Plot File</p>
                <p>Plot Form</p>

              </div>}
              {type_pan === 2 && <div className='option_tabs'>
                <p>Office</p>
                <p>Shop</p>
                <p>Warehouse</p>
                <p>Factory</p>
                <p>Farm House</p>
                <p>Building</p>
                <p>Other</p>
              </div>}
            </div>}
          </>}
        </div>
      </div>
    </div>
  )
}

export default Banner
