import React from 'react'
import img from'../../Images/image 29.png'
function PropertyVlaue() {
  return (
    <>
    <div className='property-value px-4'>
    <p>Things that can affect a property's value</p>
    <div className='p-v'>
      <div className='row m-0 my-4 p-0'>
        <div className='col-lg-7'>
            <div className='property-card'>
              <p>The following factors are not included in our online valuations but can affect a home's value.</p> 
              <div className='value-text'>
                <h3>Unique features</h3>
                <p>We love them! But our tool doesn't know about your secret wine cellar or funky<br/> wallpaper. Sorry, it's not a mind-reader.</p>
                </div> 
                <div className='value-text'>
                <h3>Renovations</h3>
                <p>We can't see your amazing new kitchen ourselves (our loss), so we can't tell how much<br/> value it adds. We'd rather not guess.</p>
                </div> 
                <div className='value-text'>
                <h3>Property condition</h3>
                <p>Is your house pristine or well-loved? We won't know unless you invite us over for a cup<br/> of tea. We'll wait for our invite...<br/>
                Remember, while our online house valuations are great for providing a quick estimate,<br/> they are not a substitute for an expert's opinion.</p>
                </div> 
            </div>
        </div>
        <div className='col-lg-5 p-0'>
            <div className='value-img'>
                <img src={img} alt='...'/>
            </div>
        </div>
    </div>
    </div>
    </div>
    </>
  )
}

export default PropertyVlaue
