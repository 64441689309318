import React from 'react'
import img from '../../Images/Agents/Rectangle 1813.png'
import img1 from '../../Images/Agents/Rectangle 1813 (4).png'
import img2 from '../../Images/Agents/Rectangle 1813 (2).png'
import img3 from '../../Images/Agents/Rectangle 1813 (3).png'
import img4 from '../../Images/Agents/Frame 1300192747.png'
import img5 from '../../Images/Agents/Frame 1300192750.png'
import { IoLocationOutline } from 'react-icons/io5'

function ThirdSection() {
    return (
        <>
            <div className="conatiner-fluid px-4 mt-3">
                <div className="row my-4 pt-3">
                    <div className="col-10">
                        <div className="agents-featured">
                            Featured Agencies
                        </div>
                    </div>
                </div>
                <div className="row m-0 px-1">
                    <div className="col-xl-3 col-lg-4 col-md-6 col-8 special-col p-0">
                        <div className="card-1">
                            <div className="row">
                                <div className="col-3">
                                    <div className="card-img">
                                        <img src={img} alt="" />
                                    </div>
                                </div>
                                <div className="col-9">
                                    <div className="card-text px-2">
                                        <div className="first-heading py-0">
                                            Property View
                                        </div>
                                        <div className="location d-flex mt-2">
                                            <div className='location-icon'><IoLocationOutline /></div>
                                            <div className='location-details'>Lahore </div>
                                        </div>
                                        <div className="house-box d-flex ">
                                            <div className="box-1 d-flex">
                                                <div className="box1-img"> <img className='w-100' src={img4} alt="" /></div>
                                                <div className="box1-text">52 for Sale</div>
                                            </div>

                                            <div className="box-1 d-flex ">
                                                <div className="box1-img"> <img className='w-100' src={img5} alt="" /></div>
                                                <div className="box1-text">100 for Rent</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                     <div className="col-xl-3 col-lg-4 col-md-6 col-8 p-0 my-md-0 mt-3 mb-0">
                        <div className="card-1">
                            <div className="row">
                                <div className="col-3">
                                    <div className="card-img">
                                        <img src={img1} alt="" />
                                    </div>
                                </div>
                                <div className="col-9">
                                    <div className="card-text px-2">
                                        <div className="first-heading py-0">
                                        Madina Estate Advisor 
                                        </div>
                                        <div className="location d-flex mt-2">
                                            <div className='location-icon'><IoLocationOutline /></div>
                                            <div className='location-details'>Lahore </div>
                                        </div>
                                        <div className="house-box d-flex ">
                                            <div className="box-1 d-flex">
                                                <div className="box1-img"> <img className='w-100' src={img4} alt="" /></div>
                                                <div className="box1-text">52 for Sale</div>
                                            </div>

                                            <div className="box-1 d-flex ">
                                                <div className="box1-img"> <img className='w-100' src={img5} alt="" /></div>
                                                <div className="box1-text">100 for Rent</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-md-6 col-8 p-0 my-lg-0 mt-3 mb-0">
                        <div className="card-1">
                            <div className="row">
                                <div className="col-3">
                                    <div className="card-img">
                                        <img src={img2} alt="" />
                                    </div>
                                </div>
                                <div className="col-9">
                                    <div className="card-text px-2">
                                        <div className="first-heading py-0">
                                            Property View
                                        </div>
                                        <div className="location d-flex mt-2">
                                            <div className='location-icon'><IoLocationOutline /></div>
                                            <div className='location-details'>Lahore </div>
                                        </div>
                                        <div className="house-box d-flex ">
                                            <div className="box-1 d-flex">
                                                <div className="box1-img"> <img className='w-100' src={img4} alt="" /></div>
                                                <div className="box1-text">52 for Sale</div>
                                            </div>

                                            <div className="box-1 d-flex ">
                                                <div className="box1-img"> <img className='w-100' src={img5} alt="" /></div>
                                                <div className="box1-text">100 for Rent</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-md-6 col-8 p-0 my-xl-0 mt-3 mb-0">
                        <div className="card-1">
                            <div className="row">
                                <div className="col-3">
                                    <div className="card-img">
                                        <img src={img3} alt="" />
                                    </div>
                                </div>
                                <div className="col-9">
                                    <div className="card-text px-2">
                                        <div className="first-heading py-0">
                                            Property View
                                        </div>
                                        <div className="location d-flex mt-2">
                                            <div className='location-icon'><IoLocationOutline /></div>
                                            <div className='location-details'>Lahore </div>
                                        </div>
                                        <div className="house-box d-flex ">
                                            <div className="box-1 d-flex">
                                                <div className="box1-img"> <img className='w-100' src={img4} alt="" /></div>
                                                <div className="box1-text">52 for Sale</div>
                                            </div>

                                            <div className="box-1 d-flex ">
                                                <div className="box1-img"> <img className='w-100' src={img5} alt="" /></div>
                                                <div className="box1-text">100 for Rent</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
           
             
                    <div className="col-xl-3 col-lg-4 col-md-6 col-8 my-xl-3 mt-3 mb-0 p-0 special-col">
                        <div className="card-1">
                            <div className="row">
                                <div className="col-3">
                                    <div className="card-img">
                                        <img src={img} alt="" />
                                    </div>
                                </div>
                                <div className="col-9">
                                    <div className="card-text px-2">
                                        <div className="first-heading py-0">
                                            Property View
                                        </div>
                                        <div className="location d-flex mt-2">
                                            <div className='location-icon'><IoLocationOutline /></div>
                                            <div className='location-details'>Lahore </div>
                                        </div>
                                        <div className="house-box d-flex ">
                                            <div className="box-1 d-flex">
                                                <div className="box1-img"> <img className='w-100' src={img4} alt="" /></div>
                                                <div className="box1-text">52 for Sale</div>
                                            </div>

                                            <div className="box-1 d-flex ">
                                                <div className="box1-img"> <img className='w-100' src={img5} alt="" /></div>
                                                <div className="box1-text">100 for Rent</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                     <div className="col-xl-3 col-lg-4 col-md-6 col-8 p-0 my-3">
                        <div className="card-1">
                            <div className="row">
                                <div className="col-3">
                                    <div className="card-img">
                                        <img src={img1} alt="" />
                                    </div>
                                </div>
                                <div className="col-9">
                                    <div className="card-text px-2">
                                        <div className="first-heading py-0">
                                        Madina Estate Advisor 
                                        </div>
                                        <div className="location d-flex mt-2">
                                            <div className='location-icon'><IoLocationOutline /></div>
                                            <div className='location-details'>Lahore </div>
                                        </div>
                                        <div className="house-box d-flex ">
                                            <div className="box-1 d-flex">
                                                <div className="box1-img"> <img className='w-100' src={img4} alt="" /></div>
                                                <div className="box1-text">52 for Sale</div>
                                            </div>

                                            <div className="box-1 d-flex ">
                                                <div className="box1-img"> <img className='w-100' src={img5} alt="" /></div>
                                                <div className="box1-text">100 for Rent</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-md-6 col-8 p-0 my-xl-3 mt-0 mb-3">
                        <div className="card-1">
                            <div className="row">
                                <div className="col-3">
                                    <div className="card-img">
                                        <img src={img2} alt="" />
                                    </div>
                                </div>
                                <div className="col-9">
                                    <div className="card-text px-2">
                                        <div className="first-heading py-0">
                                            Property View
                                        </div>
                                        <div className="location d-flex mt-2">
                                            <div className='location-icon'><IoLocationOutline /></div>
                                            <div className='location-details'>Lahore </div>
                                        </div>
                                        <div className="house-box d-flex ">
                                            <div className="box-1 d-flex">
                                                <div className="box1-img"> <img className='w-100' src={img4} alt="" /></div>
                                                <div className="box1-text">52 for Sale</div>
                                            </div>

                                            <div className="box-1 d-flex ">
                                                <div className="box1-img"> <img className='w-100' src={img5} alt="" /></div>
                                                <div className="box1-text">100 for Rent</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-4 col-md-6 col-8 p-0 my-xl-3 mt-0 mb-3">
                        <div className="card-1">
                            <div className="row">
                                <div className="col-3">
                                    <div className="card-img">
                                        <img src={img3} alt="" />
                                    </div>
                                </div>
                                <div className="col-9">
                                    <div className="card-text px-2">
                                        <div className="first-heading py-0">
                                            Property View
                                        </div>
                                        <div className="location d-flex mt-2">
                                            <div className='location-icon'><IoLocationOutline /></div>
                                            <div className='location-details'>Lahore </div>
                                        </div>
                                        <div className="house-box d-flex ">
                                            <div className="box-1 d-flex">
                                                <div className="box1-img"> <img className='w-100' src={img4} alt="" /></div>
                                                <div className="box1-text">52 for Sale</div>
                                            </div>

                                            <div className="box-1 d-flex ">
                                                <div className="box1-img"> <img className='w-100' src={img5} alt="" /></div>
                                                <div className="box1-text">100 for Rent</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
             
                </div>
            </div>
        </>
    )
}

export default ThirdSection
