import React from 'react'
import { MdArrowOutward } from 'react-icons/md'

function LastSection() {
  return (
    <div className='neighborhood_properties row container-fluid main_banner_home mt-3 px-3 mx-0'>
      <div className="heading mt-3">Browse Agencies by City</div>
      <div className='col-lg-4 col-md-6 col-12 my-2'>
        <div className='main_card'>
            <div className='img'>
                <img  src='./image/rectangle-17@2x.png' alt='...'/>
            </div>
            <div className='content_neignor'> 
            <h1>261</h1>
            <h5>Lahore, Panjab</h5>
            <a href="/">View Agents <MdArrowOutward /></a>
            </div>
        </div>
      </div>
      <div className='col-lg-4 col-md-6 col-12 my-2'>
        <div className='main_card'>
            <div className='img'>
                <img src='./image/rectangle-17@2x.png' alt='...'/>
            </div>
            <div className='content_neignor'> 
            <h1>142</h1>
            <h5>Islamabad, Punjab</h5>
            <a href="/">View Agents <MdArrowOutward /></a>
            </div>
        </div>
      </div>
      <div className='col-lg-4 col-md-6 col-12 my-2 '>
        <div className='main_card' >
            <div className='img'>
                <img src='./image/rectangle-17@2x.png' alt='...'/>
            </div>
            <div className='content_neignor'> 
            <h1>2124</h1>
            <h5>Karachi, Punjab</h5>
            <a href="/">View Agents <MdArrowOutward /></a>
            </div>
        </div>
      </div>
      <div className='col-lg-4 col-md-6 col-12 mt-2'>
        <div className='main_card' >
            <div className='img'>
                <img src='./image/rectangle-17@2x.png' alt='...'/>
            </div>
            <div className='content_neignor'> 
            <h1>1745</h1>
            <h5>Multan, Punjab</h5>
            <a href="/">View Agents <MdArrowOutward /></a>
            </div>
        </div>
      </div>
      <div className='col-lg-4 col-md-6 col-12 mt-2'>
        <div className='main_card'>
            <div className='img'>
                <img src='./image/rectangle-17@2x.png' alt='...'/>
            </div>
            <div className='content_neignor'> 
            <h1>142</h1>
            <h5>Faisalabad, Punjab </h5>
            <a href="/">View Agents <MdArrowOutward /></a>
            </div>
        </div>
      </div>
      <div className='col-lg-4 col-md-6 col-12 mt-2'>
        <div className='main_card'>
            <div className='img'>
                <img src='./image/rectangle-17@2x.png' alt='...'/>
            </div>
            <div className='content_neignor'> 
            <h1>1204</h1>
            <h5>Gujranwala, Punjab</h5>
            <a href="/">View Agents <MdArrowOutward /></a>
            </div>
        </div>
      </div>
    </div>
  )
}

export default LastSection
