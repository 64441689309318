import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { useAuth } from '../../Context/ContextProvider';

export default function CustomizedSnackbars() {
    const {   open,
        setOpen,
        handleClick,
        error,
        setError
        }=useAuth()
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  React.useEffect(()=>{
    if(error.message){
      setTimeout(() => {
        setError({message:"",color:""})
      },6000);
    }
    // eslint-disable-next-line
  },[handleClick])
  console.log(error);
  return (
    <div>

      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={error?.color}
          variant="filled"
          sx={{ width: '100%'}}
        >
         {error?.message}
        </Alert>
      </Snackbar>
    </div>
  );
}