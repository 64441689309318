import React from 'react'
import img from '../../Images/logo.png'
function CurrentlyMarkit() {
  return (
    <>
    <div className='markit px-4'>
       <div className='row m-0'>
        <div className='col-lg-12'>
          <div className='currently'>
          <div className='markit-off'>Currently Off Market</div>
          <p style={{marginTop:'14px'}}>This property is not for rent or sale on</p>
          <div className='markit-img'>
            <img src={img} alt='...'/>
          </div>
          </div>
        </div>
        </div> 
    </div>
    </>
  )
}

export default CurrentlyMarkit
