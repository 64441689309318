import React from 'react'
import img from'../../Images/Vector (4).png'
import img1 from'../../Images/Vector (5).png'
import img2 from'../../Images/Vector (6).png'
import img3 from'../../Images/Vector (7).png'
function Instant() {
  return (
    <>
    <div className='instant mx-4'>
      <div className='row m-0'>
        <div className='col-lg-5'>
          <div className='value'>
            <div className='row'>
              <div className='col-lg-12 p-0'>
                <div className='value-box'>
                  <div className='v-card'>
                    <div className='instant-card'>
                      <h3>Instant<br/>results</h3>
                      <div className='v-img'>
                        <img src={img} alt='...'/>
                      </div>
                    </div>
                    <p>No need to wait. Our tool gives you an immediate estimate of your home's value.</p>
                  </div>
                  <div style={{background:'#F1FFFF'}} className='v-card'>
                  <div className='instant-card'>
                      <h3>Data-driven<br/>accuracy</h3>
                      <div style={{background:'#1C9093'}} className='v-img'>
                        <img src={img1} alt='...'/>
                      </div>
                    </div>
                    <p>No need to wait. Our tool gives you an immediate estimate of your home's value.</p>
                  </div>
                </div>
              </div>
              <div className='col-lg-12 p-0'>
                <div className='value-box'>
                <div style={{background:'#F1FFFF'}} className='v-card'>
                  <div className='instant-card'>
                      <h3>No<br/>obligation</h3>
                      <div style={{background:'#1C9093'}} className='v-img'>
                        <img src={img2} alt='...'/>
                      </div>
                    </div>
                    <p>This service is completely free, and there's no commitment required. You can use it as often as you like.</p>
                  </div>
                  <div className='v-card'>
                    <div className='instant-card'>
                      <h3>100%<br/>Online</h3>
                      <div className='v-img'>
                        <img src={img3} alt='...'/>
                      </div>
                    </div>
                    <p>No need for appointments or paperwork. Get your valuation from the comfort of your own home, 24/7.</p>
                  </div>
                </div>
              </div>
              
            </div>
          </div>
        </div>
        <div className='col-lg-7'>
          <div className='intant-value my-4'>
            <h2>Get an instant<br/>online valuation<br/>of you Property </h2>
            <p>Try our free online property valuation tool.</p>
            <h5>Humans available (if you wish): Along with your instant valuation, we can connect you to local agents to discuss your valuation. Knowledge is power!</h5>
            <button>Get an Agent Valuation</button>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}

export default Instant
