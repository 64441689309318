import React from 'react'

import NavBar from '../Camponent/NavBarFooter/NavBar'
import Banner from '../Camponent/HousingSocietyDetailsExp/Banner'



function HousingSocietyDetailsExp() {
  return (
<>
   <NavBar/>
   <Banner/>
</>
  )
}

export default HousingSocietyDetailsExp
