import React from 'react'
import NavBar from '../Camponent/NavBarFooter/NavBar'
import Banner from '../Camponent/Agents/Banner'
import SecondSection from '../Camponent/Agents/SecondSection'
import ThirdSection from '../Camponent/Agents/ThirdSection'
import LastSection from '../Camponent/Agents/LastSection'
import Footer from '../Camponent/NavBarFooter/Footer'

function Agents() {
  return (
    <div>
      <NavBar/>
      <Banner/>
      <SecondSection/>
      <ThirdSection/>
      <LastSection/>
      <Footer/>
    </div>
  )
}

export default Agents
