import React from 'react'
import NavBar from '../Camponent/NavBarFooter/NavBar'
import Banner from '../Camponent/LeasDetail/Banner'
import Similar from './Similar'
function LeasDetail() {
  return (
    <div>
      <NavBar/>
      <div className="container-fluid px-4">
      <Banner/>
      <Similar/>
    </div>
    </div>
  )
}

export default LeasDetail
