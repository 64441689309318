import React from 'react'

function Map() {
  return (
    <div className='map'>
        <div className='px-4 my-2'>
        <h2>Defence Map</h2>
        <h3>Society Map<span style={{marginLeft:'10px'}}>Map</span></h3>
      <iframe title='map'  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d54446.51735208889!2d74.41479531246141!3d31.47173516107753!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39190920e59fccd3%3A0x56cae6ee2c7a479b!2sDHA%20Phase%206%2C%20Lahore%2C%20Punjab%2C%20Pakistan!5e0!3m2!1sen!2s!4v1724255128373!5m2!1sen!2s" width="100%" height="600" style={{border:"0"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    </div>
    </div>
  )
}

export default Map
