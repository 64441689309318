import React, { useEffect, useRef, useState } from 'react'
import { TbCurrentLocation } from 'react-icons/tb'
import Checkbox from '@mui/material/Checkbox';
import { teal } from '@mui/material/colors';
import { RiDeleteBinLine } from 'react-icons/ri';
import { BiExpandAlt } from 'react-icons/bi';
import { LuSave } from 'react-icons/lu';
import { useAuth } from '../../Context/ContextProvider';
const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
function AgencySetting() {
    const {agencyProfile,getCity,agencyCeo,getagency}=useAuth()
    const [city, setcity] = useState([])
    const [allcity, setallcity] = useState([])
    useEffect(()=>{
        const fetchCity = async () => {
            try {
                let result = await getCity();
                if (result?.success) {
                    setcity(result.data);
                    setallcity(result.data);
                }
            } catch (error) {
                console.error("An error occurred while fetching data:", error);
            }
        };
        const fetchAgency = async () => {
            try {
                let result = await getagency();
                if (result?.success) {
                    let profileData=result?.data?.data
                    console.log(profileData);
                        setData({
                            name:profileData?.agency_name,
                            email:profileData?.email,
                            website:profileData?.website,
                            description:profileData?.description,
                            city_id:profileData?.city_code,
                            address:profileData?.address,
                            image:profileData?.agency_image
                        })
                        setData1({
                            name:profileData?.ceo_full_name,
                            email:profileData?.designation,
                            description:profileData?.ceo_desc,
                            image:profileData?.ceo_image
                        })
                        if(allcity){
                            allcity?.map((item)=>{
                                if(item?.app_code===profileData?.city_code){
                                    setCityValue({city:item.city})
                                }
                            })
                        }
                }
            } catch (error) {
                console.error("An error occurred while fetching data:", error);
            }
        };
        fetchCity()
        fetchAgency()
    },[])
    const [data,setData]=useState({
        name:"",
        email:"",
        website:"",
        description:"",
        city_id:"",
        address:"",
        image:""
    })
    const [data1,setData1]=useState({
        name:"",
        email:"",
        description:"",
        image:""
    })
    const [fieldErrors, setFieldErrors] = React.useState({})
    const [fieldErrors1, setFieldErrors1] = React.useState({})
    
    const validateStep = () => {
        let errors = {
    
        };
        if (!data.name) {
            errors.name = "Name is required.";
        }
        if (!data.email) {
            errors.email = "Email is required.";
        } else if (!isValidEmail(data.email)) {
            errors.email = "Please enter a valid email address.";
        }
        if (!data.website) {
            errors.website = "website is required.";
        }
        if (!data.city_id) {
            errors.city_id = "City is required.";
        }
        if (!data.address) {
            errors.address = "Address is required.";
        }
        if (!data.description) {
            errors.description = "description is required.";
        }
        setFieldErrors(errors);
        // Check if there are any errors
        return Object.keys(errors).length === 0;
    };
    const validateStep1 = () => {
        let errors = {
    
        };
        if (!data1.name) {
            errors.name = "Name is required.";
        }
        if (!data1.email) {
            errors.email = "Designation is required.";
        } 
        if (!data1.description) {
            errors.description = "description is required.";
        }
        setFieldErrors1(errors);
        // Check if there are any errors
        return Object.keys(errors).length === 0;
    };
    const submit = (event) => {
        event.preventDefault();
        if (validateStep()) {
            agencyProfile({
                agency_name:data?.name,
                city_code:data?.city_id,
                email:data?.email,
                website:data?.website,
                address:data?.address,
                description:data?.description,
                agency_image:data?.image
            })
        }
    }
    const submit1 = (event) => {
        event.preventDefault();
        if (validateStep1()) {
            agencyCeo({
                ceo_full_name:data1?.name,
                designation:data1?.email,
                ceo_desc:data1.description,
                ceo_image:data1.image ,
            })
        }
    }
    const handleFileInputChange = (event) => {
        const file = event.target.files[0];
        if (file) {
          const reader = new FileReader();
    
          reader.onload = (event) => {
            const base64String = event.target.result;
            setData({ ...data, image: base64String });
          };
    
          reader.readAsDataURL(file);
        }
      };
    const handleFileInputChange1 = (event) => {
        const file = event.target.files[0];
        if (file) {
          const reader = new FileReader();
    
          reader.onload = (event) => {
            const base64String = event.target.result;
            setData1({ ...data1, image: base64String });
          };
    
          reader.readAsDataURL(file);
        }
      };
      const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };
    const [city_value,setCityValue]=useState({city:""})
    const [show, setShow] = useState(false)
    const wrapperRef = useRef(null);
    const handleClickOutside = (event) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            setShow(false);
       if(!data?.city_id){
                setCityValue({...city_value,
                    city: "",
                })
            }
         
        }
    };
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        // Cleanup the event listener on component unmount
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    return (
        <div className='add_propertey_main agency_setting'>
            <div className='content_box'>
                <div className='d-flex justify-content-between'>
                    <h3 className='heading_mian'>Agency Profile</h3>
                    <button onClick={submit} >Save Changes   <LuSave />  </button>
                </div>
                <div className='locationPurpos my-3'>
                    <div className='row'>
                        <div className='col-lg-3 col-md-6 col-12'>
                            <div className='inp_login'>
                                <label>Agency Name</label>
                                <input value={data?.name} onChange={(e)=>setData({...data,name:e.target.value})} type='text' placeholder='Agency Name' />
                                {fieldErrors?.name && <span className='text_error'>{fieldErrors?.name}</span>}    
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-6 col-12'>
                        <div className='inp_login'>
                        <label>City</label>
                        <input onClick={() => setShow(true)} value={city_value?.city} onChange={async (e) => {
                            setCityValue({ ...city_value, city: e.target.value })
                            if (e.target.value) {
                                setShow(true)
                                let array = allcity?.filter((item) => item?.city?.toLowerCase().includes(e.target.value?.toLowerCase()));

                                setcity(array)
                            }
                            else {
                                setShow(false)
                                setcity(allcity)
                            }

                        }} placeholder='Select Location' />
                        {show && <ul ref={wrapperRef}>
                            {city?.map((item) => {
                                return (
                                    <li onClick={() => {
                                        setCityValue({ ...city_value, city: item?.city })
                                        setData({ ...data, city_id: item?.app_code })
                                        setShow(false)

                                    }}>{item?.city}</li>
                                )
                            })}
                        </ul>}
                        {fieldErrors?.city_id && <span className='text_error'>{fieldErrors?.city_id}</span>}
                    </div>
                        </div>
                        <div className='col-lg-3 col-md-6 col-12'>
                            <div className='inp_login'>
                                <label>Email</label>
                                <input value={data?.email} onChange={(e)=>setData({...data,email:e.target.value})} type='text' placeholder='Email' />
                                {fieldErrors?.email && <span className='text_error'>{fieldErrors?.email}</span>}    

                            </div>
                        </div>
                        <div className='col-lg-3 col-md-6 col-12'>
                            <div className='inp_login'>
                                <label>Website</label>
                                <input value={data?.website} onChange={(e)=>setData({...data,website:e.target.value})} type='text' placeholder='www.web.com' />
                                {fieldErrors?.website && <span className='text_error'>{fieldErrors?.website}</span>}    

                            </div>
                        </div>
                        <div className='col-lg-9 col-md-12 col-12'>
                            <div className='inp_login'>
                                <label>Address</label>
                                <div className='d-flex align-items-center' style={{ border: "1px solid lightgray", borderRadius: "5px", padding: "0 .5rem" }}>
                                    <TbCurrentLocation />
                                    <input value={data?.address} onChange={(e)=>setData({...data,address:e.target.value})} style={{ border: "none" }} type='text' placeholder='Location Detail, e,g. No. Street' />
                                    {fieldErrors?.address && <span className='text_error'>{fieldErrors?.address}</span>}    

                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6 col-md-12 col-12'>
                            <div className='inp_login my-2'>
                                <label>Describe yourself</label>
                                <textarea value={data?.description} onChange={(e)=>setData({...data,description:e.target.value})} placeholder='Write here' />
                                {fieldErrors?.description && <span className='text_error' style={{top:"100%"}}>{fieldErrors?.description}</span>}    
                            </div>
                        </div>
                        <div className='col-lg-6 col-md-12 col-12'>
                            <div className='inp_login my-2'>
                                <label>Upload a picture</label>
                            </div>
                            <div className='propertyimage'>
                                <div className='image_section py-2 px-3'>
                                    <div className='row'>
                                        <div className='col-lg-4 col-md-12 col-12'>
                                            <div className='d-flex flex-column justify-content-center h-100'>
                                                <label className='btn1 w-100' for="upload_image">
                                                Upload Image
                                                </label>
                                                {/* <button className='btn2 w-100 mt-2'>Image Bank</button> */}
                                                <input onChange={handleFileInputChange} hidden type='file' id='upload_image' />
                                            </div>
                                        </div>
                                        <div className='col-lg-8 col-md-12 col-12'>
                                            <div style={{ borderRadius: "5px", display: "flex", alignItems: "center" }}>
                                            {data?.image&& <div className='image_main p-0'>
                                                    <img src={data?.image} alt='...' />
                                                    <div style={{ position: "absolute", background: "white", left: "10px", top: "5px", height: "25px", width: "25px", padding: "0", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "5px" }}>
                                                        <Checkbox
                                                            {...label}
                                                            defaultChecked
                                                            sx={{
                                                                color: teal[800],
                                                                '&.Mui-checked': {
                                                                    color: teal[600],
                                                                },
                                                            }}
                                                        />
                                                    </div>
                                                    <div onClick={()=>setData({...data,image:""})} style={{ position: "absolute", background: "white", right: "10px", top: "5px", height: "20px", width: "20px", padding: "0", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "5px" }}>
                                                        <RiDeleteBinLine  style={{ color: "#1C9093" }} />
                                                    </div>
                                                    <div style={{ position: "absolute", background: "white", right: "10px", bottom: "5px", height: "20px", width: "20px", padding: "0", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "5px" }}>
                                                        <BiExpandAlt style={{ color: "#1C9093" }} />
                                                    </div>
                                                </div>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className='col-12'>
                            <div className='inp_login'>
                                <Checkbox
                                    {...label}
                                    defaultChecked
                                    sx={{
                                        color: teal[800],
                                        '&.Mui-checked': {
                                            color: teal[600],
                                        },
                                    }}
                                />
                                <label>Update details in all property listings</label>
                            </div>
                        </div> */}
                    </div>
                </div>
                <div className='d-flex justify-content-between'>
                    <h3 className='heading_mian'>CEO Owner Profile</h3>
                    <button onClick={submit1}>Save Changes   <LuSave />  </button>
                </div>
                <div className='locationPurpos my-3'>
                    <div className='row'>
                        <div className='col-lg-5 col-md-6 col-12'>
                            <div className='inp_login'>
                                <label>Agency Name</label>
                                <input value={data1?.name} onChange={(e)=>setData1({...data1,name:e.target.value})} type='text' placeholder='Agency Name' />
                                {fieldErrors1?.name && <span className='text_error'>{fieldErrors1?.name}</span>}

                            </div>
                        </div>
                        <div className='col-lg-5 col-md-6 col-12'>
                            <div className='inp_login'>
                                <label>Designation</label>
                                <input value={data1?.email} onChange={(e)=>setData1({...data1,email:e.target.value})} type='text' placeholder='Designation' />
                                {fieldErrors1?.email && <span className='text_error'>{fieldErrors1?.email}</span>}
                            </div>
                        </div>
                        <div className='col-lg-6 col-md-12 col-12'>
                            <div className='inp_login my-2'>
                                <label>Describe yourself</label>
                                <textarea value={data1?.description} onChange={(e)=>setData1({...data1,description:e.target.value})} placeholder='Write here' />
                                {fieldErrors1?.description && <span className='text_error' style={{top:"100%"}}>{fieldErrors1?.description}</span>}
                            </div>
                        </div>
                        <div className='col-lg-6 col-md-12 col-12'>
                            <div className='inp_login my-2'>
                                <label>Upload a picture</label>
                            </div>
                            <div className='propertyimage'>
                                <div className='image_section py-2 px-3'>
                                    <div className='row'>
                                        <div className='col-lg-4 col-md-12 col-12'>
                                            <div className='d-flex flex-column justify-content-center h-100'>
                                                <label className='btn1 w-100' for="upload_image1">
                                                Upload Image
                                                </label>
                                                {/* <button className='btn2 w-100 mt-2'>Image Bank</button> */}
                                                <input onChange={handleFileInputChange1} hidden type='file' id='upload_image1' />
                                            </div>
                                        </div>
                                        <div className='col-lg-8 col-md-12 col-12'>
                                            <div style={{ borderRadius: "5px", display: "flex", alignItems: "center" }}>
                                            {data1?.image&&    <div className='image_main p-0'>
                                                    <img src={data1?.image} alt='...' />
                                                    <div style={{ position: "absolute", background: "white", left: "10px", top: "5px", height: "25px", width: "25px", padding: "0", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "5px" }}>
                                                        <Checkbox
                                                            {...label}
                                                            defaultChecked
                                                            sx={{
                                                                color: teal[800],
                                                                '&.Mui-checked': {
                                                                    color: teal[600],
                                                                },
                                                            }}
                                                        />
                                                    </div>
                                                    <div style={{ position: "absolute", background: "white", right: "10px", top: "5px", height: "20px", width: "20px", padding: "0", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "5px" }}>
                                                        <RiDeleteBinLine style={{ color: "#1C9093" }} />
                                                    </div>
                                                    <div style={{ position: "absolute", background: "white", right: "10px", bottom: "5px", height: "20px", width: "20px", padding: "0", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "5px" }}>
                                                        <BiExpandAlt style={{ color: "#1C9093" }} />
                                                    </div>
                                                </div>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className='col-12'>
                            <div className='inp_login'>
                                <Checkbox
                                    {...label}
                                    defaultChecked
                                    sx={{
                                        color: teal[800],
                                        '&.Mui-checked': {
                                            color: teal[600],
                                        },
                                    }}
                                />
                                <label>Update details in all property listings</label>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    )
}
export default AgencySetting